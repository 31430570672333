/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { PropsWithChildren } from 'react';
import React, { createContext, useEffect, useState, useMemo, useCallback } from 'react';

import Box from '@mui/material/Box';

import { useTranslation } from 'next-i18next';

import IconButton from 'src/components/ui/IconButton';
import Dialog from 'src/components/ui/Dialog';
import JoinWoopenPrompt from 'src/components/auth/JoinWoopenPrompt';
import LoginForm from 'src/components/auth/LoginForm';

import { breakpoints } from 'src/constants/breakpoints';
import useUser from 'src/hooks/useUser';
import useAppRouter from 'src/hooks/useAppRouter';
import { styledComponent } from 'src/utils/styled';

const StyledDialog = styledComponent(Dialog)`
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiDialog-container {
        @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
            width: 100%;
        }
    }
    .MuiDialog-paper {
        margin: auto;
        max-width: 42rem;
        @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
            width: 100%;
            height: 100%;
            max-width: none;
        }
    }
    .MuiDialog-paperScrollPaper {
        @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
            max-height: calc(100% - 0rem);
        }
    }
`;

const CloseButton = styledComponent(IconButton)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

const StyledBox = styledComponent(Box)`
    min-width: 39rem;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        min-width: 0rem;
    }
`;

type ViewTypes = 'joinPrompt' | 'login';

interface AuthenticatedHandlerOptions {
    returnPathAfterSignInSignUpFlow?: string;
}

export interface AuthenticatedHandlerCallback {
    (userId?: string): void | Promise<void> | undefined;
}

interface LoginModalContextValue {
    // function that check is user is logged in
    // if the user is logged in, it calls the optional callback function
    // if not, opens the login modal, and save the actual path or the path in second params if provided in authenticatedHandlerOptions.returnPathAfterSignInSignUpFlow
    // at the end of the login flow, onEndFlow (if function after a button click) or getReturnPathAfterSignInSignUpFlow (if link click) is used to redirect on the saved path
    createAuthenticatedHandler: (
        cb?: AuthenticatedHandlerCallback,
        returnPathAfterSignInSignUpFlow?: string,
        afterLoginCallback?: AuthenticatedHandlerCallback,
        isPJClaimPrompt?: boolean,
    ) => Promise<void>;
    closeLoginModal: () => void;
    setSelectedView: React.Dispatch<React.SetStateAction<ViewTypes>>;
    onEndFlow: (defaultReturnPath?: string, userId?: string) => void;
    getReturnPathAfterSignInSignUpFlow: () => string | undefined;
    loginModalOpened: boolean;
}

export const LoginModalContext = createContext({} as LoginModalContextValue);

const LoginModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation(['common']);
    const [loginModalOpened, setLoginModalOpen] = useState(false);
    const [pagesJaunesClaimPrompt, setPagesJaunesClaimPrompt] = useState(false);
    const currentUser = useUser();
    const router = useAppRouter();
    const [selectedView, setSelectedView] = useState<ViewTypes>('joinPrompt');
    const [authenticatedHandlerOptions, setAuthenticatedHandlerOptions] = useState<AuthenticatedHandlerOptions>();

    const [loginCallback, setLoginCallback] = useState<AuthenticatedHandlerCallback>();

    const close = () => {
        setLoginModalOpen(false);
    };

    const open = () => {
        setSelectedView('joinPrompt');
        setLoginModalOpen(true);
    };

    /**
     * Turns any function into a "logged only" function that opens the login modal if not logged in.
     *
     * @param cb - a function that will be called if the user is already authenticated, if the user is not authenticated, the login modal will be opened
     * @param returnPathAfterSignInSignUpFlow - a path to redirect to after the SignIn/SignUp/SignUpPro flow has ended, if not provided, the current path will be used
     * @param afterLoginCallback - a function that will be called after the user logs in
     * @param isPJClaimPrompt - a bool that indicates if the JoinWoopenPrompt is for a PagesJaunes company claim or not
     * @returns void.
     *
     * @example
     * ```
     * <Button onClick={() => createAuthenticatedHandler(handleEdit, '/create-ad')} ></Button>
     * ```
     */
    const createAuthenticatedHandler = useCallback(
        async (
            cb?: AuthenticatedHandlerCallback,
            returnPathAfterSignInSignUpFlow?: string,
            afterLoginCallback?: AuthenticatedHandlerCallback,
            isPJClaimPrompt?: boolean,
        ): Promise<void> => {
            if (currentUser) {
                await cb?.();
            } else {
                // if we have a returnPathAfterSignInSignUpFlow, we use it else the default behavior is to use the current path
                setAuthenticatedHandlerOptions({
                    returnPathAfterSignInSignUpFlow: returnPathAfterSignInSignUpFlow ?? router.asPath,
                });
                setLoginCallback(() => afterLoginCallback);
                open();
            }
            setPagesJaunesClaimPrompt(Boolean(isPJClaimPrompt));
        },
        [currentUser, router.asPath],
    );

    /**
     * Callback to call at the end of SignIn/SignUp/SignUpPro flow, to notify that the flow has ended.
     *
     * @param defaultReturnPath - The default return path, to be redirected to. If no path was already defined, this path will be used.
     * @returns void.
     *
     * @remarks
     * In the flow steps where the end of the flow is done by clicking on a link, this function cannot be used. Instead, use `getReturnPathAfterSignInSignUpFlow`.
     *
     * @example
     * ```ts
     * const handleForm = async (values: FormValues) => {
     * // ...
     * onEndFlow('/welcome');
     * };
     * ```
     */
    const onEndFlow = useCallback(
        async (defaultReturnPath?: string, userId?: string) => {
            close();
            if (loginCallback && userId) {
                await loginCallback(userId);
                setLoginCallback(undefined);
            } else {
                const nextPath = authenticatedHandlerOptions?.returnPathAfterSignInSignUpFlow ?? defaultReturnPath;

                if (nextPath) {
                    if ((nextPath as string) !== (router.asPath as string)) {
                        await router.push(nextPath);
                    } else {
                        await router.replace(router.asPath, undefined, {
                            shallow: false,
                            scroll: false,
                        });
                    }
                }
                setAuthenticatedHandlerOptions(undefined);
            }
        },
        [authenticatedHandlerOptions?.returnPathAfterSignInSignUpFlow, loginCallback, router],
    );

    /**
     * Get the `returnPathAfterSignInSignUpFlow` that was set previously.
     *
     * @remarks
     * Use this function only in the flow steps where the end of the flow is done by clicking on a link. Otherwise, if the end of the flow is done by a function call, call `onEndFlow(defaultReturnPath | undefined)`.
     *
     * @example
     * <Link href={getReturnPathAfterSignInSignUpFlow() ?? routePathWelcomeAfterSignUpPro}>{t('common:signUpSkipStep')}</Link>
     */
    const getReturnPathAfterSignInSignUpFlow = useCallback(
        () => authenticatedHandlerOptions?.returnPathAfterSignInSignUpFlow,
        [authenticatedHandlerOptions?.returnPathAfterSignInSignUpFlow],
    );

    // Close modal on route changes
    useEffect(() => {
        const handleRouteChange = () => {
            close();
        };
        router?.events?.on('routeChangeComplete', handleRouteChange);
        return () => {
            router?.events?.off('routeChangeComplete', handleRouteChange);
        };
    }, [router?.events]);

    useEffect(() => {
        if (router.asPath === authenticatedHandlerOptions?.returnPathAfterSignInSignUpFlow) {
            setAuthenticatedHandlerOptions(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router?.asPath]);

    const loginModalProviderValue = useMemo(
        () => ({
            loginModalOpened,
            closeLoginModal: close,
            createAuthenticatedHandler,
            setSelectedView,
            onEndFlow,
            getReturnPathAfterSignInSignUpFlow,
        }),
        [createAuthenticatedHandler, getReturnPathAfterSignInSignUpFlow, onEndFlow, loginModalOpened],
    );

    return (
        <LoginModalContext.Provider value={loginModalProviderValue}>
            {children}
            <StyledDialog data-target-id="login-modal" open={loginModalOpened} onClose={close}>
                <CloseButton
                    color="white"
                    onClick={close}
                    iconProps={{
                        name: 'Close',
                    }}
                />
                {selectedView === 'joinPrompt' && (
                    <Box px={6} pt={4} pb={6}>
                        <JoinWoopenPrompt isPJClaimPrompt={pagesJaunesClaimPrompt} />
                    </Box>
                )}
                {selectedView === 'login' && (
                    <StyledBox p={6}>
                        <LoginForm title={t('common:loginModal.loginTitle')} />
                    </StyledBox>
                )}
            </StyledDialog>
        </LoginModalContext.Provider>
    );
};

export default LoginModalProvider;
