import * as Types from 'src/types/__generated__/graphql';

import * as Operations from 'src/types/__generated__/graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export type AcceptAdminRequestMutationFn = Apollo.MutationFunction<Types.AcceptAdminRequestMutation, Types.AcceptAdminRequestMutationVariables>;

/**
 * __useAcceptAdminRequestMutation__
 *
 * To run a mutation, you first call `useAcceptAdminRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAdminRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAdminRequestMutation, { data, loading, error }] = useAcceptAdminRequestMutation({
 *   variables: {
 *      adminRequestId: // value for 'adminRequestId'
 *   },
 * });
 */
export function useAcceptAdminRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptAdminRequestMutation, Types.AcceptAdminRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptAdminRequestMutation, Types.AcceptAdminRequestMutationVariables>(Operations.AcceptAdminRequest, options);
      }
export type AcceptAdminRequestMutationHookResult = ReturnType<typeof useAcceptAdminRequestMutation>;
export type AcceptAdminRequestMutationResult = Apollo.MutationResult<Types.AcceptAdminRequestMutation>;
export type AcceptAdminRequestMutationOptions = Apollo.BaseMutationOptions<Types.AcceptAdminRequestMutation, Types.AcceptAdminRequestMutationVariables>;
export type AddAdminMutationFn = Apollo.MutationFunction<Types.AddAdminMutation, Types.AddAdminMutationVariables>;

/**
 * __useAddAdminMutation__
 *
 * To run a mutation, you first call `useAddAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdminMutation, { data, loading, error }] = useAddAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddAdminMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddAdminMutation, Types.AddAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddAdminMutation, Types.AddAdminMutationVariables>(Operations.AddAdmin, options);
      }
export type AddAdminMutationHookResult = ReturnType<typeof useAddAdminMutation>;
export type AddAdminMutationResult = Apollo.MutationResult<Types.AddAdminMutation>;
export type AddAdminMutationOptions = Apollo.BaseMutationOptions<Types.AddAdminMutation, Types.AddAdminMutationVariables>;
export type AddBlockedUserMutationFn = Apollo.MutationFunction<Types.AddBlockedUserMutation, Types.AddBlockedUserMutationVariables>;

/**
 * __useAddBlockedUserMutation__
 *
 * To run a mutation, you first call `useAddBlockedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBlockedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBlockedUserMutation, { data, loading, error }] = useAddBlockedUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddBlockedUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddBlockedUserMutation, Types.AddBlockedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddBlockedUserMutation, Types.AddBlockedUserMutationVariables>(Operations.AddBlockedUser, options);
      }
export type AddBlockedUserMutationHookResult = ReturnType<typeof useAddBlockedUserMutation>;
export type AddBlockedUserMutationResult = Apollo.MutationResult<Types.AddBlockedUserMutation>;
export type AddBlockedUserMutationOptions = Apollo.BaseMutationOptions<Types.AddBlockedUserMutation, Types.AddBlockedUserMutationVariables>;
export type RemoveBlockedUserMutationFn = Apollo.MutationFunction<Types.RemoveBlockedUserMutation, Types.RemoveBlockedUserMutationVariables>;

/**
 * __useRemoveBlockedUserMutation__
 *
 * To run a mutation, you first call `useRemoveBlockedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBlockedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBlockedUserMutation, { data, loading, error }] = useRemoveBlockedUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveBlockedUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveBlockedUserMutation, Types.RemoveBlockedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveBlockedUserMutation, Types.RemoveBlockedUserMutationVariables>(Operations.RemoveBlockedUser, options);
      }
export type RemoveBlockedUserMutationHookResult = ReturnType<typeof useRemoveBlockedUserMutation>;
export type RemoveBlockedUserMutationResult = Apollo.MutationResult<Types.RemoveBlockedUserMutation>;
export type RemoveBlockedUserMutationOptions = Apollo.BaseMutationOptions<Types.RemoveBlockedUserMutation, Types.RemoveBlockedUserMutationVariables>;
export type AddContactMutationFn = Apollo.MutationFunction<Types.AddContactMutation, Types.AddContactMutationVariables>;

/**
 * __useAddContactMutation__
 *
 * To run a mutation, you first call `useAddContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactMutation, { data, loading, error }] = useAddContactMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddContactMutation, Types.AddContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddContactMutation, Types.AddContactMutationVariables>(Operations.AddContact, options);
      }
export type AddContactMutationHookResult = ReturnType<typeof useAddContactMutation>;
export type AddContactMutationResult = Apollo.MutationResult<Types.AddContactMutation>;
export type AddContactMutationOptions = Apollo.BaseMutationOptions<Types.AddContactMutation, Types.AddContactMutationVariables>;
export type RemoveContactMutationFn = Apollo.MutationFunction<Types.RemoveContactMutation, Types.RemoveContactMutationVariables>;

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveContactMutation, Types.RemoveContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveContactMutation, Types.RemoveContactMutationVariables>(Operations.RemoveContact, options);
      }
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = Apollo.MutationResult<Types.RemoveContactMutation>;
export type RemoveContactMutationOptions = Apollo.BaseMutationOptions<Types.RemoveContactMutation, Types.RemoveContactMutationVariables>;
export type AddContentEventMutationFn = Apollo.MutationFunction<Types.AddContentEventMutation, Types.AddContentEventMutationVariables>;

/**
 * __useAddContentEventMutation__
 *
 * To run a mutation, you first call `useAddContentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentEventMutation, { data, loading, error }] = useAddContentEventMutation({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *      postId: // value for 'postId'
 *      adId: // value for 'adId'
 *      storyId: // value for 'storyId'
 *      companyId: // value for 'companyId'
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useAddContentEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddContentEventMutation, Types.AddContentEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddContentEventMutation, Types.AddContentEventMutationVariables>(Operations.AddContentEvent, options);
      }
export type AddContentEventMutationHookResult = ReturnType<typeof useAddContentEventMutation>;
export type AddContentEventMutationResult = Apollo.MutationResult<Types.AddContentEventMutation>;
export type AddContentEventMutationOptions = Apollo.BaseMutationOptions<Types.AddContentEventMutation, Types.AddContentEventMutationVariables>;
export type AddContentToGalleryMutationFn = Apollo.MutationFunction<Types.AddContentToGalleryMutation, Types.AddContentToGalleryMutationVariables>;

/**
 * __useAddContentToGalleryMutation__
 *
 * To run a mutation, you first call `useAddContentToGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentToGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentToGalleryMutation, { data, loading, error }] = useAddContentToGalleryMutation({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *      adId: // value for 'adId'
 *      postId: // value for 'postId'
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useAddContentToGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddContentToGalleryMutation, Types.AddContentToGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddContentToGalleryMutation, Types.AddContentToGalleryMutationVariables>(Operations.AddContentToGallery, options);
      }
export type AddContentToGalleryMutationHookResult = ReturnType<typeof useAddContentToGalleryMutation>;
export type AddContentToGalleryMutationResult = Apollo.MutationResult<Types.AddContentToGalleryMutation>;
export type AddContentToGalleryMutationOptions = Apollo.BaseMutationOptions<Types.AddContentToGalleryMutation, Types.AddContentToGalleryMutationVariables>;
export type AddCrmSyncMutationFn = Apollo.MutationFunction<Types.AddCrmSyncMutation, Types.AddCrmSyncMutationVariables>;

/**
 * __useAddCrmSyncMutation__
 *
 * To run a mutation, you first call `useAddCrmSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCrmSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCrmSyncMutation, { data, loading, error }] = useAddCrmSyncMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      userId: // value for 'userId'
 *      crmSourceId: // value for 'crmSourceId'
 *      requestedCrmName: // value for 'requestedCrmName'
 *   },
 * });
 */
export function useAddCrmSyncMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddCrmSyncMutation, Types.AddCrmSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddCrmSyncMutation, Types.AddCrmSyncMutationVariables>(Operations.AddCrmSync, options);
      }
export type AddCrmSyncMutationHookResult = ReturnType<typeof useAddCrmSyncMutation>;
export type AddCrmSyncMutationResult = Apollo.MutationResult<Types.AddCrmSyncMutation>;
export type AddCrmSyncMutationOptions = Apollo.BaseMutationOptions<Types.AddCrmSyncMutation, Types.AddCrmSyncMutationVariables>;
export type AddDirectorMutationFn = Apollo.MutationFunction<Types.AddDirectorMutation, Types.AddDirectorMutationVariables>;

/**
 * __useAddDirectorMutation__
 *
 * To run a mutation, you first call `useAddDirectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDirectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDirectorMutation, { data, loading, error }] = useAddDirectorMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddDirectorMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddDirectorMutation, Types.AddDirectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddDirectorMutation, Types.AddDirectorMutationVariables>(Operations.AddDirector, options);
      }
export type AddDirectorMutationHookResult = ReturnType<typeof useAddDirectorMutation>;
export type AddDirectorMutationResult = Apollo.MutationResult<Types.AddDirectorMutation>;
export type AddDirectorMutationOptions = Apollo.BaseMutationOptions<Types.AddDirectorMutation, Types.AddDirectorMutationVariables>;
export type AddEventMutationFn = Apollo.MutationFunction<Types.AddEventMutation, Types.AddEventMutationVariables>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      quickSignupUserId: // value for 'quickSignupUserId'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      eventType: // value for 'eventType'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useAddEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddEventMutation, Types.AddEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddEventMutation, Types.AddEventMutationVariables>(Operations.AddEvent, options);
      }
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = Apollo.MutationResult<Types.AddEventMutation>;
export type AddEventMutationOptions = Apollo.BaseMutationOptions<Types.AddEventMutation, Types.AddEventMutationVariables>;
export type AddEventsMutationFn = Apollo.MutationFunction<Types.AddEventsMutation, Types.AddEventsMutationVariables>;

/**
 * __useAddEventsMutation__
 *
 * To run a mutation, you first call `useAddEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventsMutation, { data, loading, error }] = useAddEventsMutation({
 *   variables: {
 *      events: // value for 'events'
 *   },
 * });
 */
export function useAddEventsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddEventsMutation, Types.AddEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddEventsMutation, Types.AddEventsMutationVariables>(Operations.AddEvents, options);
      }
export type AddEventsMutationHookResult = ReturnType<typeof useAddEventsMutation>;
export type AddEventsMutationResult = Apollo.MutationResult<Types.AddEventsMutation>;
export type AddEventsMutationOptions = Apollo.BaseMutationOptions<Types.AddEventsMutation, Types.AddEventsMutationVariables>;
export type AssignAdsToUserMutationFn = Apollo.MutationFunction<Types.AssignAdsToUserMutation, Types.AssignAdsToUserMutationVariables>;

/**
 * __useAssignAdsToUserMutation__
 *
 * To run a mutation, you first call `useAssignAdsToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAdsToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAdsToUserMutation, { data, loading, error }] = useAssignAdsToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      adIds: // value for 'adIds'
 *   },
 * });
 */
export function useAssignAdsToUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssignAdsToUserMutation, Types.AssignAdsToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssignAdsToUserMutation, Types.AssignAdsToUserMutationVariables>(Operations.AssignAdsToUser, options);
      }
export type AssignAdsToUserMutationHookResult = ReturnType<typeof useAssignAdsToUserMutation>;
export type AssignAdsToUserMutationResult = Apollo.MutationResult<Types.AssignAdsToUserMutation>;
export type AssignAdsToUserMutationOptions = Apollo.BaseMutationOptions<Types.AssignAdsToUserMutation, Types.AssignAdsToUserMutationVariables>;
export type AssistancePagesJaunesMutationFn = Apollo.MutationFunction<Types.AssistancePagesJaunesMutation, Types.AssistancePagesJaunesMutationVariables>;

/**
 * __useAssistancePagesJaunesMutation__
 *
 * To run a mutation, you first call `useAssistancePagesJaunesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssistancePagesJaunesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assistancePagesJaunesMutation, { data, loading, error }] = useAssistancePagesJaunesMutation({
 *   variables: {
 *      pagesJaunesId: // value for 'pagesJaunesId'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAssistancePagesJaunesMutation(baseOptions?: Apollo.MutationHookOptions<Types.AssistancePagesJaunesMutation, Types.AssistancePagesJaunesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssistancePagesJaunesMutation, Types.AssistancePagesJaunesMutationVariables>(Operations.AssistancePagesJaunes, options);
      }
export type AssistancePagesJaunesMutationHookResult = ReturnType<typeof useAssistancePagesJaunesMutation>;
export type AssistancePagesJaunesMutationResult = Apollo.MutationResult<Types.AssistancePagesJaunesMutation>;
export type AssistancePagesJaunesMutationOptions = Apollo.BaseMutationOptions<Types.AssistancePagesJaunesMutation, Types.AssistancePagesJaunesMutationVariables>;
export type CreateAdMutationFn = Apollo.MutationFunction<Types.CreateAdMutation, Types.CreateAdMutationVariables>;

/**
 * __useCreateAdMutation__
 *
 * To run a mutation, you first call `useCreateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdMutation, { data, loading, error }] = useCreateAdMutation({
 *   variables: {
 *      type: // value for 'type'
 *      propertyType: // value for 'propertyType'
 *      propertyState: // value for 'propertyState'
 *      commonAreasState: // value for 'commonAreasState'
 *      propertyPurposeEstimation: // value for 'propertyPurposeEstimation'
 *      propertyResidenceType: // value for 'propertyResidenceType'
 *      userIsOwner: // value for 'userIsOwner'
 *      contactConsent: // value for 'contactConsent'
 *      status: // value for 'status'
 *      isHiddingCompleteAddress: // value for 'isHiddingCompleteAddress'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      constructionYear: // value for 'constructionYear'
 *      floorPosition: // value for 'floorPosition'
 *      livingArea: // value for 'livingArea'
 *      landArea: // value for 'landArea'
 *      media: // value for 'media'
 *      tmpUrls: // value for 'tmpUrls'
 *      bedroomNumber: // value for 'bedroomNumber'
 *      roomNumber: // value for 'roomNumber'
 *      floorNumber: // value for 'floorNumber'
 *      rooms: // value for 'rooms'
 *      heatType: // value for 'heatType'
 *      heatInformations: // value for 'heatInformations'
 *      consumption: // value for 'consumption'
 *      emissions: // value for 'emissions'
 *      interOffice: // value for 'interOffice'
 *      commissionRate: // value for 'commissionRate'
 *      assets: // value for 'assets'
 *      price: // value for 'price'
 *      homadataEvaluation: // value for 'homadataEvaluation'
 *      furnished: // value for 'furnished'
 *      inclusions: // value for 'inclusions'
 *      rent: // value for 'rent'
 *      monthlyCharges: // value for 'monthlyCharges'
 *      inventory: // value for 'inventory'
 *      fees: // value for 'fees'
 *      guarantees: // value for 'guarantees'
 *      availableDate: // value for 'availableDate'
 *      email: // value for 'email'
 *      servicedLand: // value for 'servicedLand'
 *      constructibleLand: // value for 'constructibleLand'
 *      newProperty: // value for 'newProperty'
 *      surfaceArea: // value for 'surfaceArea'
 *      numberOfVehicles: // value for 'numberOfVehicles'
 *      dimensionLength: // value for 'dimensionLength'
 *      dimensionWidth: // value for 'dimensionWidth'
 *      dimensionHeight: // value for 'dimensionHeight'
 *      coOwnership: // value for 'coOwnership'
 *      coOwnershipNumberOfLots: // value for 'coOwnershipNumberOfLots'
 *      coOwnershipOngoing: // value for 'coOwnershipOngoing'
 *      coOwnershipYearlyCharge: // value for 'coOwnershipYearlyCharge'
 *      saleFeeType: // value for 'saleFeeType'
 *      showSaleFeeBuyerPrice: // value for 'showSaleFeeBuyerPrice'
 *      saleFeeBuyerRate: // value for 'saleFeeBuyerRate'
 *      priceWithoutSaleFee: // value for 'priceWithoutSaleFee'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateAdMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAdMutation, Types.CreateAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAdMutation, Types.CreateAdMutationVariables>(Operations.CreateAd, options);
      }
export type CreateAdMutationHookResult = ReturnType<typeof useCreateAdMutation>;
export type CreateAdMutationResult = Apollo.MutationResult<Types.CreateAdMutation>;
export type CreateAdMutationOptions = Apollo.BaseMutationOptions<Types.CreateAdMutation, Types.CreateAdMutationVariables>;
export type CreateChatTokenMutationFn = Apollo.MutationFunction<Types.CreateChatTokenMutation, Types.CreateChatTokenMutationVariables>;

/**
 * __useCreateChatTokenMutation__
 *
 * To run a mutation, you first call `useCreateChatTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatTokenMutation, { data, loading, error }] = useCreateChatTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateChatTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateChatTokenMutation, Types.CreateChatTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateChatTokenMutation, Types.CreateChatTokenMutationVariables>(Operations.CreateChatToken, options);
      }
export type CreateChatTokenMutationHookResult = ReturnType<typeof useCreateChatTokenMutation>;
export type CreateChatTokenMutationResult = Apollo.MutationResult<Types.CreateChatTokenMutation>;
export type CreateChatTokenMutationOptions = Apollo.BaseMutationOptions<Types.CreateChatTokenMutation, Types.CreateChatTokenMutationVariables>;
export type CreateCommentMutationFn = Apollo.MutationFunction<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(Operations.CreateComment, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<Types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;
export type CreateCompanyMutationFn = Apollo.MutationFunction<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      label: // value for 'label'
 *      name: // value for 'name'
 *      website: // value for 'website'
 *      description: // value for 'description'
 *      phoneNumber: // value for 'phoneNumber'
 *      foundationYear: // value for 'foundationYear'
 *      feeScaleFile: // value for 'feeScaleFile'
 *      feeScaleFileName: // value for 'feeScaleFileName'
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *      coverImage: // value for 'coverImage'
 *      address: // value for 'address'
 *      isPrivate: // value for 'isPrivate'
 *      siretNumber: // value for 'siretNumber'
 *      mainActivityId: // value for 'mainActivityId'
 *      mainCategoryId: // value for 'mainCategoryId'
 *      customActivity: // value for 'customActivity'
 *      pagesJaunesId: // value for 'pagesJaunesId'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>(Operations.CreateCompany, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<Types.CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>;
export type CreateGalleryMutationFn = Apollo.MutationFunction<Types.CreateGalleryMutation, Types.CreateGalleryMutationVariables>;

/**
 * __useCreateGalleryMutation__
 *
 * To run a mutation, you first call `useCreateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGalleryMutation, { data, loading, error }] = useCreateGalleryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isSecret: // value for 'isSecret'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateGalleryMutation, Types.CreateGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateGalleryMutation, Types.CreateGalleryMutationVariables>(Operations.CreateGallery, options);
      }
export type CreateGalleryMutationHookResult = ReturnType<typeof useCreateGalleryMutation>;
export type CreateGalleryMutationResult = Apollo.MutationResult<Types.CreateGalleryMutation>;
export type CreateGalleryMutationOptions = Apollo.BaseMutationOptions<Types.CreateGalleryMutation, Types.CreateGalleryMutationVariables>;
export type CreateGroupInvitationMutationFn = Apollo.MutationFunction<Types.CreateGroupInvitationMutation, Types.CreateGroupInvitationMutationVariables>;

/**
 * __useCreateGroupInvitationMutation__
 *
 * To run a mutation, you first call `useCreateGroupInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupInvitationMutation, { data, loading, error }] = useCreateGroupInvitationMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateGroupInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateGroupInvitationMutation, Types.CreateGroupInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateGroupInvitationMutation, Types.CreateGroupInvitationMutationVariables>(Operations.CreateGroupInvitation, options);
      }
export type CreateGroupInvitationMutationHookResult = ReturnType<typeof useCreateGroupInvitationMutation>;
export type CreateGroupInvitationMutationResult = Apollo.MutationResult<Types.CreateGroupInvitationMutation>;
export type CreateGroupInvitationMutationOptions = Apollo.BaseMutationOptions<Types.CreateGroupInvitationMutation, Types.CreateGroupInvitationMutationVariables>;
export type CreateInvitationMutationFn = Apollo.MutationFunction<Types.CreateInvitationMutation, Types.CreateInvitationMutationVariables>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateInvitationMutation, Types.CreateInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateInvitationMutation, Types.CreateInvitationMutationVariables>(Operations.CreateInvitation, options);
      }
export type CreateInvitationMutationHookResult = ReturnType<typeof useCreateInvitationMutation>;
export type CreateInvitationMutationResult = Apollo.MutationResult<Types.CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<Types.CreateInvitationMutation, Types.CreateInvitationMutationVariables>;
export type CreateJobOfferMutationFn = Apollo.MutationFunction<Types.CreateJobOfferMutation, Types.CreateJobOfferMutationVariables>;

/**
 * __useCreateJobOfferMutation__
 *
 * To run a mutation, you first call `useCreateJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobOfferMutation, { data, loading, error }] = useCreateJobOfferMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyName: // value for 'companyName'
 *      jobTypeId: // value for 'jobTypeId'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      profile: // value for 'profile'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useCreateJobOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateJobOfferMutation, Types.CreateJobOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateJobOfferMutation, Types.CreateJobOfferMutationVariables>(Operations.CreateJobOffer, options);
      }
export type CreateJobOfferMutationHookResult = ReturnType<typeof useCreateJobOfferMutation>;
export type CreateJobOfferMutationResult = Apollo.MutationResult<Types.CreateJobOfferMutation>;
export type CreateJobOfferMutationOptions = Apollo.BaseMutationOptions<Types.CreateJobOfferMutation, Types.CreateJobOfferMutationVariables>;
export type CreateJobOfferApplicationMutationFn = Apollo.MutationFunction<Types.CreateJobOfferApplicationMutation, Types.CreateJobOfferApplicationMutationVariables>;

/**
 * __useCreateJobOfferApplicationMutation__
 *
 * To run a mutation, you first call `useCreateJobOfferApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobOfferApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobOfferApplicationMutation, { data, loading, error }] = useCreateJobOfferApplicationMutation({
 *   variables: {
 *      jobOfferId: // value for 'jobOfferId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      linkedInProfileLink: // value for 'linkedInProfileLink'
 *      coverLetter: // value for 'coverLetter'
 *      resumeFileName: // value for 'resumeFileName'
 *      resumeFile: // value for 'resumeFile'
 *   },
 * });
 */
export function useCreateJobOfferApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateJobOfferApplicationMutation, Types.CreateJobOfferApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateJobOfferApplicationMutation, Types.CreateJobOfferApplicationMutationVariables>(Operations.CreateJobOfferApplication, options);
      }
export type CreateJobOfferApplicationMutationHookResult = ReturnType<typeof useCreateJobOfferApplicationMutation>;
export type CreateJobOfferApplicationMutationResult = Apollo.MutationResult<Types.CreateJobOfferApplicationMutation>;
export type CreateJobOfferApplicationMutationOptions = Apollo.BaseMutationOptions<Types.CreateJobOfferApplicationMutation, Types.CreateJobOfferApplicationMutationVariables>;
export type CreateNewsFeedbackMutationFn = Apollo.MutationFunction<Types.CreateNewsFeedbackMutation, Types.CreateNewsFeedbackMutationVariables>;

/**
 * __useCreateNewsFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateNewsFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsFeedbackMutation, { data, loading, error }] = useCreateNewsFeedbackMutation({
 *   variables: {
 *      wantToSeeMoreOrLess: // value for 'wantToSeeMoreOrLess'
 *      reasonForLess: // value for 'reasonForLess'
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useCreateNewsFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateNewsFeedbackMutation, Types.CreateNewsFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateNewsFeedbackMutation, Types.CreateNewsFeedbackMutationVariables>(Operations.CreateNewsFeedback, options);
      }
export type CreateNewsFeedbackMutationHookResult = ReturnType<typeof useCreateNewsFeedbackMutation>;
export type CreateNewsFeedbackMutationResult = Apollo.MutationResult<Types.CreateNewsFeedbackMutation>;
export type CreateNewsFeedbackMutationOptions = Apollo.BaseMutationOptions<Types.CreateNewsFeedbackMutation, Types.CreateNewsFeedbackMutationVariables>;
export type CreatePostMutationFn = Apollo.MutationFunction<Types.CreatePostMutation, Types.CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      media: // value for 'media'
 *      message: // value for 'message'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      newsPostId: // value for 'newsPostId'
 *      adId: // value for 'adId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePostMutation, Types.CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePostMutation, Types.CreatePostMutationVariables>(Operations.CreatePost, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<Types.CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<Types.CreatePostMutation, Types.CreatePostMutationVariables>;
export type CreateProjectMutationFn = Apollo.MutationFunction<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      description: // value for 'description'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>(Operations.CreateProject, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<Types.CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;
export type CreateQuickSignupAdsMutationFn = Apollo.MutationFunction<Types.CreateQuickSignupAdsMutation, Types.CreateQuickSignupAdsMutationVariables>;

/**
 * __useCreateQuickSignupAdsMutation__
 *
 * To run a mutation, you first call `useCreateQuickSignupAdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickSignupAdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickSignupAdsMutation, { data, loading, error }] = useCreateQuickSignupAdsMutation({
 *   variables: {
 *      quickSignupUserId: // value for 'quickSignupUserId'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateQuickSignupAdsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateQuickSignupAdsMutation, Types.CreateQuickSignupAdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateQuickSignupAdsMutation, Types.CreateQuickSignupAdsMutationVariables>(Operations.CreateQuickSignupAds, options);
      }
export type CreateQuickSignupAdsMutationHookResult = ReturnType<typeof useCreateQuickSignupAdsMutation>;
export type CreateQuickSignupAdsMutationResult = Apollo.MutationResult<Types.CreateQuickSignupAdsMutation>;
export type CreateQuickSignupAdsMutationOptions = Apollo.BaseMutationOptions<Types.CreateQuickSignupAdsMutation, Types.CreateQuickSignupAdsMutationVariables>;
export type CreateRatingMutationFn = Apollo.MutationFunction<Types.CreateRatingMutation, Types.CreateRatingMutationVariables>;

/**
 * __useCreateRatingMutation__
 *
 * To run a mutation, you first call `useCreateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingMutation, { data, loading, error }] = useCreateRatingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      value: // value for 'value'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateRatingMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRatingMutation, Types.CreateRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRatingMutation, Types.CreateRatingMutationVariables>(Operations.CreateRating, options);
      }
export type CreateRatingMutationHookResult = ReturnType<typeof useCreateRatingMutation>;
export type CreateRatingMutationResult = Apollo.MutationResult<Types.CreateRatingMutation>;
export type CreateRatingMutationOptions = Apollo.BaseMutationOptions<Types.CreateRatingMutation, Types.CreateRatingMutationVariables>;
export type CreateRecommendationMutationFn = Apollo.MutationFunction<Types.CreateRecommendationMutation, Types.CreateRecommendationMutationVariables>;

/**
 * __useCreateRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationMutation, { data, loading, error }] = useCreateRecommendationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRecommendationMutation, Types.CreateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRecommendationMutation, Types.CreateRecommendationMutationVariables>(Operations.CreateRecommendation, options);
      }
export type CreateRecommendationMutationHookResult = ReturnType<typeof useCreateRecommendationMutation>;
export type CreateRecommendationMutationResult = Apollo.MutationResult<Types.CreateRecommendationMutation>;
export type CreateRecommendationMutationOptions = Apollo.BaseMutationOptions<Types.CreateRecommendationMutation, Types.CreateRecommendationMutationVariables>;
export type CreateSecondaryEmailMutationFn = Apollo.MutationFunction<Types.CreateSecondaryEmailMutation, Types.CreateSecondaryEmailMutationVariables>;

/**
 * __useCreateSecondaryEmailMutation__
 *
 * To run a mutation, you first call `useCreateSecondaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSecondaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSecondaryEmailMutation, { data, loading, error }] = useCreateSecondaryEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateSecondaryEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSecondaryEmailMutation, Types.CreateSecondaryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSecondaryEmailMutation, Types.CreateSecondaryEmailMutationVariables>(Operations.CreateSecondaryEmail, options);
      }
export type CreateSecondaryEmailMutationHookResult = ReturnType<typeof useCreateSecondaryEmailMutation>;
export type CreateSecondaryEmailMutationResult = Apollo.MutationResult<Types.CreateSecondaryEmailMutation>;
export type CreateSecondaryEmailMutationOptions = Apollo.BaseMutationOptions<Types.CreateSecondaryEmailMutation, Types.CreateSecondaryEmailMutationVariables>;
export type CreateStoryMutationFn = Apollo.MutationFunction<Types.CreateStoryMutation, Types.CreateStoryMutationVariables>;

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      medium: // value for 'medium'
 *      floatingText: // value for 'floatingText'
 *      adId: // value for 'adId'
 *      newsPostId: // value for 'newsPostId'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateStoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateStoryMutation, Types.CreateStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateStoryMutation, Types.CreateStoryMutationVariables>(Operations.CreateStory, options);
      }
export type CreateStoryMutationHookResult = ReturnType<typeof useCreateStoryMutation>;
export type CreateStoryMutationResult = Apollo.MutationResult<Types.CreateStoryMutation>;
export type CreateStoryMutationOptions = Apollo.BaseMutationOptions<Types.CreateStoryMutation, Types.CreateStoryMutationVariables>;
export type DeleteCommentMutationFn = Apollo.MutationFunction<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(Operations.DeleteComment, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>(Operations.DeleteCompany, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<Types.DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCompanyMutation, Types.DeleteCompanyMutationVariables>;
export type DeleteGalleryMutationFn = Apollo.MutationFunction<Types.DeleteGalleryMutation, Types.DeleteGalleryMutationVariables>;

/**
 * __useDeleteGalleryMutation__
 *
 * To run a mutation, you first call `useDeleteGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGalleryMutation, { data, loading, error }] = useDeleteGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteGalleryMutation, Types.DeleteGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteGalleryMutation, Types.DeleteGalleryMutationVariables>(Operations.DeleteGallery, options);
      }
export type DeleteGalleryMutationHookResult = ReturnType<typeof useDeleteGalleryMutation>;
export type DeleteGalleryMutationResult = Apollo.MutationResult<Types.DeleteGalleryMutation>;
export type DeleteGalleryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteGalleryMutation, Types.DeleteGalleryMutationVariables>;
export type DeleteGroupInvitationMutationFn = Apollo.MutationFunction<Types.DeleteGroupInvitationMutation, Types.DeleteGroupInvitationMutationVariables>;

/**
 * __useDeleteGroupInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteGroupInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupInvitationMutation, { data, loading, error }] = useDeleteGroupInvitationMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteGroupInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteGroupInvitationMutation, Types.DeleteGroupInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteGroupInvitationMutation, Types.DeleteGroupInvitationMutationVariables>(Operations.DeleteGroupInvitation, options);
      }
export type DeleteGroupInvitationMutationHookResult = ReturnType<typeof useDeleteGroupInvitationMutation>;
export type DeleteGroupInvitationMutationResult = Apollo.MutationResult<Types.DeleteGroupInvitationMutation>;
export type DeleteGroupInvitationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteGroupInvitationMutation, Types.DeleteGroupInvitationMutationVariables>;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>(Operations.DeleteInvitation, options);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<Types.DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteInvitationMutation, Types.DeleteInvitationMutationVariables>;
export type DeleteJobOfferMutationFn = Apollo.MutationFunction<Types.DeleteJobOfferMutation, Types.DeleteJobOfferMutationVariables>;

/**
 * __useDeleteJobOfferMutation__
 *
 * To run a mutation, you first call `useDeleteJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobOfferMutation, { data, loading, error }] = useDeleteJobOfferMutation({
 *   variables: {
 *      jobOfferId: // value for 'jobOfferId'
 *   },
 * });
 */
export function useDeleteJobOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteJobOfferMutation, Types.DeleteJobOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteJobOfferMutation, Types.DeleteJobOfferMutationVariables>(Operations.DeleteJobOffer, options);
      }
export type DeleteJobOfferMutationHookResult = ReturnType<typeof useDeleteJobOfferMutation>;
export type DeleteJobOfferMutationResult = Apollo.MutationResult<Types.DeleteJobOfferMutation>;
export type DeleteJobOfferMutationOptions = Apollo.BaseMutationOptions<Types.DeleteJobOfferMutation, Types.DeleteJobOfferMutationVariables>;
export type DeletePostMutationFn = Apollo.MutationFunction<Types.DeletePostMutation, Types.DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePostMutation, Types.DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePostMutation, Types.DeletePostMutationVariables>(Operations.DeletePost, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<Types.DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<Types.DeletePostMutation, Types.DeletePostMutationVariables>;
export type DeleteProjectMutationFn = Apollo.MutationFunction<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>(Operations.DeleteProject, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<Types.DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>;
export type DeleteSavedSearchMutationFn = Apollo.MutationFunction<Types.DeleteSavedSearchMutation, Types.DeleteSavedSearchMutationVariables>;

/**
 * __useDeleteSavedSearchMutation__
 *
 * To run a mutation, you first call `useDeleteSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedSearchMutation, { data, loading, error }] = useDeleteSavedSearchMutation({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useDeleteSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSavedSearchMutation, Types.DeleteSavedSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSavedSearchMutation, Types.DeleteSavedSearchMutationVariables>(Operations.DeleteSavedSearch, options);
      }
export type DeleteSavedSearchMutationHookResult = ReturnType<typeof useDeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationResult = Apollo.MutationResult<Types.DeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSavedSearchMutation, Types.DeleteSavedSearchMutationVariables>;
export type DeleteSecondaryEmailMutationFn = Apollo.MutationFunction<Types.DeleteSecondaryEmailMutation, Types.DeleteSecondaryEmailMutationVariables>;

/**
 * __useDeleteSecondaryEmailMutation__
 *
 * To run a mutation, you first call `useDeleteSecondaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSecondaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSecondaryEmailMutation, { data, loading, error }] = useDeleteSecondaryEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSecondaryEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSecondaryEmailMutation, Types.DeleteSecondaryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSecondaryEmailMutation, Types.DeleteSecondaryEmailMutationVariables>(Operations.DeleteSecondaryEmail, options);
      }
export type DeleteSecondaryEmailMutationHookResult = ReturnType<typeof useDeleteSecondaryEmailMutation>;
export type DeleteSecondaryEmailMutationResult = Apollo.MutationResult<Types.DeleteSecondaryEmailMutation>;
export type DeleteSecondaryEmailMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSecondaryEmailMutation, Types.DeleteSecondaryEmailMutationVariables>;
export type DeleteStoryMutationFn = Apollo.MutationFunction<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>(Operations.DeleteStory, options);
      }
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<Types.DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>;
export type DislikeCommentMutationFn = Apollo.MutationFunction<Types.DislikeCommentMutation, Types.DislikeCommentMutationVariables>;

/**
 * __useDislikeCommentMutation__
 *
 * To run a mutation, you first call `useDislikeCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDislikeCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dislikeCommentMutation, { data, loading, error }] = useDislikeCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDislikeCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DislikeCommentMutation, Types.DislikeCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DislikeCommentMutation, Types.DislikeCommentMutationVariables>(Operations.DislikeComment, options);
      }
export type DislikeCommentMutationHookResult = ReturnType<typeof useDislikeCommentMutation>;
export type DislikeCommentMutationResult = Apollo.MutationResult<Types.DislikeCommentMutation>;
export type DislikeCommentMutationOptions = Apollo.BaseMutationOptions<Types.DislikeCommentMutation, Types.DislikeCommentMutationVariables>;
export type DislikeNewsPostMutationFn = Apollo.MutationFunction<Types.DislikeNewsPostMutation, Types.DislikeNewsPostMutationVariables>;

/**
 * __useDislikeNewsPostMutation__
 *
 * To run a mutation, you first call `useDislikeNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDislikeNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dislikeNewsPostMutation, { data, loading, error }] = useDislikeNewsPostMutation({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useDislikeNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<Types.DislikeNewsPostMutation, Types.DislikeNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DislikeNewsPostMutation, Types.DislikeNewsPostMutationVariables>(Operations.DislikeNewsPost, options);
      }
export type DislikeNewsPostMutationHookResult = ReturnType<typeof useDislikeNewsPostMutation>;
export type DislikeNewsPostMutationResult = Apollo.MutationResult<Types.DislikeNewsPostMutation>;
export type DislikeNewsPostMutationOptions = Apollo.BaseMutationOptions<Types.DislikeNewsPostMutation, Types.DislikeNewsPostMutationVariables>;
export type DislikePostMutationFn = Apollo.MutationFunction<Types.DislikePostMutation, Types.DislikePostMutationVariables>;

/**
 * __useDislikePostMutation__
 *
 * To run a mutation, you first call `useDislikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDislikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dislikePostMutation, { data, loading, error }] = useDislikePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useDislikePostMutation(baseOptions?: Apollo.MutationHookOptions<Types.DislikePostMutation, Types.DislikePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DislikePostMutation, Types.DislikePostMutationVariables>(Operations.DislikePost, options);
      }
export type DislikePostMutationHookResult = ReturnType<typeof useDislikePostMutation>;
export type DislikePostMutationResult = Apollo.MutationResult<Types.DislikePostMutation>;
export type DislikePostMutationOptions = Apollo.BaseMutationOptions<Types.DislikePostMutation, Types.DislikePostMutationVariables>;
export type FollowMutationFn = Apollo.MutationFunction<Types.FollowMutation, Types.FollowMutationVariables>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFollowMutation(baseOptions?: Apollo.MutationHookOptions<Types.FollowMutation, Types.FollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FollowMutation, Types.FollowMutationVariables>(Operations.Follow, options);
      }
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<Types.FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<Types.FollowMutation, Types.FollowMutationVariables>;
export type UnFollowMutationFn = Apollo.MutationFunction<Types.UnFollowMutation, Types.UnFollowMutationVariables>;

/**
 * __useUnFollowMutation__
 *
 * To run a mutation, you first call `useUnFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowMutation, { data, loading, error }] = useUnFollowMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUnFollowMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnFollowMutation, Types.UnFollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnFollowMutation, Types.UnFollowMutationVariables>(Operations.UnFollow, options);
      }
export type UnFollowMutationHookResult = ReturnType<typeof useUnFollowMutation>;
export type UnFollowMutationResult = Apollo.MutationResult<Types.UnFollowMutation>;
export type UnFollowMutationOptions = Apollo.BaseMutationOptions<Types.UnFollowMutation, Types.UnFollowMutationVariables>;
export type HideRatingMutationFn = Apollo.MutationFunction<Types.HideRatingMutation, Types.HideRatingMutationVariables>;

/**
 * __useHideRatingMutation__
 *
 * To run a mutation, you first call `useHideRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideRatingMutation, { data, loading, error }] = useHideRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useHideRatingMutation(baseOptions?: Apollo.MutationHookOptions<Types.HideRatingMutation, Types.HideRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.HideRatingMutation, Types.HideRatingMutationVariables>(Operations.HideRating, options);
      }
export type HideRatingMutationHookResult = ReturnType<typeof useHideRatingMutation>;
export type HideRatingMutationResult = Apollo.MutationResult<Types.HideRatingMutation>;
export type HideRatingMutationOptions = Apollo.BaseMutationOptions<Types.HideRatingMutation, Types.HideRatingMutationVariables>;
export type IgnoreAdminRequestMutationFn = Apollo.MutationFunction<Types.IgnoreAdminRequestMutation, Types.IgnoreAdminRequestMutationVariables>;

/**
 * __useIgnoreAdminRequestMutation__
 *
 * To run a mutation, you first call `useIgnoreAdminRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreAdminRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreAdminRequestMutation, { data, loading, error }] = useIgnoreAdminRequestMutation({
 *   variables: {
 *      adminRequestId: // value for 'adminRequestId'
 *   },
 * });
 */
export function useIgnoreAdminRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.IgnoreAdminRequestMutation, Types.IgnoreAdminRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.IgnoreAdminRequestMutation, Types.IgnoreAdminRequestMutationVariables>(Operations.IgnoreAdminRequest, options);
      }
export type IgnoreAdminRequestMutationHookResult = ReturnType<typeof useIgnoreAdminRequestMutation>;
export type IgnoreAdminRequestMutationResult = Apollo.MutationResult<Types.IgnoreAdminRequestMutation>;
export type IgnoreAdminRequestMutationOptions = Apollo.BaseMutationOptions<Types.IgnoreAdminRequestMutation, Types.IgnoreAdminRequestMutationVariables>;
export type IncrementStatsContactCountMutationFn = Apollo.MutationFunction<Types.IncrementStatsContactCountMutation, Types.IncrementStatsContactCountMutationVariables>;

/**
 * __useIncrementStatsContactCountMutation__
 *
 * To run a mutation, you first call `useIncrementStatsContactCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementStatsContactCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementStatsContactCountMutation, { data, loading, error }] = useIncrementStatsContactCountMutation({
 *   variables: {
 *      statsId: // value for 'statsId'
 *   },
 * });
 */
export function useIncrementStatsContactCountMutation(baseOptions?: Apollo.MutationHookOptions<Types.IncrementStatsContactCountMutation, Types.IncrementStatsContactCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.IncrementStatsContactCountMutation, Types.IncrementStatsContactCountMutationVariables>(Operations.IncrementStatsContactCount, options);
      }
export type IncrementStatsContactCountMutationHookResult = ReturnType<typeof useIncrementStatsContactCountMutation>;
export type IncrementStatsContactCountMutationResult = Apollo.MutationResult<Types.IncrementStatsContactCountMutation>;
export type IncrementStatsContactCountMutationOptions = Apollo.BaseMutationOptions<Types.IncrementStatsContactCountMutation, Types.IncrementStatsContactCountMutationVariables>;
export type LikeCommentMutationFn = Apollo.MutationFunction<Types.LikeCommentMutation, Types.LikeCommentMutationVariables>;

/**
 * __useLikeCommentMutation__
 *
 * To run a mutation, you first call `useLikeCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeCommentMutation, { data, loading, error }] = useLikeCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useLikeCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeCommentMutation, Types.LikeCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeCommentMutation, Types.LikeCommentMutationVariables>(Operations.LikeComment, options);
      }
export type LikeCommentMutationHookResult = ReturnType<typeof useLikeCommentMutation>;
export type LikeCommentMutationResult = Apollo.MutationResult<Types.LikeCommentMutation>;
export type LikeCommentMutationOptions = Apollo.BaseMutationOptions<Types.LikeCommentMutation, Types.LikeCommentMutationVariables>;
export type LikeNewsPostMutationFn = Apollo.MutationFunction<Types.LikeNewsPostMutation, Types.LikeNewsPostMutationVariables>;

/**
 * __useLikeNewsPostMutation__
 *
 * To run a mutation, you first call `useLikeNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeNewsPostMutation, { data, loading, error }] = useLikeNewsPostMutation({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useLikeNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeNewsPostMutation, Types.LikeNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeNewsPostMutation, Types.LikeNewsPostMutationVariables>(Operations.LikeNewsPost, options);
      }
export type LikeNewsPostMutationHookResult = ReturnType<typeof useLikeNewsPostMutation>;
export type LikeNewsPostMutationResult = Apollo.MutationResult<Types.LikeNewsPostMutation>;
export type LikeNewsPostMutationOptions = Apollo.BaseMutationOptions<Types.LikeNewsPostMutation, Types.LikeNewsPostMutationVariables>;
export type LikePostMutationFn = Apollo.MutationFunction<Types.LikePostMutation, Types.LikePostMutationVariables>;

/**
 * __useLikePostMutation__
 *
 * To run a mutation, you first call `useLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostMutation, { data, loading, error }] = useLikePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useLikePostMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikePostMutation, Types.LikePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikePostMutation, Types.LikePostMutationVariables>(Operations.LikePost, options);
      }
export type LikePostMutationHookResult = ReturnType<typeof useLikePostMutation>;
export type LikePostMutationResult = Apollo.MutationResult<Types.LikePostMutation>;
export type LikePostMutationOptions = Apollo.BaseMutationOptions<Types.LikePostMutation, Types.LikePostMutationVariables>;
export type PatchViewerNotificationSubscriptionsMutationFn = Apollo.MutationFunction<Types.PatchViewerNotificationSubscriptionsMutation, Types.PatchViewerNotificationSubscriptionsMutationVariables>;

/**
 * __usePatchViewerNotificationSubscriptionsMutation__
 *
 * To run a mutation, you first call `usePatchViewerNotificationSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchViewerNotificationSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchViewerNotificationSubscriptionsMutation, { data, loading, error }] = usePatchViewerNotificationSubscriptionsMutation({
 *   variables: {
 *      subscriptions: // value for 'subscriptions'
 *   },
 * });
 */
export function usePatchViewerNotificationSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.PatchViewerNotificationSubscriptionsMutation, Types.PatchViewerNotificationSubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PatchViewerNotificationSubscriptionsMutation, Types.PatchViewerNotificationSubscriptionsMutationVariables>(Operations.PatchViewerNotificationSubscriptions, options);
      }
export type PatchViewerNotificationSubscriptionsMutationHookResult = ReturnType<typeof usePatchViewerNotificationSubscriptionsMutation>;
export type PatchViewerNotificationSubscriptionsMutationResult = Apollo.MutationResult<Types.PatchViewerNotificationSubscriptionsMutation>;
export type PatchViewerNotificationSubscriptionsMutationOptions = Apollo.BaseMutationOptions<Types.PatchViewerNotificationSubscriptionsMutation, Types.PatchViewerNotificationSubscriptionsMutationVariables>;
export type RecoverUserMutationFn = Apollo.MutationFunction<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>;

/**
 * __useRecoverUserMutation__
 *
 * To run a mutation, you first call `useRecoverUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverUserMutation, { data, loading, error }] = useRecoverUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecoverUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>(Operations.RecoverUser, options);
      }
export type RecoverUserMutationHookResult = ReturnType<typeof useRecoverUserMutation>;
export type RecoverUserMutationResult = Apollo.MutationResult<Types.RecoverUserMutation>;
export type RecoverUserMutationOptions = Apollo.BaseMutationOptions<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>;
export type RefreshBadgeCountMutationFn = Apollo.MutationFunction<Types.RefreshBadgeCountMutation, Types.RefreshBadgeCountMutationVariables>;

/**
 * __useRefreshBadgeCountMutation__
 *
 * To run a mutation, you first call `useRefreshBadgeCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshBadgeCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshBadgeCountMutation, { data, loading, error }] = useRefreshBadgeCountMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshBadgeCountMutation(baseOptions?: Apollo.MutationHookOptions<Types.RefreshBadgeCountMutation, Types.RefreshBadgeCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshBadgeCountMutation, Types.RefreshBadgeCountMutationVariables>(Operations.RefreshBadgeCount, options);
      }
export type RefreshBadgeCountMutationHookResult = ReturnType<typeof useRefreshBadgeCountMutation>;
export type RefreshBadgeCountMutationResult = Apollo.MutationResult<Types.RefreshBadgeCountMutation>;
export type RefreshBadgeCountMutationOptions = Apollo.BaseMutationOptions<Types.RefreshBadgeCountMutation, Types.RefreshBadgeCountMutationVariables>;
export type RequestHomadataEvaluationMutationFn = Apollo.MutationFunction<Types.RequestHomadataEvaluationMutation, Types.RequestHomadataEvaluationMutationVariables>;

/**
 * __useRequestHomadataEvaluationMutation__
 *
 * To run a mutation, you first call `useRequestHomadataEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestHomadataEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestHomadataEvaluationMutation, { data, loading, error }] = useRequestHomadataEvaluationMutation({
 *   variables: {
 *      adId: // value for 'adId'
 *   },
 * });
 */
export function useRequestHomadataEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestHomadataEvaluationMutation, Types.RequestHomadataEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestHomadataEvaluationMutation, Types.RequestHomadataEvaluationMutationVariables>(Operations.RequestHomadataEvaluation, options);
      }
export type RequestHomadataEvaluationMutationHookResult = ReturnType<typeof useRequestHomadataEvaluationMutation>;
export type RequestHomadataEvaluationMutationResult = Apollo.MutationResult<Types.RequestHomadataEvaluationMutation>;
export type RequestHomadataEvaluationMutationOptions = Apollo.BaseMutationOptions<Types.RequestHomadataEvaluationMutation, Types.RequestHomadataEvaluationMutationVariables>;
export type RemoveAdminMutationFn = Apollo.MutationFunction<Types.RemoveAdminMutation, Types.RemoveAdminMutationVariables>;

/**
 * __useRemoveAdminMutation__
 *
 * To run a mutation, you first call `useRemoveAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdminMutation, { data, loading, error }] = useRemoveAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveAdminMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveAdminMutation, Types.RemoveAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveAdminMutation, Types.RemoveAdminMutationVariables>(Operations.RemoveAdmin, options);
      }
export type RemoveAdminMutationHookResult = ReturnType<typeof useRemoveAdminMutation>;
export type RemoveAdminMutationResult = Apollo.MutationResult<Types.RemoveAdminMutation>;
export type RemoveAdminMutationOptions = Apollo.BaseMutationOptions<Types.RemoveAdminMutation, Types.RemoveAdminMutationVariables>;
export type RemoveCompanyMutationFn = Apollo.MutationFunction<Types.RemoveCompanyMutation, Types.RemoveCompanyMutationVariables>;

/**
 * __useRemoveCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMutation, { data, loading, error }] = useRemoveCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveCompanyMutation, Types.RemoveCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveCompanyMutation, Types.RemoveCompanyMutationVariables>(Operations.RemoveCompany, options);
      }
export type RemoveCompanyMutationHookResult = ReturnType<typeof useRemoveCompanyMutation>;
export type RemoveCompanyMutationResult = Apollo.MutationResult<Types.RemoveCompanyMutation>;
export type RemoveCompanyMutationOptions = Apollo.BaseMutationOptions<Types.RemoveCompanyMutation, Types.RemoveCompanyMutationVariables>;
export type RemoveContentFromGalleriesMutationFn = Apollo.MutationFunction<Types.RemoveContentFromGalleriesMutation, Types.RemoveContentFromGalleriesMutationVariables>;

/**
 * __useRemoveContentFromGalleriesMutation__
 *
 * To run a mutation, you first call `useRemoveContentFromGalleriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContentFromGalleriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContentFromGalleriesMutation, { data, loading, error }] = useRemoveContentFromGalleriesMutation({
 *   variables: {
 *      isCompanyGallery: // value for 'isCompanyGallery'
 *      adId: // value for 'adId'
 *      postId: // value for 'postId'
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useRemoveContentFromGalleriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveContentFromGalleriesMutation, Types.RemoveContentFromGalleriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveContentFromGalleriesMutation, Types.RemoveContentFromGalleriesMutationVariables>(Operations.RemoveContentFromGalleries, options);
      }
export type RemoveContentFromGalleriesMutationHookResult = ReturnType<typeof useRemoveContentFromGalleriesMutation>;
export type RemoveContentFromGalleriesMutationResult = Apollo.MutationResult<Types.RemoveContentFromGalleriesMutation>;
export type RemoveContentFromGalleriesMutationOptions = Apollo.BaseMutationOptions<Types.RemoveContentFromGalleriesMutation, Types.RemoveContentFromGalleriesMutationVariables>;
export type RemoveCrmSyncMutationFn = Apollo.MutationFunction<Types.RemoveCrmSyncMutation, Types.RemoveCrmSyncMutationVariables>;

/**
 * __useRemoveCrmSyncMutation__
 *
 * To run a mutation, you first call `useRemoveCrmSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCrmSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCrmSyncMutation, { data, loading, error }] = useRemoveCrmSyncMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveCrmSyncMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveCrmSyncMutation, Types.RemoveCrmSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveCrmSyncMutation, Types.RemoveCrmSyncMutationVariables>(Operations.RemoveCrmSync, options);
      }
export type RemoveCrmSyncMutationHookResult = ReturnType<typeof useRemoveCrmSyncMutation>;
export type RemoveCrmSyncMutationResult = Apollo.MutationResult<Types.RemoveCrmSyncMutation>;
export type RemoveCrmSyncMutationOptions = Apollo.BaseMutationOptions<Types.RemoveCrmSyncMutation, Types.RemoveCrmSyncMutationVariables>;
export type RemoveDirectorMutationFn = Apollo.MutationFunction<Types.RemoveDirectorMutation, Types.RemoveDirectorMutationVariables>;

/**
 * __useRemoveDirectorMutation__
 *
 * To run a mutation, you first call `useRemoveDirectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDirectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDirectorMutation, { data, loading, error }] = useRemoveDirectorMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveDirectorMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveDirectorMutation, Types.RemoveDirectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveDirectorMutation, Types.RemoveDirectorMutationVariables>(Operations.RemoveDirector, options);
      }
export type RemoveDirectorMutationHookResult = ReturnType<typeof useRemoveDirectorMutation>;
export type RemoveDirectorMutationResult = Apollo.MutationResult<Types.RemoveDirectorMutation>;
export type RemoveDirectorMutationOptions = Apollo.BaseMutationOptions<Types.RemoveDirectorMutation, Types.RemoveDirectorMutationVariables>;
export type RemoveGroupMembershipMutationFn = Apollo.MutationFunction<Types.RemoveGroupMembershipMutation, Types.RemoveGroupMembershipMutationVariables>;

/**
 * __useRemoveGroupMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveGroupMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupMembershipMutation, { data, loading, error }] = useRemoveGroupMembershipMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveGroupMembershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveGroupMembershipMutation, Types.RemoveGroupMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveGroupMembershipMutation, Types.RemoveGroupMembershipMutationVariables>(Operations.RemoveGroupMembership, options);
      }
export type RemoveGroupMembershipMutationHookResult = ReturnType<typeof useRemoveGroupMembershipMutation>;
export type RemoveGroupMembershipMutationResult = Apollo.MutationResult<Types.RemoveGroupMembershipMutation>;
export type RemoveGroupMembershipMutationOptions = Apollo.BaseMutationOptions<Types.RemoveGroupMembershipMutation, Types.RemoveGroupMembershipMutationVariables>;
export type RemoveMemberMutationFn = Apollo.MutationFunction<Types.RemoveMemberMutation, Types.RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveMemberMutation, Types.RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveMemberMutation, Types.RemoveMemberMutationVariables>(Operations.RemoveMember, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<Types.RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<Types.RemoveMemberMutation, Types.RemoveMemberMutationVariables>;
export type RemoveRecommendationMutationFn = Apollo.MutationFunction<Types.RemoveRecommendationMutation, Types.RemoveRecommendationMutationVariables>;

/**
 * __useRemoveRecommendationMutation__
 *
 * To run a mutation, you first call `useRemoveRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecommendationMutation, { data, loading, error }] = useRemoveRecommendationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveRecommendationMutation, Types.RemoveRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveRecommendationMutation, Types.RemoveRecommendationMutationVariables>(Operations.RemoveRecommendation, options);
      }
export type RemoveRecommendationMutationHookResult = ReturnType<typeof useRemoveRecommendationMutation>;
export type RemoveRecommendationMutationResult = Apollo.MutationResult<Types.RemoveRecommendationMutation>;
export type RemoveRecommendationMutationOptions = Apollo.BaseMutationOptions<Types.RemoveRecommendationMutation, Types.RemoveRecommendationMutationVariables>;
export type ReportAdMutationFn = Apollo.MutationFunction<Types.ReportAdMutation, Types.ReportAdMutationVariables>;

/**
 * __useReportAdMutation__
 *
 * To run a mutation, you first call `useReportAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAdMutation, { data, loading, error }] = useReportAdMutation({
 *   variables: {
 *      adId: // value for 'adId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportAdMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportAdMutation, Types.ReportAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportAdMutation, Types.ReportAdMutationVariables>(Operations.ReportAd, options);
      }
export type ReportAdMutationHookResult = ReturnType<typeof useReportAdMutation>;
export type ReportAdMutationResult = Apollo.MutationResult<Types.ReportAdMutation>;
export type ReportAdMutationOptions = Apollo.BaseMutationOptions<Types.ReportAdMutation, Types.ReportAdMutationVariables>;
export type ReportCommentMutationFn = Apollo.MutationFunction<Types.ReportCommentMutation, Types.ReportCommentMutationVariables>;

/**
 * __useReportCommentMutation__
 *
 * To run a mutation, you first call `useReportCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCommentMutation, { data, loading, error }] = useReportCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportCommentMutation, Types.ReportCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportCommentMutation, Types.ReportCommentMutationVariables>(Operations.ReportComment, options);
      }
export type ReportCommentMutationHookResult = ReturnType<typeof useReportCommentMutation>;
export type ReportCommentMutationResult = Apollo.MutationResult<Types.ReportCommentMutation>;
export type ReportCommentMutationOptions = Apollo.BaseMutationOptions<Types.ReportCommentMutation, Types.ReportCommentMutationVariables>;
export type ReportCompanyMutationFn = Apollo.MutationFunction<Types.ReportCompanyMutation, Types.ReportCompanyMutationVariables>;

/**
 * __useReportCompanyMutation__
 *
 * To run a mutation, you first call `useReportCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCompanyMutation, { data, loading, error }] = useReportCompanyMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useReportCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportCompanyMutation, Types.ReportCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportCompanyMutation, Types.ReportCompanyMutationVariables>(Operations.ReportCompany, options);
      }
export type ReportCompanyMutationHookResult = ReturnType<typeof useReportCompanyMutation>;
export type ReportCompanyMutationResult = Apollo.MutationResult<Types.ReportCompanyMutation>;
export type ReportCompanyMutationOptions = Apollo.BaseMutationOptions<Types.ReportCompanyMutation, Types.ReportCompanyMutationVariables>;
export type ReportConversationMutationFn = Apollo.MutationFunction<Types.ReportConversationMutation, Types.ReportConversationMutationVariables>;

/**
 * __useReportConversationMutation__
 *
 * To run a mutation, you first call `useReportConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportConversationMutation, { data, loading, error }] = useReportConversationMutation({
 *   variables: {
 *      channelUrl: // value for 'channelUrl'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportConversationMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportConversationMutation, Types.ReportConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportConversationMutation, Types.ReportConversationMutationVariables>(Operations.ReportConversation, options);
      }
export type ReportConversationMutationHookResult = ReturnType<typeof useReportConversationMutation>;
export type ReportConversationMutationResult = Apollo.MutationResult<Types.ReportConversationMutation>;
export type ReportConversationMutationOptions = Apollo.BaseMutationOptions<Types.ReportConversationMutation, Types.ReportConversationMutationVariables>;
export type ReportNewsPostMutationFn = Apollo.MutationFunction<Types.ReportNewsPostMutation, Types.ReportNewsPostMutationVariables>;

/**
 * __useReportNewsPostMutation__
 *
 * To run a mutation, you first call `useReportNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportNewsPostMutation, { data, loading, error }] = useReportNewsPostMutation({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportNewsPostMutation, Types.ReportNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportNewsPostMutation, Types.ReportNewsPostMutationVariables>(Operations.ReportNewsPost, options);
      }
export type ReportNewsPostMutationHookResult = ReturnType<typeof useReportNewsPostMutation>;
export type ReportNewsPostMutationResult = Apollo.MutationResult<Types.ReportNewsPostMutation>;
export type ReportNewsPostMutationOptions = Apollo.BaseMutationOptions<Types.ReportNewsPostMutation, Types.ReportNewsPostMutationVariables>;
export type ReportPostMutationFn = Apollo.MutationFunction<Types.ReportPostMutation, Types.ReportPostMutationVariables>;

/**
 * __useReportPostMutation__
 *
 * To run a mutation, you first call `useReportPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPostMutation, { data, loading, error }] = useReportPostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportPostMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportPostMutation, Types.ReportPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportPostMutation, Types.ReportPostMutationVariables>(Operations.ReportPost, options);
      }
export type ReportPostMutationHookResult = ReturnType<typeof useReportPostMutation>;
export type ReportPostMutationResult = Apollo.MutationResult<Types.ReportPostMutation>;
export type ReportPostMutationOptions = Apollo.BaseMutationOptions<Types.ReportPostMutation, Types.ReportPostMutationVariables>;
export type ReportStoryMutationFn = Apollo.MutationFunction<Types.ReportStoryMutation, Types.ReportStoryMutationVariables>;

/**
 * __useReportStoryMutation__
 *
 * To run a mutation, you first call `useReportStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportStoryMutation, { data, loading, error }] = useReportStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportStoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportStoryMutation, Types.ReportStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportStoryMutation, Types.ReportStoryMutationVariables>(Operations.ReportStory, options);
      }
export type ReportStoryMutationHookResult = ReturnType<typeof useReportStoryMutation>;
export type ReportStoryMutationResult = Apollo.MutationResult<Types.ReportStoryMutation>;
export type ReportStoryMutationOptions = Apollo.BaseMutationOptions<Types.ReportStoryMutation, Types.ReportStoryMutationVariables>;
export type ReportUserMutationFn = Apollo.MutationFunction<Types.ReportUserMutation, Types.ReportUserMutationVariables>;

/**
 * __useReportUserMutation__
 *
 * To run a mutation, you first call `useReportUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportUserMutation, { data, loading, error }] = useReportUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReportUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReportUserMutation, Types.ReportUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReportUserMutation, Types.ReportUserMutationVariables>(Operations.ReportUser, options);
      }
export type ReportUserMutationHookResult = ReturnType<typeof useReportUserMutation>;
export type ReportUserMutationResult = Apollo.MutationResult<Types.ReportUserMutation>;
export type ReportUserMutationOptions = Apollo.BaseMutationOptions<Types.ReportUserMutation, Types.ReportUserMutationVariables>;
export type RequestAdminAccessMutationFn = Apollo.MutationFunction<Types.RequestAdminAccessMutation, Types.RequestAdminAccessMutationVariables>;

/**
 * __useRequestAdminAccessMutation__
 *
 * To run a mutation, you first call `useRequestAdminAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAdminAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAdminAccessMutation, { data, loading, error }] = useRequestAdminAccessMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRequestAdminAccessMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestAdminAccessMutation, Types.RequestAdminAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestAdminAccessMutation, Types.RequestAdminAccessMutationVariables>(Operations.RequestAdminAccess, options);
      }
export type RequestAdminAccessMutationHookResult = ReturnType<typeof useRequestAdminAccessMutation>;
export type RequestAdminAccessMutationResult = Apollo.MutationResult<Types.RequestAdminAccessMutation>;
export type RequestAdminAccessMutationOptions = Apollo.BaseMutationOptions<Types.RequestAdminAccessMutation, Types.RequestAdminAccessMutationVariables>;
export type RequestAgentEmailMutationFn = Apollo.MutationFunction<Types.RequestAgentEmailMutation, Types.RequestAgentEmailMutationVariables>;

/**
 * __useRequestAgentEmailMutation__
 *
 * To run a mutation, you first call `useRequestAgentEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAgentEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAgentEmailMutation, { data, loading, error }] = useRequestAgentEmailMutation({
 *   variables: {
 *      estimationId: // value for 'estimationId'
 *   },
 * });
 */
export function useRequestAgentEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestAgentEmailMutation, Types.RequestAgentEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestAgentEmailMutation, Types.RequestAgentEmailMutationVariables>(Operations.RequestAgentEmail, options);
      }
export type RequestAgentEmailMutationHookResult = ReturnType<typeof useRequestAgentEmailMutation>;
export type RequestAgentEmailMutationResult = Apollo.MutationResult<Types.RequestAgentEmailMutation>;
export type RequestAgentEmailMutationOptions = Apollo.BaseMutationOptions<Types.RequestAgentEmailMutation, Types.RequestAgentEmailMutationVariables>;
export type RequestRecommendationMutationFn = Apollo.MutationFunction<Types.RequestRecommendationMutation, Types.RequestRecommendationMutationVariables>;

/**
 * __useRequestRecommendationMutation__
 *
 * To run a mutation, you first call `useRequestRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRecommendationMutation, { data, loading, error }] = useRequestRecommendationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRequestRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestRecommendationMutation, Types.RequestRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestRecommendationMutation, Types.RequestRecommendationMutationVariables>(Operations.RequestRecommendation, options);
      }
export type RequestRecommendationMutationHookResult = ReturnType<typeof useRequestRecommendationMutation>;
export type RequestRecommendationMutationResult = Apollo.MutationResult<Types.RequestRecommendationMutation>;
export type RequestRecommendationMutationOptions = Apollo.BaseMutationOptions<Types.RequestRecommendationMutation, Types.RequestRecommendationMutationVariables>;
export type RequestVerificationCodeMutationFn = Apollo.MutationFunction<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>;

/**
 * __useRequestVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVerificationCodeMutation, { data, loading, error }] = useRequestVerificationCodeMutation({
 *   variables: {
 *      proId: // value for 'proId'
 *   },
 * });
 */
export function useRequestVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>(Operations.RequestVerificationCode, options);
      }
export type RequestVerificationCodeMutationHookResult = ReturnType<typeof useRequestVerificationCodeMutation>;
export type RequestVerificationCodeMutationResult = Apollo.MutationResult<Types.RequestVerificationCodeMutation>;
export type RequestVerificationCodeMutationOptions = Apollo.BaseMutationOptions<Types.RequestVerificationCodeMutation, Types.RequestVerificationCodeMutationVariables>;
export type SaveNewsCategoriesMutationFn = Apollo.MutationFunction<Types.SaveNewsCategoriesMutation, Types.SaveNewsCategoriesMutationVariables>;

/**
 * __useSaveNewsCategoriesMutation__
 *
 * To run a mutation, you first call `useSaveNewsCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNewsCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNewsCategoriesMutation, { data, loading, error }] = useSaveNewsCategoriesMutation({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useSaveNewsCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveNewsCategoriesMutation, Types.SaveNewsCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveNewsCategoriesMutation, Types.SaveNewsCategoriesMutationVariables>(Operations.SaveNewsCategories, options);
      }
export type SaveNewsCategoriesMutationHookResult = ReturnType<typeof useSaveNewsCategoriesMutation>;
export type SaveNewsCategoriesMutationResult = Apollo.MutationResult<Types.SaveNewsCategoriesMutation>;
export type SaveNewsCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.SaveNewsCategoriesMutation, Types.SaveNewsCategoriesMutationVariables>;
export type SaveSearchByPlaceMutationFn = Apollo.MutationFunction<Types.SaveSearchByPlaceMutation, Types.SaveSearchByPlaceMutationVariables>;

/**
 * __useSaveSearchByPlaceMutation__
 *
 * To run a mutation, you first call `useSaveSearchByPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSearchByPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSearchByPlaceMutation, { data, loading, error }] = useSaveSearchByPlaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      parameters: // value for 'parameters'
 *      place: // value for 'place'
 *      placeSource: // value for 'placeSource'
 *      emailNotificationsEnabled: // value for 'emailNotificationsEnabled'
 *   },
 * });
 */
export function useSaveSearchByPlaceMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveSearchByPlaceMutation, Types.SaveSearchByPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveSearchByPlaceMutation, Types.SaveSearchByPlaceMutationVariables>(Operations.SaveSearchByPlace, options);
      }
export type SaveSearchByPlaceMutationHookResult = ReturnType<typeof useSaveSearchByPlaceMutation>;
export type SaveSearchByPlaceMutationResult = Apollo.MutationResult<Types.SaveSearchByPlaceMutation>;
export type SaveSearchByPlaceMutationOptions = Apollo.BaseMutationOptions<Types.SaveSearchByPlaceMutation, Types.SaveSearchByPlaceMutationVariables>;
export type SendChatNotificationMessageMutationFn = Apollo.MutationFunction<Types.SendChatNotificationMessageMutation, Types.SendChatNotificationMessageMutationVariables>;

/**
 * __useSendChatNotificationMessageMutation__
 *
 * To run a mutation, you first call `useSendChatNotificationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatNotificationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatNotificationMessageMutation, { data, loading, error }] = useSendChatNotificationMessageMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      channelUrl: // value for 'channelUrl'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useSendChatNotificationMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendChatNotificationMessageMutation, Types.SendChatNotificationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendChatNotificationMessageMutation, Types.SendChatNotificationMessageMutationVariables>(Operations.SendChatNotificationMessage, options);
      }
export type SendChatNotificationMessageMutationHookResult = ReturnType<typeof useSendChatNotificationMessageMutation>;
export type SendChatNotificationMessageMutationResult = Apollo.MutationResult<Types.SendChatNotificationMessageMutation>;
export type SendChatNotificationMessageMutationOptions = Apollo.BaseMutationOptions<Types.SendChatNotificationMessageMutation, Types.SendChatNotificationMessageMutationVariables>;
export type SendMailVerificationMutationFn = Apollo.MutationFunction<Types.SendMailVerificationMutation, Types.SendMailVerificationMutationVariables>;

/**
 * __useSendMailVerificationMutation__
 *
 * To run a mutation, you first call `useSendMailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMailVerificationMutation, { data, loading, error }] = useSendMailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendMailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMailVerificationMutation, Types.SendMailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendMailVerificationMutation, Types.SendMailVerificationMutationVariables>(Operations.SendMailVerification, options);
      }
export type SendMailVerificationMutationHookResult = ReturnType<typeof useSendMailVerificationMutation>;
export type SendMailVerificationMutationResult = Apollo.MutationResult<Types.SendMailVerificationMutation>;
export type SendMailVerificationMutationOptions = Apollo.BaseMutationOptions<Types.SendMailVerificationMutation, Types.SendMailVerificationMutationVariables>;
export type SendMessageMutationFn = Apollo.MutationFunction<Types.SendMessageMutation, Types.SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      data: // value for 'data'
 *      channelUrl: // value for 'channelUrl'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMessageMutation, Types.SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendMessageMutation, Types.SendMessageMutationVariables>(Operations.SendMessage, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<Types.SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<Types.SendMessageMutation, Types.SendMessageMutationVariables>;
export type SendSharedContentMessageMutationFn = Apollo.MutationFunction<Types.SendSharedContentMessageMutation, Types.SendSharedContentMessageMutationVariables>;

/**
 * __useSendSharedContentMessageMutation__
 *
 * To run a mutation, you first call `useSendSharedContentMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSharedContentMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSharedContentMessageMutation, { data, loading, error }] = useSendSharedContentMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      data: // value for 'data'
 *      channelUrl: // value for 'channelUrl'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useSendSharedContentMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendSharedContentMessageMutation, Types.SendSharedContentMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendSharedContentMessageMutation, Types.SendSharedContentMessageMutationVariables>(Operations.SendSharedContentMessage, options);
      }
export type SendSharedContentMessageMutationHookResult = ReturnType<typeof useSendSharedContentMessageMutation>;
export type SendSharedContentMessageMutationResult = Apollo.MutationResult<Types.SendSharedContentMessageMutation>;
export type SendSharedContentMessageMutationOptions = Apollo.BaseMutationOptions<Types.SendSharedContentMessageMutation, Types.SendSharedContentMessageMutationVariables>;
export type SendSharedFileMessageMutationFn = Apollo.MutationFunction<Types.SendSharedFileMessageMutation, Types.SendSharedFileMessageMutationVariables>;

/**
 * __useSendSharedFileMessageMutation__
 *
 * To run a mutation, you first call `useSendSharedFileMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSharedFileMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSharedFileMessageMutation, { data, loading, error }] = useSendSharedFileMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      files: // value for 'files'
 *      channelUrl: // value for 'channelUrl'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useSendSharedFileMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendSharedFileMessageMutation, Types.SendSharedFileMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendSharedFileMessageMutation, Types.SendSharedFileMessageMutationVariables>(Operations.SendSharedFileMessage, options);
      }
export type SendSharedFileMessageMutationHookResult = ReturnType<typeof useSendSharedFileMessageMutation>;
export type SendSharedFileMessageMutationResult = Apollo.MutationResult<Types.SendSharedFileMessageMutation>;
export type SendSharedFileMessageMutationOptions = Apollo.BaseMutationOptions<Types.SendSharedFileMessageMutation, Types.SendSharedFileMessageMutationVariables>;
export type SetPrimaryEmailMutationFn = Apollo.MutationFunction<Types.SetPrimaryEmailMutation, Types.SetPrimaryEmailMutationVariables>;

/**
 * __useSetPrimaryEmailMutation__
 *
 * To run a mutation, you first call `useSetPrimaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryEmailMutation, { data, loading, error }] = useSetPrimaryEmailMutation({
 *   variables: {
 *      secondaryEmail: // value for 'secondaryEmail'
 *   },
 * });
 */
export function useSetPrimaryEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetPrimaryEmailMutation, Types.SetPrimaryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetPrimaryEmailMutation, Types.SetPrimaryEmailMutationVariables>(Operations.SetPrimaryEmail, options);
      }
export type SetPrimaryEmailMutationHookResult = ReturnType<typeof useSetPrimaryEmailMutation>;
export type SetPrimaryEmailMutationResult = Apollo.MutationResult<Types.SetPrimaryEmailMutation>;
export type SetPrimaryEmailMutationOptions = Apollo.BaseMutationOptions<Types.SetPrimaryEmailMutation, Types.SetPrimaryEmailMutationVariables>;
export type SignUpMutationFn = Apollo.MutationFunction<Types.SignUpMutation, Types.SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      hasOptedInToMarketingCommsAt: // value for 'hasOptedInToMarketingCommsAt'
 *      hasOptedInAt: // value for 'hasOptedInAt'
 *      quickSignupUserId: // value for 'quickSignupUserId'
 *      ipAddress: // value for 'ipAddress'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpMutation, Types.SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpMutation, Types.SignUpMutationVariables>(Operations.SignUp, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<Types.SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<Types.SignUpMutation, Types.SignUpMutationVariables>;
export type SignUpStepTwoMutationFn = Apollo.MutationFunction<Types.SignUpStepTwoMutation, Types.SignUpStepTwoMutationVariables>;

/**
 * __useSignUpStepTwoMutation__
 *
 * To run a mutation, you first call `useSignUpStepTwoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpStepTwoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpStepTwoMutation, { data, loading, error }] = useSignUpStepTwoMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *      description: // value for 'description'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSignUpStepTwoMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpStepTwoMutation, Types.SignUpStepTwoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpStepTwoMutation, Types.SignUpStepTwoMutationVariables>(Operations.SignUpStepTwo, options);
      }
export type SignUpStepTwoMutationHookResult = ReturnType<typeof useSignUpStepTwoMutation>;
export type SignUpStepTwoMutationResult = Apollo.MutationResult<Types.SignUpStepTwoMutation>;
export type SignUpStepTwoMutationOptions = Apollo.BaseMutationOptions<Types.SignUpStepTwoMutation, Types.SignUpStepTwoMutationVariables>;
export type SignUpProMutationFn = Apollo.MutationFunction<Types.SignUpProMutation, Types.SignUpProMutationVariables>;

/**
 * __useSignUpProMutation__
 *
 * To run a mutation, you first call `useSignUpProMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpProMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpProMutation, { data, loading, error }] = useSignUpProMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      customActivity: // value for 'customActivity'
 *      hasOptedInToMarketingCommsAt: // value for 'hasOptedInToMarketingCommsAt'
 *      hasOptedInAt: // value for 'hasOptedInAt'
 *      quickSignupUserId: // value for 'quickSignupUserId'
 *      isTheRealEstateOther: // value for 'isTheRealEstateOther'
 *      ipAddress: // value for 'ipAddress'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useSignUpProMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpProMutation, Types.SignUpProMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpProMutation, Types.SignUpProMutationVariables>(Operations.SignUpPro, options);
      }
export type SignUpProMutationHookResult = ReturnType<typeof useSignUpProMutation>;
export type SignUpProMutationResult = Apollo.MutationResult<Types.SignUpProMutation>;
export type SignUpProMutationOptions = Apollo.BaseMutationOptions<Types.SignUpProMutation, Types.SignUpProMutationVariables>;
export type SoftDeleteAdMutationFn = Apollo.MutationFunction<Types.SoftDeleteAdMutation, Types.SoftDeleteAdMutationVariables>;

/**
 * __useSoftDeleteAdMutation__
 *
 * To run a mutation, you first call `useSoftDeleteAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteAdMutation, { data, loading, error }] = useSoftDeleteAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteAdMutation(baseOptions?: Apollo.MutationHookOptions<Types.SoftDeleteAdMutation, Types.SoftDeleteAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SoftDeleteAdMutation, Types.SoftDeleteAdMutationVariables>(Operations.SoftDeleteAd, options);
      }
export type SoftDeleteAdMutationHookResult = ReturnType<typeof useSoftDeleteAdMutation>;
export type SoftDeleteAdMutationResult = Apollo.MutationResult<Types.SoftDeleteAdMutation>;
export type SoftDeleteAdMutationOptions = Apollo.BaseMutationOptions<Types.SoftDeleteAdMutation, Types.SoftDeleteAdMutationVariables>;
export type SoftDeleteUserMutationFn = Apollo.MutationFunction<Types.SoftDeleteUserMutation, Types.SoftDeleteUserMutationVariables>;

/**
 * __useSoftDeleteUserMutation__
 *
 * To run a mutation, you first call `useSoftDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteUserMutation, { data, loading, error }] = useSoftDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useSoftDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.SoftDeleteUserMutation, Types.SoftDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SoftDeleteUserMutation, Types.SoftDeleteUserMutationVariables>(Operations.SoftDeleteUser, options);
      }
export type SoftDeleteUserMutationHookResult = ReturnType<typeof useSoftDeleteUserMutation>;
export type SoftDeleteUserMutationResult = Apollo.MutationResult<Types.SoftDeleteUserMutation>;
export type SoftDeleteUserMutationOptions = Apollo.BaseMutationOptions<Types.SoftDeleteUserMutation, Types.SoftDeleteUserMutationVariables>;
export type IncrementStatsViewCountMutationFn = Apollo.MutationFunction<Types.IncrementStatsViewCountMutation, Types.IncrementStatsViewCountMutationVariables>;

/**
 * __useIncrementStatsViewCountMutation__
 *
 * To run a mutation, you first call `useIncrementStatsViewCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementStatsViewCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementStatsViewCountMutation, { data, loading, error }] = useIncrementStatsViewCountMutation({
 *   variables: {
 *      adId: // value for 'adId'
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useIncrementStatsViewCountMutation(baseOptions?: Apollo.MutationHookOptions<Types.IncrementStatsViewCountMutation, Types.IncrementStatsViewCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.IncrementStatsViewCountMutation, Types.IncrementStatsViewCountMutationVariables>(Operations.IncrementStatsViewCount, options);
      }
export type IncrementStatsViewCountMutationHookResult = ReturnType<typeof useIncrementStatsViewCountMutation>;
export type IncrementStatsViewCountMutationResult = Apollo.MutationResult<Types.IncrementStatsViewCountMutation>;
export type IncrementStatsViewCountMutationOptions = Apollo.BaseMutationOptions<Types.IncrementStatsViewCountMutation, Types.IncrementStatsViewCountMutationVariables>;
export type SubmitInterestMutationFn = Apollo.MutationFunction<Types.SubmitInterestMutation, Types.SubmitInterestMutationVariables>;

/**
 * __useSubmitInterestMutation__
 *
 * To run a mutation, you first call `useSubmitInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInterestMutation, { data, loading, error }] = useSubmitInterestMutation({
 *   variables: {
 *      interest: // value for 'interest'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubmitInterestMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitInterestMutation, Types.SubmitInterestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitInterestMutation, Types.SubmitInterestMutationVariables>(Operations.SubmitInterest, options);
      }
export type SubmitInterestMutationHookResult = ReturnType<typeof useSubmitInterestMutation>;
export type SubmitInterestMutationResult = Apollo.MutationResult<Types.SubmitInterestMutation>;
export type SubmitInterestMutationOptions = Apollo.BaseMutationOptions<Types.SubmitInterestMutation, Types.SubmitInterestMutationVariables>;
export type SyncNotificationsMutationFn = Apollo.MutationFunction<Types.SyncNotificationsMutation, Types.SyncNotificationsMutationVariables>;

/**
 * __useSyncNotificationsMutation__
 *
 * To run a mutation, you first call `useSyncNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncNotificationsMutation, { data, loading, error }] = useSyncNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyncNotificationsMutation, Types.SyncNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyncNotificationsMutation, Types.SyncNotificationsMutationVariables>(Operations.SyncNotifications, options);
      }
export type SyncNotificationsMutationHookResult = ReturnType<typeof useSyncNotificationsMutation>;
export type SyncNotificationsMutationResult = Apollo.MutationResult<Types.SyncNotificationsMutation>;
export type SyncNotificationsMutationOptions = Apollo.BaseMutationOptions<Types.SyncNotificationsMutation, Types.SyncNotificationsMutationVariables>;
export type UnsubscribeQuickSignupUserMutationFn = Apollo.MutationFunction<Types.UnsubscribeQuickSignupUserMutation, Types.UnsubscribeQuickSignupUserMutationVariables>;

/**
 * __useUnsubscribeQuickSignupUserMutation__
 *
 * To run a mutation, you first call `useUnsubscribeQuickSignupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeQuickSignupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeQuickSignupUserMutation, { data, loading, error }] = useUnsubscribeQuickSignupUserMutation({
 *   variables: {
 *      quickSignupUserId: // value for 'quickSignupUserId'
 *   },
 * });
 */
export function useUnsubscribeQuickSignupUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnsubscribeQuickSignupUserMutation, Types.UnsubscribeQuickSignupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnsubscribeQuickSignupUserMutation, Types.UnsubscribeQuickSignupUserMutationVariables>(Operations.UnsubscribeQuickSignupUser, options);
      }
export type UnsubscribeQuickSignupUserMutationHookResult = ReturnType<typeof useUnsubscribeQuickSignupUserMutation>;
export type UnsubscribeQuickSignupUserMutationResult = Apollo.MutationResult<Types.UnsubscribeQuickSignupUserMutation>;
export type UnsubscribeQuickSignupUserMutationOptions = Apollo.BaseMutationOptions<Types.UnsubscribeQuickSignupUserMutation, Types.UnsubscribeQuickSignupUserMutationVariables>;
export type UpdateAdMutationFn = Apollo.MutationFunction<Types.UpdateAdMutation, Types.UpdateAdMutationVariables>;

/**
 * __useUpdateAdMutation__
 *
 * To run a mutation, you first call `useUpdateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdMutation, { data, loading, error }] = useUpdateAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      propertyType: // value for 'propertyType'
 *      isHiddingCompleteAddress: // value for 'isHiddingCompleteAddress'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      propertyState: // value for 'propertyState'
 *      commonAreasState: // value for 'commonAreasState'
 *      propertyPurposeEstimation: // value for 'propertyPurposeEstimation'
 *      propertyResidenceType: // value for 'propertyResidenceType'
 *      userIsOwner: // value for 'userIsOwner'
 *      contactConsent: // value for 'contactConsent'
 *      status: // value for 'status'
 *      constructionYear: // value for 'constructionYear'
 *      floorPosition: // value for 'floorPosition'
 *      livingArea: // value for 'livingArea'
 *      landArea: // value for 'landArea'
 *      media: // value for 'media'
 *      bedroomNumber: // value for 'bedroomNumber'
 *      roomNumber: // value for 'roomNumber'
 *      floorNumber: // value for 'floorNumber'
 *      rooms: // value for 'rooms'
 *      heatType: // value for 'heatType'
 *      heatInformations: // value for 'heatInformations'
 *      consumption: // value for 'consumption'
 *      emissions: // value for 'emissions'
 *      interOffice: // value for 'interOffice'
 *      commissionRate: // value for 'commissionRate'
 *      assets: // value for 'assets'
 *      price: // value for 'price'
 *      homadataEvaluation: // value for 'homadataEvaluation'
 *      furnished: // value for 'furnished'
 *      inclusions: // value for 'inclusions'
 *      rent: // value for 'rent'
 *      fees: // value for 'fees'
 *      monthlyCharges: // value for 'monthlyCharges'
 *      inventory: // value for 'inventory'
 *      guarantees: // value for 'guarantees'
 *      availableDate: // value for 'availableDate'
 *      email: // value for 'email'
 *      servicedLand: // value for 'servicedLand'
 *      constructibleLand: // value for 'constructibleLand'
 *      newProperty: // value for 'newProperty'
 *      surfaceArea: // value for 'surfaceArea'
 *      numberOfVehicles: // value for 'numberOfVehicles'
 *      dimensionLength: // value for 'dimensionLength'
 *      dimensionWidth: // value for 'dimensionWidth'
 *      dimensionHeight: // value for 'dimensionHeight'
 *      coOwnership: // value for 'coOwnership'
 *      coOwnershipNumberOfLots: // value for 'coOwnershipNumberOfLots'
 *      coOwnershipOngoing: // value for 'coOwnershipOngoing'
 *      coOwnershipYearlyCharge: // value for 'coOwnershipYearlyCharge'
 *      saleFeeType: // value for 'saleFeeType'
 *      saleFeeBuyerRate: // value for 'saleFeeBuyerRate'
 *      showSaleFeeBuyerPrice: // value for 'showSaleFeeBuyerPrice'
 *      priceWithoutSaleFee: // value for 'priceWithoutSaleFee'
 *      isInaccurateAddress: // value for 'isInaccurateAddress'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateAdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAdMutation, Types.UpdateAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAdMutation, Types.UpdateAdMutationVariables>(Operations.UpdateAd, options);
      }
export type UpdateAdMutationHookResult = ReturnType<typeof useUpdateAdMutation>;
export type UpdateAdMutationResult = Apollo.MutationResult<Types.UpdateAdMutation>;
export type UpdateAdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAdMutation, Types.UpdateAdMutationVariables>;
export type UpdateAdStatusMutationFn = Apollo.MutationFunction<Types.UpdateAdStatusMutation, Types.UpdateAdStatusMutationVariables>;

/**
 * __useUpdateAdStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAdStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdStatusMutation, { data, loading, error }] = useUpdateAdStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      priceSold: // value for 'priceSold'
 *      reasonWhyAdArchived: // value for 'reasonWhyAdArchived'
 *   },
 * });
 */
export function useUpdateAdStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAdStatusMutation, Types.UpdateAdStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAdStatusMutation, Types.UpdateAdStatusMutationVariables>(Operations.UpdateAdStatus, options);
      }
export type UpdateAdStatusMutationHookResult = ReturnType<typeof useUpdateAdStatusMutation>;
export type UpdateAdStatusMutationResult = Apollo.MutationResult<Types.UpdateAdStatusMutation>;
export type UpdateAdStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAdStatusMutation, Types.UpdateAdStatusMutationVariables>;
export type UpdateChannelNameMutationFn = Apollo.MutationFunction<Types.UpdateChannelNameMutation, Types.UpdateChannelNameMutationVariables>;

/**
 * __useUpdateChannelNameMutation__
 *
 * To run a mutation, you first call `useUpdateChannelNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelNameMutation, { data, loading, error }] = useUpdateChannelNameMutation({
 *   variables: {
 *      channelUrl: // value for 'channelUrl'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateChannelNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChannelNameMutation, Types.UpdateChannelNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChannelNameMutation, Types.UpdateChannelNameMutationVariables>(Operations.UpdateChannelName, options);
      }
export type UpdateChannelNameMutationHookResult = ReturnType<typeof useUpdateChannelNameMutation>;
export type UpdateChannelNameMutationResult = Apollo.MutationResult<Types.UpdateChannelNameMutation>;
export type UpdateChannelNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChannelNameMutation, Types.UpdateChannelNameMutationVariables>;
export type UpdateChannelToSuperChannelMutationFn = Apollo.MutationFunction<Types.UpdateChannelToSuperChannelMutation, Types.UpdateChannelToSuperChannelMutationVariables>;

/**
 * __useUpdateChannelToSuperChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelToSuperChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelToSuperChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelToSuperChannelMutation, { data, loading, error }] = useUpdateChannelToSuperChannelMutation({
 *   variables: {
 *      channelUrl: // value for 'channelUrl'
 *   },
 * });
 */
export function useUpdateChannelToSuperChannelMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChannelToSuperChannelMutation, Types.UpdateChannelToSuperChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChannelToSuperChannelMutation, Types.UpdateChannelToSuperChannelMutationVariables>(Operations.UpdateChannelToSuperChannel, options);
      }
export type UpdateChannelToSuperChannelMutationHookResult = ReturnType<typeof useUpdateChannelToSuperChannelMutation>;
export type UpdateChannelToSuperChannelMutationResult = Apollo.MutationResult<Types.UpdateChannelToSuperChannelMutation>;
export type UpdateChannelToSuperChannelMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChannelToSuperChannelMutation, Types.UpdateChannelToSuperChannelMutationVariables>;
export type UpdateCommentMutationFn = Apollo.MutationFunction<Types.UpdateCommentMutation, Types.UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCommentMutation, Types.UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCommentMutation, Types.UpdateCommentMutationVariables>(Operations.UpdateComment, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<Types.UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCommentMutation, Types.UpdateCommentMutationVariables>;
export type UpdateCompanyShowcaseMutationFn = Apollo.MutationFunction<Types.UpdateCompanyShowcaseMutation, Types.UpdateCompanyShowcaseMutationVariables>;

/**
 * __useUpdateCompanyShowcaseMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyShowcaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyShowcaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyShowcaseMutation, { data, loading, error }] = useUpdateCompanyShowcaseMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useUpdateCompanyShowcaseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyShowcaseMutation, Types.UpdateCompanyShowcaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyShowcaseMutation, Types.UpdateCompanyShowcaseMutationVariables>(Operations.UpdateCompanyShowcase, options);
      }
export type UpdateCompanyShowcaseMutationHookResult = ReturnType<typeof useUpdateCompanyShowcaseMutation>;
export type UpdateCompanyShowcaseMutationResult = Apollo.MutationResult<Types.UpdateCompanyShowcaseMutation>;
export type UpdateCompanyShowcaseMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyShowcaseMutation, Types.UpdateCompanyShowcaseMutationVariables>;
export type UpdateCompanyWithNullableAddressMutationFn = Apollo.MutationFunction<Types.UpdateCompanyWithNullableAddressMutation, Types.UpdateCompanyWithNullableAddressMutationVariables>;

/**
 * __useUpdateCompanyWithNullableAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyWithNullableAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyWithNullableAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyWithNullableAddressMutation, { data, loading, error }] = useUpdateCompanyWithNullableAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      phoneNumber: // value for 'phoneNumber'
 *      feeScaleFileHybridMedium: // value for 'feeScaleFileHybridMedium'
 *      feeScaleFileName: // value for 'feeScaleFileName'
 *      siretNumber: // value for 'siretNumber'
 *      website: // value for 'website'
 *      foundationYear: // value for 'foundationYear'
 *      address: // value for 'address'
 *      showcase: // value for 'showcase'
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *      videoHybridMedium: // value for 'videoHybridMedium'
 *      coverImageHybridMedium: // value for 'coverImageHybridMedium'
 *      contactUserId: // value for 'contactUserId'
 *      mission: // value for 'mission'
 *      contactUrl: // value for 'contactUrl'
 *      label: // value for 'label'
 *      mainActivityId: // value for 'mainActivityId'
 *      mainCategoryId: // value for 'mainCategoryId'
 *      customActivity: // value for 'customActivity'
 *   },
 * });
 */
export function useUpdateCompanyWithNullableAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCompanyWithNullableAddressMutation, Types.UpdateCompanyWithNullableAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCompanyWithNullableAddressMutation, Types.UpdateCompanyWithNullableAddressMutationVariables>(Operations.UpdateCompanyWithNullableAddress, options);
      }
export type UpdateCompanyWithNullableAddressMutationHookResult = ReturnType<typeof useUpdateCompanyWithNullableAddressMutation>;
export type UpdateCompanyWithNullableAddressMutationResult = Apollo.MutationResult<Types.UpdateCompanyWithNullableAddressMutation>;
export type UpdateCompanyWithNullableAddressMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCompanyWithNullableAddressMutation, Types.UpdateCompanyWithNullableAddressMutationVariables>;
export type UpdateContactMutationFn = Apollo.MutationFunction<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      contactedUserId: // value for 'contactedUserId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>(Operations.UpdateContact, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<Types.UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>;
export type UpdateGalleryMutationFn = Apollo.MutationFunction<Types.UpdateGalleryMutation, Types.UpdateGalleryMutationVariables>;

/**
 * __useUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGalleryMutation, { data, loading, error }] = useUpdateGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isSecret: // value for 'isSecret'
 *   },
 * });
 */
export function useUpdateGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateGalleryMutation, Types.UpdateGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateGalleryMutation, Types.UpdateGalleryMutationVariables>(Operations.UpdateGallery, options);
      }
export type UpdateGalleryMutationHookResult = ReturnType<typeof useUpdateGalleryMutation>;
export type UpdateGalleryMutationResult = Apollo.MutationResult<Types.UpdateGalleryMutation>;
export type UpdateGalleryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateGalleryMutation, Types.UpdateGalleryMutationVariables>;
export type UpdateGroupInvitationMutationFn = Apollo.MutationFunction<Types.UpdateGroupInvitationMutation, Types.UpdateGroupInvitationMutationVariables>;

/**
 * __useUpdateGroupInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateGroupInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupInvitationMutation, { data, loading, error }] = useUpdateGroupInvitationMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      companyId: // value for 'companyId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateGroupInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateGroupInvitationMutation, Types.UpdateGroupInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateGroupInvitationMutation, Types.UpdateGroupInvitationMutationVariables>(Operations.UpdateGroupInvitation, options);
      }
export type UpdateGroupInvitationMutationHookResult = ReturnType<typeof useUpdateGroupInvitationMutation>;
export type UpdateGroupInvitationMutationResult = Apollo.MutationResult<Types.UpdateGroupInvitationMutation>;
export type UpdateGroupInvitationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateGroupInvitationMutation, Types.UpdateGroupInvitationMutationVariables>;
export type UpdateInvitationMutationFn = Apollo.MutationFunction<Types.UpdateInvitationMutation, Types.UpdateInvitationMutationVariables>;

/**
 * __useUpdateInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvitationMutation, { data, loading, error }] = useUpdateInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateInvitationMutation, Types.UpdateInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateInvitationMutation, Types.UpdateInvitationMutationVariables>(Operations.UpdateInvitation, options);
      }
export type UpdateInvitationMutationHookResult = ReturnType<typeof useUpdateInvitationMutation>;
export type UpdateInvitationMutationResult = Apollo.MutationResult<Types.UpdateInvitationMutation>;
export type UpdateInvitationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateInvitationMutation, Types.UpdateInvitationMutationVariables>;
export type UpdateJobOfferMutationFn = Apollo.MutationFunction<Types.UpdateJobOfferMutation, Types.UpdateJobOfferMutationVariables>;

/**
 * __useUpdateJobOfferMutation__
 *
 * To run a mutation, you first call `useUpdateJobOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobOfferMutation, { data, loading, error }] = useUpdateJobOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyName: // value for 'companyName'
 *      jobTypeId: // value for 'jobTypeId'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      profile: // value for 'profile'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useUpdateJobOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobOfferMutation, Types.UpdateJobOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobOfferMutation, Types.UpdateJobOfferMutationVariables>(Operations.UpdateJobOffer, options);
      }
export type UpdateJobOfferMutationHookResult = ReturnType<typeof useUpdateJobOfferMutation>;
export type UpdateJobOfferMutationResult = Apollo.MutationResult<Types.UpdateJobOfferMutation>;
export type UpdateJobOfferMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobOfferMutation, Types.UpdateJobOfferMutationVariables>;
export type UpdateJobOfferIsHiddenMutationFn = Apollo.MutationFunction<Types.UpdateJobOfferIsHiddenMutation, Types.UpdateJobOfferIsHiddenMutationVariables>;

/**
 * __useUpdateJobOfferIsHiddenMutation__
 *
 * To run a mutation, you first call `useUpdateJobOfferIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobOfferIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobOfferIsHiddenMutation, { data, loading, error }] = useUpdateJobOfferIsHiddenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdateJobOfferIsHiddenMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobOfferIsHiddenMutation, Types.UpdateJobOfferIsHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobOfferIsHiddenMutation, Types.UpdateJobOfferIsHiddenMutationVariables>(Operations.UpdateJobOfferIsHidden, options);
      }
export type UpdateJobOfferIsHiddenMutationHookResult = ReturnType<typeof useUpdateJobOfferIsHiddenMutation>;
export type UpdateJobOfferIsHiddenMutationResult = Apollo.MutationResult<Types.UpdateJobOfferIsHiddenMutation>;
export type UpdateJobOfferIsHiddenMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobOfferIsHiddenMutation, Types.UpdateJobOfferIsHiddenMutationVariables>;
export type UpdatePostMutationFn = Apollo.MutationFunction<Types.UpdatePostMutation, Types.UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      media: // value for 'media'
 *      message: // value for 'message'
 *      newsPostId: // value for 'newsPostId'
 *      adId: // value for 'adId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePostMutation, Types.UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePostMutation, Types.UpdatePostMutationVariables>(Operations.UpdatePost, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<Types.UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePostMutation, Types.UpdatePostMutationVariables>;
export type UpdateProjectMutationFn = Apollo.MutationFunction<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      description: // value for 'description'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>(Operations.UpdateProject, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<Types.UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>;
export type UpdateRatingMutationFn = Apollo.MutationFunction<Types.UpdateRatingMutation, Types.UpdateRatingMutationVariables>;

/**
 * __useUpdateRatingMutation__
 *
 * To run a mutation, you first call `useUpdateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingMutation, { data, loading, error }] = useUpdateRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateRatingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRatingMutation, Types.UpdateRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRatingMutation, Types.UpdateRatingMutationVariables>(Operations.UpdateRating, options);
      }
export type UpdateRatingMutationHookResult = ReturnType<typeof useUpdateRatingMutation>;
export type UpdateRatingMutationResult = Apollo.MutationResult<Types.UpdateRatingMutation>;
export type UpdateRatingMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRatingMutation, Types.UpdateRatingMutationVariables>;
export type UpdateRecommendationMutationFn = Apollo.MutationFunction<Types.UpdateRecommendationMutation, Types.UpdateRecommendationMutationVariables>;

/**
 * __useUpdateRecommendationMutation__
 *
 * To run a mutation, you first call `useUpdateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecommendationMutation, { data, loading, error }] = useUpdateRecommendationMutation({
 *   variables: {
 *      recommendationId: // value for 'recommendationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRecommendationMutation, Types.UpdateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRecommendationMutation, Types.UpdateRecommendationMutationVariables>(Operations.UpdateRecommendation, options);
      }
export type UpdateRecommendationMutationHookResult = ReturnType<typeof useUpdateRecommendationMutation>;
export type UpdateRecommendationMutationResult = Apollo.MutationResult<Types.UpdateRecommendationMutation>;
export type UpdateRecommendationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRecommendationMutation, Types.UpdateRecommendationMutationVariables>;
export type UpdateSearchByPlaceMutationFn = Apollo.MutationFunction<Types.UpdateSearchByPlaceMutation, Types.UpdateSearchByPlaceMutationVariables>;

/**
 * __useUpdateSearchByPlaceMutation__
 *
 * To run a mutation, you first call `useUpdateSearchByPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchByPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchByPlaceMutation, { data, loading, error }] = useUpdateSearchByPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      parameters: // value for 'parameters'
 *      place: // value for 'place'
 *      placeSource: // value for 'placeSource'
 *      lastViewedAt: // value for 'lastViewedAt'
 *      emailNotificationsEnabled: // value for 'emailNotificationsEnabled'
 *   },
 * });
 */
export function useUpdateSearchByPlaceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSearchByPlaceMutation, Types.UpdateSearchByPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSearchByPlaceMutation, Types.UpdateSearchByPlaceMutationVariables>(Operations.UpdateSearchByPlace, options);
      }
export type UpdateSearchByPlaceMutationHookResult = ReturnType<typeof useUpdateSearchByPlaceMutation>;
export type UpdateSearchByPlaceMutationResult = Apollo.MutationResult<Types.UpdateSearchByPlaceMutation>;
export type UpdateSearchByPlaceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSearchByPlaceMutation, Types.UpdateSearchByPlaceMutationVariables>;
export type UpdateShowcaseMutationFn = Apollo.MutationFunction<Types.UpdateShowcaseMutation, Types.UpdateShowcaseMutationVariables>;

/**
 * __useUpdateShowcaseMutation__
 *
 * To run a mutation, you first call `useUpdateShowcaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowcaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowcaseMutation, { data, loading, error }] = useUpdateShowcaseMutation({
 *   variables: {
 *      media: // value for 'media'
 *   },
 * });
 */
export function useUpdateShowcaseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateShowcaseMutation, Types.UpdateShowcaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateShowcaseMutation, Types.UpdateShowcaseMutationVariables>(Operations.UpdateShowcase, options);
      }
export type UpdateShowcaseMutationHookResult = ReturnType<typeof useUpdateShowcaseMutation>;
export type UpdateShowcaseMutationResult = Apollo.MutationResult<Types.UpdateShowcaseMutation>;
export type UpdateShowcaseMutationOptions = Apollo.BaseMutationOptions<Types.UpdateShowcaseMutation, Types.UpdateShowcaseMutationVariables>;
export type UpdateUserLocaleMutationFn = Apollo.MutationFunction<Types.UpdateUserLocaleMutation, Types.UpdateUserLocaleMutationVariables>;

/**
 * __useUpdateUserLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocaleMutation, { data, loading, error }] = useUpdateUserLocaleMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateUserLocaleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserLocaleMutation, Types.UpdateUserLocaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserLocaleMutation, Types.UpdateUserLocaleMutationVariables>(Operations.UpdateUserLocale, options);
      }
export type UpdateUserLocaleMutationHookResult = ReturnType<typeof useUpdateUserLocaleMutation>;
export type UpdateUserLocaleMutationResult = Apollo.MutationResult<Types.UpdateUserLocaleMutation>;
export type UpdateUserLocaleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserLocaleMutation, Types.UpdateUserLocaleMutationVariables>;
export type UpdateMyProfileImageMutationFn = Apollo.MutationFunction<Types.UpdateMyProfileImageMutation, Types.UpdateMyProfileImageMutationVariables>;

/**
 * __useUpdateMyProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateMyProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProfileImageMutation, { data, loading, error }] = useUpdateMyProfileImageMutation({
 *   variables: {
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *   },
 * });
 */
export function useUpdateMyProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMyProfileImageMutation, Types.UpdateMyProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMyProfileImageMutation, Types.UpdateMyProfileImageMutationVariables>(Operations.UpdateMyProfileImage, options);
      }
export type UpdateMyProfileImageMutationHookResult = ReturnType<typeof useUpdateMyProfileImageMutation>;
export type UpdateMyProfileImageMutationResult = Apollo.MutationResult<Types.UpdateMyProfileImageMutation>;
export type UpdateMyProfileImageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMyProfileImageMutation, Types.UpdateMyProfileImageMutationVariables>;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<Types.UpdateUserProfileMutation, Types.UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *      coverImageHybridMedium: // value for 'coverImageHybridMedium'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      description: // value for 'description'
 *      phoneNumber: // value for 'phoneNumber'
 *      certificates: // value for 'certificates'
 *      customActivity: // value for 'customActivity'
 *      services: // value for 'services'
 *      awards: // value for 'awards'
 *      feeScaleFileHybridMedium: // value for 'feeScaleFileHybridMedium'
 *      feeScaleFileName: // value for 'feeScaleFileName'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserProfileMutation, Types.UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserProfileMutation, Types.UpdateUserProfileMutationVariables>(Operations.UpdateUserProfile, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<Types.UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserProfileMutation, Types.UpdateUserProfileMutationVariables>;
export type UpdateUserProfileProMutationFn = Apollo.MutationFunction<Types.UpdateUserProfileProMutation, Types.UpdateUserProfileProMutationVariables>;

/**
 * __useUpdateUserProfileProMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileProMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileProMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileProMutation, { data, loading, error }] = useUpdateUserProfileProMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *      cityId: // value for 'cityId'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      phoneNumber: // value for 'phoneNumber'
 *      description: // value for 'description'
 *      certificates: // value for 'certificates'
 *      customActivity: // value for 'customActivity'
 *      services: // value for 'services'
 *      awards: // value for 'awards'
 *      feeScaleFileHybridMedium: // value for 'feeScaleFileHybridMedium'
 *      feeScaleFileName: // value for 'feeScaleFileName'
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      companyPhoneNumber: // value for 'companyPhoneNumber'
 *      siretNumber: // value for 'siretNumber'
 *      website: // value for 'website'
 *      foundationYear: // value for 'foundationYear'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateUserProfileProMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserProfileProMutation, Types.UpdateUserProfileProMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserProfileProMutation, Types.UpdateUserProfileProMutationVariables>(Operations.UpdateUserProfilePro, options);
      }
export type UpdateUserProfileProMutationHookResult = ReturnType<typeof useUpdateUserProfileProMutation>;
export type UpdateUserProfileProMutationResult = Apollo.MutationResult<Types.UpdateUserProfileProMutation>;
export type UpdateUserProfileProMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserProfileProMutation, Types.UpdateUserProfileProMutationVariables>;
export type UpdateUserProfileAndCreateCompanyMutationFn = Apollo.MutationFunction<Types.UpdateUserProfileAndCreateCompanyMutation, Types.UpdateUserProfileAndCreateCompanyMutationVariables>;

/**
 * __useUpdateUserProfileAndCreateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileAndCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileAndCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileAndCreateCompanyMutation, { data, loading, error }] = useUpdateUserProfileAndCreateCompanyMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      imageHybridMedium: // value for 'imageHybridMedium'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      phoneNumber: // value for 'phoneNumber'
 *      cityId: // value for 'cityId'
 *      certificates: // value for 'certificates'
 *      customActivity: // value for 'customActivity'
 *      services: // value for 'services'
 *      awards: // value for 'awards'
 *      feeScaleFileHybridMedium: // value for 'feeScaleFileHybridMedium'
 *      feeScaleFileName: // value for 'feeScaleFileName'
 *      name: // value for 'name'
 *      website: // value for 'website'
 *      companyPhoneNumber: // value for 'companyPhoneNumber'
 *      foundationYear: // value for 'foundationYear'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateUserProfileAndCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserProfileAndCreateCompanyMutation, Types.UpdateUserProfileAndCreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserProfileAndCreateCompanyMutation, Types.UpdateUserProfileAndCreateCompanyMutationVariables>(Operations.UpdateUserProfileAndCreateCompany, options);
      }
export type UpdateUserProfileAndCreateCompanyMutationHookResult = ReturnType<typeof useUpdateUserProfileAndCreateCompanyMutation>;
export type UpdateUserProfileAndCreateCompanyMutationResult = Apollo.MutationResult<Types.UpdateUserProfileAndCreateCompanyMutation>;
export type UpdateUserProfileAndCreateCompanyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserProfileAndCreateCompanyMutation, Types.UpdateUserProfileAndCreateCompanyMutationVariables>;
export type UploadChannelImageMutationFn = Apollo.MutationFunction<Types.UploadChannelImageMutation, Types.UploadChannelImageMutationVariables>;

/**
 * __useUploadChannelImageMutation__
 *
 * To run a mutation, you first call `useUploadChannelImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadChannelImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadChannelImageMutation, { data, loading, error }] = useUploadChannelImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      channelUrl: // value for 'channelUrl'
 *   },
 * });
 */
export function useUploadChannelImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadChannelImageMutation, Types.UploadChannelImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadChannelImageMutation, Types.UploadChannelImageMutationVariables>(Operations.UploadChannelImage, options);
      }
export type UploadChannelImageMutationHookResult = ReturnType<typeof useUploadChannelImageMutation>;
export type UploadChannelImageMutationResult = Apollo.MutationResult<Types.UploadChannelImageMutation>;
export type UploadChannelImageMutationOptions = Apollo.BaseMutationOptions<Types.UploadChannelImageMutation, Types.UploadChannelImageMutationVariables>;
export type UploadMediaMutationFn = Apollo.MutationFunction<Types.UploadMediaMutation, Types.UploadMediaMutationVariables>;

/**
 * __useUploadMediaMutation__
 *
 * To run a mutation, you first call `useUploadMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaMutation, { data, loading, error }] = useUploadMediaMutation({
 *   variables: {
 *      media: // value for 'media'
 *   },
 * });
 */
export function useUploadMediaMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadMediaMutation, Types.UploadMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadMediaMutation, Types.UploadMediaMutationVariables>(Operations.UploadMedia, options);
      }
export type UploadMediaMutationHookResult = ReturnType<typeof useUploadMediaMutation>;
export type UploadMediaMutationResult = Apollo.MutationResult<Types.UploadMediaMutation>;
export type UploadMediaMutationOptions = Apollo.BaseMutationOptions<Types.UploadMediaMutation, Types.UploadMediaMutationVariables>;
export type ValidateVerificationCodeMutationFn = Apollo.MutationFunction<Types.ValidateVerificationCodeMutation, Types.ValidateVerificationCodeMutationVariables>;

/**
 * __useValidateVerificationCodeMutation__
 *
 * To run a mutation, you first call `useValidateVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateVerificationCodeMutation, { data, loading, error }] = useValidateVerificationCodeMutation({
 *   variables: {
 *      proId: // value for 'proId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.ValidateVerificationCodeMutation, Types.ValidateVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ValidateVerificationCodeMutation, Types.ValidateVerificationCodeMutationVariables>(Operations.ValidateVerificationCode, options);
      }
export type ValidateVerificationCodeMutationHookResult = ReturnType<typeof useValidateVerificationCodeMutation>;
export type ValidateVerificationCodeMutationResult = Apollo.MutationResult<Types.ValidateVerificationCodeMutation>;
export type ValidateVerificationCodeMutationOptions = Apollo.BaseMutationOptions<Types.ValidateVerificationCodeMutation, Types.ValidateVerificationCodeMutationVariables>;
export type ViewNotificationMutationFn = Apollo.MutationFunction<Types.ViewNotificationMutation, Types.ViewNotificationMutationVariables>;

/**
 * __useViewNotificationMutation__
 *
 * To run a mutation, you first call `useViewNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewNotificationMutation, { data, loading, error }] = useViewNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useViewNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewNotificationMutation, Types.ViewNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewNotificationMutation, Types.ViewNotificationMutationVariables>(Operations.ViewNotification, options);
      }
export type ViewNotificationMutationHookResult = ReturnType<typeof useViewNotificationMutation>;
export type ViewNotificationMutationResult = Apollo.MutationResult<Types.ViewNotificationMutation>;
export type ViewNotificationMutationOptions = Apollo.BaseMutationOptions<Types.ViewNotificationMutation, Types.ViewNotificationMutationVariables>;
export type ViewStoryMutationFn = Apollo.MutationFunction<Types.ViewStoryMutation, Types.ViewStoryMutationVariables>;

/**
 * __useViewStoryMutation__
 *
 * To run a mutation, you first call `useViewStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewStoryMutation, { data, loading, error }] = useViewStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useViewStoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewStoryMutation, Types.ViewStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewStoryMutation, Types.ViewStoryMutationVariables>(Operations.ViewStory, options);
      }
export type ViewStoryMutationHookResult = ReturnType<typeof useViewStoryMutation>;
export type ViewStoryMutationResult = Apollo.MutationResult<Types.ViewStoryMutation>;
export type ViewStoryMutationOptions = Apollo.BaseMutationOptions<Types.ViewStoryMutation, Types.ViewStoryMutationVariables>;
export type SetEmailVerifiedMutationFn = Apollo.MutationFunction<Types.SetEmailVerifiedMutation, Types.SetEmailVerifiedMutationVariables>;

/**
 * __useSetEmailVerifiedMutation__
 *
 * To run a mutation, you first call `useSetEmailVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmailVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmailVerifiedMutation, { data, loading, error }] = useSetEmailVerifiedMutation({
 *   variables: {
 *      isEmailVerified: // value for 'isEmailVerified'
 *   },
 * });
 */
export function useSetEmailVerifiedMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetEmailVerifiedMutation, Types.SetEmailVerifiedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetEmailVerifiedMutation, Types.SetEmailVerifiedMutationVariables>(Operations.SetEmailVerified, options);
      }
export type SetEmailVerifiedMutationHookResult = ReturnType<typeof useSetEmailVerifiedMutation>;
export type SetEmailVerifiedMutationResult = Apollo.MutationResult<Types.SetEmailVerifiedMutation>;
export type SetEmailVerifiedMutationOptions = Apollo.BaseMutationOptions<Types.SetEmailVerifiedMutation, Types.SetEmailVerifiedMutationVariables>;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      displayOneOtherOnSignUp: // value for 'displayOneOtherOnSignUp'
 *      companyLabel: // value for 'companyLabel'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ActivitiesQuery, Types.ActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActivitiesQuery, Types.ActivitiesQueryVariables>(Operations.Activities, options);
      }
export function useActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActivitiesQuery, Types.ActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActivitiesQuery, Types.ActivitiesQueryVariables>(Operations.Activities, options);
        }
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<Types.ActivitiesQuery, Types.ActivitiesQueryVariables>;

/**
 * __useActivityCategoriesQuery__
 *
 * To run a query within a React component, call `useActivityCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityCategoriesQuery({
 *   variables: {
 *      onlyCrafts: // value for 'onlyCrafts'
 *      includeOther: // value for 'includeOther'
 *   },
 * });
 */
export function useActivityCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ActivityCategoriesQuery, Types.ActivityCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActivityCategoriesQuery, Types.ActivityCategoriesQueryVariables>(Operations.ActivityCategories, options);
      }
export function useActivityCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActivityCategoriesQuery, Types.ActivityCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActivityCategoriesQuery, Types.ActivityCategoriesQueryVariables>(Operations.ActivityCategories, options);
        }
export type ActivityCategoriesQueryHookResult = ReturnType<typeof useActivityCategoriesQuery>;
export type ActivityCategoriesLazyQueryHookResult = ReturnType<typeof useActivityCategoriesLazyQuery>;
export type ActivityCategoriesQueryResult = Apollo.QueryResult<Types.ActivityCategoriesQuery, Types.ActivityCategoriesQueryVariables>;

/**
 * __useActivityCategorySubcategoriesQuery__
 *
 * To run a query within a React component, call `useActivityCategorySubcategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityCategorySubcategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityCategorySubcategoriesQuery({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *   },
 * });
 */
export function useActivityCategorySubcategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ActivityCategorySubcategoriesQuery, Types.ActivityCategorySubcategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActivityCategorySubcategoriesQuery, Types.ActivityCategorySubcategoriesQueryVariables>(Operations.ActivityCategorySubcategories, options);
      }
export function useActivityCategorySubcategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActivityCategorySubcategoriesQuery, Types.ActivityCategorySubcategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActivityCategorySubcategoriesQuery, Types.ActivityCategorySubcategoriesQueryVariables>(Operations.ActivityCategorySubcategories, options);
        }
export type ActivityCategorySubcategoriesQueryHookResult = ReturnType<typeof useActivityCategorySubcategoriesQuery>;
export type ActivityCategorySubcategoriesLazyQueryHookResult = ReturnType<typeof useActivityCategorySubcategoriesLazyQuery>;
export type ActivityCategorySubcategoriesQueryResult = Apollo.QueryResult<Types.ActivityCategorySubcategoriesQuery, Types.ActivityCategorySubcategoriesQueryVariables>;

/**
 * __useAdQuery__
 *
 * To run a query within a React component, call `useAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdQuery(baseOptions: Apollo.QueryHookOptions<Types.AdQuery, Types.AdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdQuery, Types.AdQueryVariables>(Operations.Ad, options);
      }
export function useAdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdQuery, Types.AdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdQuery, Types.AdQueryVariables>(Operations.Ad, options);
        }
export type AdQueryHookResult = ReturnType<typeof useAdQuery>;
export type AdLazyQueryHookResult = ReturnType<typeof useAdLazyQuery>;
export type AdQueryResult = Apollo.QueryResult<Types.AdQuery, Types.AdQueryVariables>;

/**
 * __useAdLightQuery__
 *
 * To run a query within a React component, call `useAdLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdLightQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdLightQuery(baseOptions: Apollo.QueryHookOptions<Types.AdLightQuery, Types.AdLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdLightQuery, Types.AdLightQueryVariables>(Operations.AdLight, options);
      }
export function useAdLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdLightQuery, Types.AdLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdLightQuery, Types.AdLightQueryVariables>(Operations.AdLight, options);
        }
export type AdLightQueryHookResult = ReturnType<typeof useAdLightQuery>;
export type AdLightLazyQueryHookResult = ReturnType<typeof useAdLightLazyQuery>;
export type AdLightQueryResult = Apollo.QueryResult<Types.AdLightQuery, Types.AdLightQueryVariables>;

/**
 * __useAdsQuery__
 *
 * To run a query within a React component, call `useAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      minLandArea: // value for 'minLandArea'
 *      maxLandArea: // value for 'maxLandArea'
 *      minLivingArea: // value for 'minLivingArea'
 *      maxLivingArea: // value for 'maxLivingArea'
 *      rooms: // value for 'rooms'
 *      bedrooms: // value for 'bedrooms'
 *      house: // value for 'house'
 *      apartment: // value for 'apartment'
 *      loft: // value for 'loft'
 *      parking: // value for 'parking'
 *      terrain: // value for 'terrain'
 *      newProperty: // value for 'newProperty'
 *      oldProperty: // value for 'oldProperty'
 *      interOffice: // value for 'interOffice'
 *      furnished: // value for 'furnished'
 *      type: // value for 'type'
 *      assets: // value for 'assets'
 *      isPrivateAdvertiser: // value for 'isPrivateAdvertiser'
 *      realEstateTradesAdvertiser: // value for 'realEstateTradesAdvertiser'
 *      statuses: // value for 'statuses'
 *      withoutAssignation: // value for 'withoutAssignation'
 *      assignatedToUserId: // value for 'assignatedToUserId'
 *      places: // value for 'places'
 *   },
 * });
 */
export function useAdsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdsQuery, Types.AdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdsQuery, Types.AdsQueryVariables>(Operations.Ads, options);
      }
export function useAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdsQuery, Types.AdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdsQuery, Types.AdsQueryVariables>(Operations.Ads, options);
        }
export type AdsQueryHookResult = ReturnType<typeof useAdsQuery>;
export type AdsLazyQueryHookResult = ReturnType<typeof useAdsLazyQuery>;
export type AdsQueryResult = Apollo.QueryResult<Types.AdsQuery, Types.AdsQueryVariables>;

/**
 * __useUserAdsSearchQuery__
 *
 * To run a query within a React component, call `useUserAdsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdsSearchQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      rooms: // value for 'rooms'
 *      house: // value for 'house'
 *      apartment: // value for 'apartment'
 *      loft: // value for 'loft'
 *      parking: // value for 'parking'
 *      terrain: // value for 'terrain'
 *      newProperty: // value for 'newProperty'
 *      oldProperty: // value for 'oldProperty'
 *      type: // value for 'type'
 *      statuses: // value for 'statuses'
 *      places: // value for 'places'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAdsSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserAdsSearchQuery, Types.UserAdsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserAdsSearchQuery, Types.UserAdsSearchQueryVariables>(Operations.UserAdsSearch, options);
      }
export function useUserAdsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserAdsSearchQuery, Types.UserAdsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserAdsSearchQuery, Types.UserAdsSearchQueryVariables>(Operations.UserAdsSearch, options);
        }
export type UserAdsSearchQueryHookResult = ReturnType<typeof useUserAdsSearchQuery>;
export type UserAdsSearchLazyQueryHookResult = ReturnType<typeof useUserAdsSearchLazyQuery>;
export type UserAdsSearchQueryResult = Apollo.QueryResult<Types.UserAdsSearchQuery, Types.UserAdsSearchQueryVariables>;

/**
 * __useCompanyAdsSearchQuery__
 *
 * To run a query within a React component, call `useCompanyAdsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAdsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAdsSearchQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      rooms: // value for 'rooms'
 *      house: // value for 'house'
 *      apartment: // value for 'apartment'
 *      loft: // value for 'loft'
 *      parking: // value for 'parking'
 *      terrain: // value for 'terrain'
 *      newProperty: // value for 'newProperty'
 *      oldProperty: // value for 'oldProperty'
 *      type: // value for 'type'
 *      companyId: // value for 'companyId'
 *      statuses: // value for 'statuses'
 *      withoutAssignation: // value for 'withoutAssignation'
 *      assignatedToUserId: // value for 'assignatedToUserId'
 *      places: // value for 'places'
 *   },
 * });
 */
export function useCompanyAdsSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyAdsSearchQuery, Types.CompanyAdsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyAdsSearchQuery, Types.CompanyAdsSearchQueryVariables>(Operations.CompanyAdsSearch, options);
      }
export function useCompanyAdsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyAdsSearchQuery, Types.CompanyAdsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyAdsSearchQuery, Types.CompanyAdsSearchQueryVariables>(Operations.CompanyAdsSearch, options);
        }
export type CompanyAdsSearchQueryHookResult = ReturnType<typeof useCompanyAdsSearchQuery>;
export type CompanyAdsSearchLazyQueryHookResult = ReturnType<typeof useCompanyAdsSearchLazyQuery>;
export type CompanyAdsSearchQueryResult = Apollo.QueryResult<Types.CompanyAdsSearchQuery, Types.CompanyAdsSearchQueryVariables>;

/**
 * __useAdsForMapQuery__
 *
 * To run a query within a React component, call `useAdsForMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdsForMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdsForMapQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      minLandArea: // value for 'minLandArea'
 *      maxLandArea: // value for 'maxLandArea'
 *      minLivingArea: // value for 'minLivingArea'
 *      maxLivingArea: // value for 'maxLivingArea'
 *      rooms: // value for 'rooms'
 *      bedrooms: // value for 'bedrooms'
 *      house: // value for 'house'
 *      apartment: // value for 'apartment'
 *      loft: // value for 'loft'
 *      parking: // value for 'parking'
 *      terrain: // value for 'terrain'
 *      newProperty: // value for 'newProperty'
 *      oldProperty: // value for 'oldProperty'
 *      interOffice: // value for 'interOffice'
 *      furnished: // value for 'furnished'
 *      type: // value for 'type'
 *      assets: // value for 'assets'
 *      isPrivateAdvertiser: // value for 'isPrivateAdvertiser'
 *      realEstateTradesAdvertiser: // value for 'realEstateTradesAdvertiser'
 *      statuses: // value for 'statuses'
 *      places: // value for 'places'
 *   },
 * });
 */
export function useAdsForMapQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdsForMapQuery, Types.AdsForMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdsForMapQuery, Types.AdsForMapQueryVariables>(Operations.AdsForMap, options);
      }
export function useAdsForMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdsForMapQuery, Types.AdsForMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdsForMapQuery, Types.AdsForMapQueryVariables>(Operations.AdsForMap, options);
        }
export type AdsForMapQueryHookResult = ReturnType<typeof useAdsForMapQuery>;
export type AdsForMapLazyQueryHookResult = ReturnType<typeof useAdsForMapLazyQuery>;
export type AdsForMapQueryResult = Apollo.QueryResult<Types.AdsForMapQuery, Types.AdsForMapQueryVariables>;

/**
 * __useAdsTotalCountQuery__
 *
 * To run a query within a React component, call `useAdsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdsTotalCountQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      minLandArea: // value for 'minLandArea'
 *      maxLandArea: // value for 'maxLandArea'
 *      minLivingArea: // value for 'minLivingArea'
 *      maxLivingArea: // value for 'maxLivingArea'
 *      rooms: // value for 'rooms'
 *      bedrooms: // value for 'bedrooms'
 *      house: // value for 'house'
 *      apartment: // value for 'apartment'
 *      loft: // value for 'loft'
 *      parking: // value for 'parking'
 *      terrain: // value for 'terrain'
 *      newProperty: // value for 'newProperty'
 *      oldProperty: // value for 'oldProperty'
 *      interOffice: // value for 'interOffice'
 *      furnished: // value for 'furnished'
 *      type: // value for 'type'
 *      assets: // value for 'assets'
 *      isPrivateAdvertiser: // value for 'isPrivateAdvertiser'
 *      realEstateTradesAdvertiser: // value for 'realEstateTradesAdvertiser'
 *      statuses: // value for 'statuses'
 *      places: // value for 'places'
 *   },
 * });
 */
export function useAdsTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdsTotalCountQuery, Types.AdsTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdsTotalCountQuery, Types.AdsTotalCountQueryVariables>(Operations.AdsTotalCount, options);
      }
export function useAdsTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdsTotalCountQuery, Types.AdsTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdsTotalCountQuery, Types.AdsTotalCountQueryVariables>(Operations.AdsTotalCount, options);
        }
export type AdsTotalCountQueryHookResult = ReturnType<typeof useAdsTotalCountQuery>;
export type AdsTotalCountLazyQueryHookResult = ReturnType<typeof useAdsTotalCountLazyQuery>;
export type AdsTotalCountQueryResult = Apollo.QueryResult<Types.AdsTotalCountQuery, Types.AdsTotalCountQueryVariables>;

/**
 * __useAdPublishersQuery__
 *
 * To run a query within a React component, call `useAdPublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdPublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdPublishersQuery({
 *   variables: {
 *      adId: // value for 'adId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdPublishersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdPublishersQuery, Types.AdPublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdPublishersQuery, Types.AdPublishersQueryVariables>(Operations.AdPublishers, options);
      }
export function useAdPublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdPublishersQuery, Types.AdPublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdPublishersQuery, Types.AdPublishersQueryVariables>(Operations.AdPublishers, options);
        }
export type AdPublishersQueryHookResult = ReturnType<typeof useAdPublishersQuery>;
export type AdPublishersLazyQueryHookResult = ReturnType<typeof useAdPublishersLazyQuery>;
export type AdPublishersQueryResult = Apollo.QueryResult<Types.AdPublishersQuery, Types.AdPublishersQueryVariables>;

/**
 * __useAdminRequestsQuery__
 *
 * To run a query within a React component, call `useAdminRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminRequestsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      status: // value for 'status'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAdminRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminRequestsQuery, Types.AdminRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminRequestsQuery, Types.AdminRequestsQueryVariables>(Operations.AdminRequests, options);
      }
export function useAdminRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminRequestsQuery, Types.AdminRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminRequestsQuery, Types.AdminRequestsQueryVariables>(Operations.AdminRequests, options);
        }
export type AdminRequestsQueryHookResult = ReturnType<typeof useAdminRequestsQuery>;
export type AdminRequestsLazyQueryHookResult = ReturnType<typeof useAdminRequestsLazyQuery>;
export type AdminRequestsQueryResult = Apollo.QueryResult<Types.AdminRequestsQuery, Types.AdminRequestsQueryVariables>;

/**
 * __useAgentsQuery__
 *
 * To run a query within a React component, call `useAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAgentsQuery(baseOptions: Apollo.QueryHookOptions<Types.AgentsQuery, Types.AgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AgentsQuery, Types.AgentsQueryVariables>(Operations.Agents, options);
      }
export function useAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AgentsQuery, Types.AgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AgentsQuery, Types.AgentsQueryVariables>(Operations.Agents, options);
        }
export type AgentsQueryHookResult = ReturnType<typeof useAgentsQuery>;
export type AgentsLazyQueryHookResult = ReturnType<typeof useAgentsLazyQuery>;
export type AgentsQueryResult = Apollo.QueryResult<Types.AgentsQuery, Types.AgentsQueryVariables>;

/**
 * __useChatUserQuery__
 *
 * To run a query within a React component, call `useChatUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUserQuery({
 *   variables: {
 *      sendbirdUserId: // value for 'sendbirdUserId'
 *   },
 * });
 */
export function useChatUserQuery(baseOptions: Apollo.QueryHookOptions<Types.ChatUserQuery, Types.ChatUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChatUserQuery, Types.ChatUserQueryVariables>(Operations.ChatUser, options);
      }
export function useChatUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChatUserQuery, Types.ChatUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChatUserQuery, Types.ChatUserQueryVariables>(Operations.ChatUser, options);
        }
export type ChatUserQueryHookResult = ReturnType<typeof useChatUserQuery>;
export type ChatUserLazyQueryHookResult = ReturnType<typeof useChatUserLazyQuery>;
export type ChatUserQueryResult = Apollo.QueryResult<Types.ChatUserQuery, Types.ChatUserQueryVariables>;

/**
 * __useChatUserLightQuery__
 *
 * To run a query within a React component, call `useChatUserLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatUserLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUserLightQuery({
 *   variables: {
 *      sendbirdUserId: // value for 'sendbirdUserId'
 *   },
 * });
 */
export function useChatUserLightQuery(baseOptions: Apollo.QueryHookOptions<Types.ChatUserLightQuery, Types.ChatUserLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChatUserLightQuery, Types.ChatUserLightQueryVariables>(Operations.ChatUserLight, options);
      }
export function useChatUserLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChatUserLightQuery, Types.ChatUserLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChatUserLightQuery, Types.ChatUserLightQueryVariables>(Operations.ChatUserLight, options);
        }
export type ChatUserLightQueryHookResult = ReturnType<typeof useChatUserLightQuery>;
export type ChatUserLightLazyQueryHookResult = ReturnType<typeof useChatUserLightLazyQuery>;
export type ChatUserLightQueryResult = Apollo.QueryResult<Types.ChatUserLightQuery, Types.ChatUserLightQueryVariables>;

/**
 * __useCompanyMembersAndPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useCompanyMembersAndPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembersAndPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembersAndPendingInvitationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      query: // value for 'query'
 *      searchFields: // value for 'searchFields'
 *      match: // value for 'match'
 *   },
 * });
 */
export function useCompanyMembersAndPendingInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyMembersAndPendingInvitationsQuery, Types.CompanyMembersAndPendingInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyMembersAndPendingInvitationsQuery, Types.CompanyMembersAndPendingInvitationsQueryVariables>(Operations.CompanyMembersAndPendingInvitations, options);
      }
export function useCompanyMembersAndPendingInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyMembersAndPendingInvitationsQuery, Types.CompanyMembersAndPendingInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyMembersAndPendingInvitationsQuery, Types.CompanyMembersAndPendingInvitationsQueryVariables>(Operations.CompanyMembersAndPendingInvitations, options);
        }
export type CompanyMembersAndPendingInvitationsQueryHookResult = ReturnType<typeof useCompanyMembersAndPendingInvitationsQuery>;
export type CompanyMembersAndPendingInvitationsLazyQueryHookResult = ReturnType<typeof useCompanyMembersAndPendingInvitationsLazyQuery>;
export type CompanyMembersAndPendingInvitationsQueryResult = Apollo.QueryResult<Types.CompanyMembersAndPendingInvitationsQuery, Types.CompanyMembersAndPendingInvitationsQueryVariables>;

/**
 * __useCompanyMembersWhoAreInMyContactsQuery__
 *
 * To run a query within a React component, call `useCompanyMembersWhoAreInMyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembersWhoAreInMyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembersWhoAreInMyContactsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *      searchFields: // value for 'searchFields'
 *      match: // value for 'match'
 *      activityIds: // value for 'activityIds'
 *   },
 * });
 */
export function useCompanyMembersWhoAreInMyContactsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyMembersWhoAreInMyContactsQuery, Types.CompanyMembersWhoAreInMyContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyMembersWhoAreInMyContactsQuery, Types.CompanyMembersWhoAreInMyContactsQueryVariables>(Operations.CompanyMembersWhoAreInMyContacts, options);
      }
export function useCompanyMembersWhoAreInMyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyMembersWhoAreInMyContactsQuery, Types.CompanyMembersWhoAreInMyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyMembersWhoAreInMyContactsQuery, Types.CompanyMembersWhoAreInMyContactsQueryVariables>(Operations.CompanyMembersWhoAreInMyContacts, options);
        }
export type CompanyMembersWhoAreInMyContactsQueryHookResult = ReturnType<typeof useCompanyMembersWhoAreInMyContactsQuery>;
export type CompanyMembersWhoAreInMyContactsLazyQueryHookResult = ReturnType<typeof useCompanyMembersWhoAreInMyContactsLazyQuery>;
export type CompanyMembersWhoAreInMyContactsQueryResult = Apollo.QueryResult<Types.CompanyMembersWhoAreInMyContactsQuery, Types.CompanyMembersWhoAreInMyContactsQueryVariables>;

/**
 * __useCompanyMembersWithTotalQuery__
 *
 * To run a query within a React component, call `useCompanyMembersWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembersWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembersWithTotalQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      query: // value for 'query'
 *      isDirector: // value for 'isDirector'
 *      isAdmin: // value for 'isAdmin'
 *      withRecursive: // value for 'withRecursive'
 *      onlyWithRecommendations: // value for 'onlyWithRecommendations'
 *   },
 * });
 */
export function useCompanyMembersWithTotalQuery(baseOptions?: Apollo.QueryHookOptions<Types.CompanyMembersWithTotalQuery, Types.CompanyMembersWithTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyMembersWithTotalQuery, Types.CompanyMembersWithTotalQueryVariables>(Operations.CompanyMembersWithTotal, options);
      }
export function useCompanyMembersWithTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyMembersWithTotalQuery, Types.CompanyMembersWithTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyMembersWithTotalQuery, Types.CompanyMembersWithTotalQueryVariables>(Operations.CompanyMembersWithTotal, options);
        }
export type CompanyMembersWithTotalQueryHookResult = ReturnType<typeof useCompanyMembersWithTotalQuery>;
export type CompanyMembersWithTotalLazyQueryHookResult = ReturnType<typeof useCompanyMembersWithTotalLazyQuery>;
export type CompanyMembersWithTotalQueryResult = Apollo.QueryResult<Types.CompanyMembersWithTotalQuery, Types.CompanyMembersWithTotalQueryVariables>;

/**
 * __useCompanyProfileQuery__
 *
 * To run a query within a React component, call `useCompanyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      showcaseLimit: // value for 'showcaseLimit'
 *   },
 * });
 */
export function useCompanyProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyProfileQuery, Types.CompanyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyProfileQuery, Types.CompanyProfileQueryVariables>(Operations.CompanyProfile, options);
      }
export function useCompanyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyProfileQuery, Types.CompanyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyProfileQuery, Types.CompanyProfileQueryVariables>(Operations.CompanyProfile, options);
        }
export type CompanyProfileQueryHookResult = ReturnType<typeof useCompanyProfileQuery>;
export type CompanyProfileLazyQueryHookResult = ReturnType<typeof useCompanyProfileLazyQuery>;
export type CompanyProfileQueryResult = Apollo.QueryResult<Types.CompanyProfileQuery, Types.CompanyProfileQueryVariables>;

/**
 * __useCompanyProfileCountsQuery__
 *
 * To run a query within a React component, call `useCompanyProfileCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileCountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyProfileCountsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyProfileCountsQuery, Types.CompanyProfileCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyProfileCountsQuery, Types.CompanyProfileCountsQueryVariables>(Operations.CompanyProfileCounts, options);
      }
export function useCompanyProfileCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyProfileCountsQuery, Types.CompanyProfileCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyProfileCountsQuery, Types.CompanyProfileCountsQueryVariables>(Operations.CompanyProfileCounts, options);
        }
export type CompanyProfileCountsQueryHookResult = ReturnType<typeof useCompanyProfileCountsQuery>;
export type CompanyProfileCountsLazyQueryHookResult = ReturnType<typeof useCompanyProfileCountsLazyQuery>;
export type CompanyProfileCountsQueryResult = Apollo.QueryResult<Types.CompanyProfileCountsQuery, Types.CompanyProfileCountsQueryVariables>;

/**
 * __useCompanyProjectsQuery__
 *
 * To run a query within a React component, call `useCompanyProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanyProjectsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyProjectsQuery, Types.CompanyProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyProjectsQuery, Types.CompanyProjectsQueryVariables>(Operations.CompanyProjects, options);
      }
export function useCompanyProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyProjectsQuery, Types.CompanyProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyProjectsQuery, Types.CompanyProjectsQueryVariables>(Operations.CompanyProjects, options);
        }
export type CompanyProjectsQueryHookResult = ReturnType<typeof useCompanyProjectsQuery>;
export type CompanyProjectsLazyQueryHookResult = ReturnType<typeof useCompanyProjectsLazyQuery>;
export type CompanyProjectsQueryResult = Apollo.QueryResult<Types.CompanyProjectsQuery, Types.CompanyProjectsQueryVariables>;

/**
 * __useCountsQuery__
 *
 * To run a query within a React component, call `useCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountsQuery, Types.CountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountsQuery, Types.CountsQueryVariables>(Operations.Counts, options);
      }
export function useCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountsQuery, Types.CountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountsQuery, Types.CountsQueryVariables>(Operations.Counts, options);
        }
export type CountsQueryHookResult = ReturnType<typeof useCountsQuery>;
export type CountsLazyQueryHookResult = ReturnType<typeof useCountsLazyQuery>;
export type CountsQueryResult = Apollo.QueryResult<Types.CountsQuery, Types.CountsQueryVariables>;

/**
 * __useCountsForSitemapQuery__
 *
 * To run a query within a React component, call `useCountsForSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountsForSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountsForSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountsForSitemapQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountsForSitemapQuery, Types.CountsForSitemapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountsForSitemapQuery, Types.CountsForSitemapQueryVariables>(Operations.CountsForSitemap, options);
      }
export function useCountsForSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountsForSitemapQuery, Types.CountsForSitemapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountsForSitemapQuery, Types.CountsForSitemapQueryVariables>(Operations.CountsForSitemap, options);
        }
export type CountsForSitemapQueryHookResult = ReturnType<typeof useCountsForSitemapQuery>;
export type CountsForSitemapLazyQueryHookResult = ReturnType<typeof useCountsForSitemapLazyQuery>;
export type CountsForSitemapQueryResult = Apollo.QueryResult<Types.CountsForSitemapQuery, Types.CountsForSitemapQueryVariables>;

/**
 * __useCrmSourcesQuery__
 *
 * To run a query within a React component, call `useCrmSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmSourcesQuery({
 *   variables: {
 *      withOther: // value for 'withOther'
 *   },
 * });
 */
export function useCrmSourcesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CrmSourcesQuery, Types.CrmSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CrmSourcesQuery, Types.CrmSourcesQueryVariables>(Operations.CrmSources, options);
      }
export function useCrmSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CrmSourcesQuery, Types.CrmSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CrmSourcesQuery, Types.CrmSourcesQueryVariables>(Operations.CrmSources, options);
        }
export type CrmSourcesQueryHookResult = ReturnType<typeof useCrmSourcesQuery>;
export type CrmSourcesLazyQueryHookResult = ReturnType<typeof useCrmSourcesLazyQuery>;
export type CrmSourcesQueryResult = Apollo.QueryResult<Types.CrmSourcesQuery, Types.CrmSourcesQueryVariables>;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(Operations.CurrentUser, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(Operations.CurrentUser, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>;

/**
 * __useCurrentUserPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useCurrentUserPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPendingInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPendingInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserPendingInvitationsQuery, Types.CurrentUserPendingInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserPendingInvitationsQuery, Types.CurrentUserPendingInvitationsQueryVariables>(Operations.CurrentUserPendingInvitations, options);
      }
export function useCurrentUserPendingInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserPendingInvitationsQuery, Types.CurrentUserPendingInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserPendingInvitationsQuery, Types.CurrentUserPendingInvitationsQueryVariables>(Operations.CurrentUserPendingInvitations, options);
        }
export type CurrentUserPendingInvitationsQueryHookResult = ReturnType<typeof useCurrentUserPendingInvitationsQuery>;
export type CurrentUserPendingInvitationsLazyQueryHookResult = ReturnType<typeof useCurrentUserPendingInvitationsLazyQuery>;
export type CurrentUserPendingInvitationsQueryResult = Apollo.QueryResult<Types.CurrentUserPendingInvitationsQuery, Types.CurrentUserPendingInvitationsQueryVariables>;

/**
 * __useCurrentUserProfileQuery__
 *
 * To run a query within a React component, call `useCurrentUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserProfileQuery({
 *   variables: {
 *      showcaseLimit: // value for 'showcaseLimit'
 *   },
 * });
 */
export function useCurrentUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserProfileQuery, Types.CurrentUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserProfileQuery, Types.CurrentUserProfileQueryVariables>(Operations.CurrentUserProfile, options);
      }
export function useCurrentUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserProfileQuery, Types.CurrentUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserProfileQuery, Types.CurrentUserProfileQueryVariables>(Operations.CurrentUserProfile, options);
        }
export type CurrentUserProfileQueryHookResult = ReturnType<typeof useCurrentUserProfileQuery>;
export type CurrentUserProfileLazyQueryHookResult = ReturnType<typeof useCurrentUserProfileLazyQuery>;
export type CurrentUserProfileQueryResult = Apollo.QueryResult<Types.CurrentUserProfileQuery, Types.CurrentUserProfileQueryVariables>;

/**
 * __useDataForSitemapQuery__
 *
 * To run a query within a React component, call `useDataForSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataForSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataForSitemapQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDataForSitemapQuery(baseOptions?: Apollo.QueryHookOptions<Types.DataForSitemapQuery, Types.DataForSitemapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataForSitemapQuery, Types.DataForSitemapQueryVariables>(Operations.DataForSitemap, options);
      }
export function useDataForSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataForSitemapQuery, Types.DataForSitemapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataForSitemapQuery, Types.DataForSitemapQueryVariables>(Operations.DataForSitemap, options);
        }
export type DataForSitemapQueryHookResult = ReturnType<typeof useDataForSitemapQuery>;
export type DataForSitemapLazyQueryHookResult = ReturnType<typeof useDataForSitemapLazyQuery>;
export type DataForSitemapQueryResult = Apollo.QueryResult<Types.DataForSitemapQuery, Types.DataForSitemapQueryVariables>;

/**
 * __useFollowersQuery__
 *
 * To run a query within a React component, call `useFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      match: // value for 'match'
 *      activityIds: // value for 'activityIds'
 *      query: // value for 'query'
 *      searchFields: // value for 'searchFields'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFollowersQuery(baseOptions?: Apollo.QueryHookOptions<Types.FollowersQuery, Types.FollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FollowersQuery, Types.FollowersQueryVariables>(Operations.Followers, options);
      }
export function useFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FollowersQuery, Types.FollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FollowersQuery, Types.FollowersQueryVariables>(Operations.Followers, options);
        }
export type FollowersQueryHookResult = ReturnType<typeof useFollowersQuery>;
export type FollowersLazyQueryHookResult = ReturnType<typeof useFollowersLazyQuery>;
export type FollowersQueryResult = Apollo.QueryResult<Types.FollowersQuery, Types.FollowersQueryVariables>;

/**
 * __useFollowingQuery__
 *
 * To run a query within a React component, call `useFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      match: // value for 'match'
 *      activityIds: // value for 'activityIds'
 *      query: // value for 'query'
 *      searchFields: // value for 'searchFields'
 *   },
 * });
 */
export function useFollowingQuery(baseOptions?: Apollo.QueryHookOptions<Types.FollowingQuery, Types.FollowingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FollowingQuery, Types.FollowingQueryVariables>(Operations.Following, options);
      }
export function useFollowingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FollowingQuery, Types.FollowingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FollowingQuery, Types.FollowingQueryVariables>(Operations.Following, options);
        }
export type FollowingQueryHookResult = ReturnType<typeof useFollowingQuery>;
export type FollowingLazyQueryHookResult = ReturnType<typeof useFollowingLazyQuery>;
export type FollowingQueryResult = Apollo.QueryResult<Types.FollowingQuery, Types.FollowingQueryVariables>;

/**
 * __useGalleriesQuery__
 *
 * To run a query within a React component, call `useGalleriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleriesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGalleriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GalleriesQuery, Types.GalleriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GalleriesQuery, Types.GalleriesQueryVariables>(Operations.Galleries, options);
      }
export function useGalleriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GalleriesQuery, Types.GalleriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GalleriesQuery, Types.GalleriesQueryVariables>(Operations.Galleries, options);
        }
export type GalleriesQueryHookResult = ReturnType<typeof useGalleriesQuery>;
export type GalleriesLazyQueryHookResult = ReturnType<typeof useGalleriesLazyQuery>;
export type GalleriesQueryResult = Apollo.QueryResult<Types.GalleriesQuery, Types.GalleriesQueryVariables>;

/**
 * __useGalleryQuery__
 *
 * To run a query within a React component, call `useGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGalleryQuery(baseOptions: Apollo.QueryHookOptions<Types.GalleryQuery, Types.GalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GalleryQuery, Types.GalleryQueryVariables>(Operations.Gallery, options);
      }
export function useGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GalleryQuery, Types.GalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GalleryQuery, Types.GalleryQueryVariables>(Operations.Gallery, options);
        }
export type GalleryQueryHookResult = ReturnType<typeof useGalleryQuery>;
export type GalleryLazyQueryHookResult = ReturnType<typeof useGalleryLazyQuery>;
export type GalleryQueryResult = Apollo.QueryResult<Types.GalleryQuery, Types.GalleryQueryVariables>;

/**
 * __useGalleryContentQuery__
 *
 * To run a query within a React component, call `useGalleryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryContentQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      galleryId: // value for 'galleryId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGalleryContentQuery(baseOptions: Apollo.QueryHookOptions<Types.GalleryContentQuery, Types.GalleryContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GalleryContentQuery, Types.GalleryContentQueryVariables>(Operations.GalleryContent, options);
      }
export function useGalleryContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GalleryContentQuery, Types.GalleryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GalleryContentQuery, Types.GalleryContentQueryVariables>(Operations.GalleryContent, options);
        }
export type GalleryContentQueryHookResult = ReturnType<typeof useGalleryContentQuery>;
export type GalleryContentLazyQueryHookResult = ReturnType<typeof useGalleryContentLazyQuery>;
export type GalleryContentQueryResult = Apollo.QueryResult<Types.GalleryContentQuery, Types.GalleryContentQueryVariables>;

/**
 * __useGenerateFirebaseDynamicLinkQuery__
 *
 * To run a query within a React component, call `useGenerateFirebaseDynamicLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateFirebaseDynamicLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateFirebaseDynamicLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGenerateFirebaseDynamicLinkQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateFirebaseDynamicLinkQuery, Types.GenerateFirebaseDynamicLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateFirebaseDynamicLinkQuery, Types.GenerateFirebaseDynamicLinkQueryVariables>(Operations.GenerateFirebaseDynamicLink, options);
      }
export function useGenerateFirebaseDynamicLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateFirebaseDynamicLinkQuery, Types.GenerateFirebaseDynamicLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateFirebaseDynamicLinkQuery, Types.GenerateFirebaseDynamicLinkQueryVariables>(Operations.GenerateFirebaseDynamicLink, options);
        }
export type GenerateFirebaseDynamicLinkQueryHookResult = ReturnType<typeof useGenerateFirebaseDynamicLinkQuery>;
export type GenerateFirebaseDynamicLinkLazyQueryHookResult = ReturnType<typeof useGenerateFirebaseDynamicLinkLazyQuery>;
export type GenerateFirebaseDynamicLinkQueryResult = Apollo.QueryResult<Types.GenerateFirebaseDynamicLinkQuery, Types.GenerateFirebaseDynamicLinkQueryVariables>;

/**
 * __useGroupCompaniesQuery__
 *
 * To run a query within a React component, call `useGroupCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupCompaniesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      radiusKm: // value for 'radiusKm'
 *      orderBy: // value for 'orderBy'
 *      cityId: // value for 'cityId'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      onlyWithRecommendations: // value for 'onlyWithRecommendations'
 *   },
 * });
 */
export function useGroupCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.GroupCompaniesQuery, Types.GroupCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GroupCompaniesQuery, Types.GroupCompaniesQueryVariables>(Operations.GroupCompanies, options);
      }
export function useGroupCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GroupCompaniesQuery, Types.GroupCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GroupCompaniesQuery, Types.GroupCompaniesQueryVariables>(Operations.GroupCompanies, options);
        }
export type GroupCompaniesQueryHookResult = ReturnType<typeof useGroupCompaniesQuery>;
export type GroupCompaniesLazyQueryHookResult = ReturnType<typeof useGroupCompaniesLazyQuery>;
export type GroupCompaniesQueryResult = Apollo.QueryResult<Types.GroupCompaniesQuery, Types.GroupCompaniesQueryVariables>;

/**
 * __useHomadataQuery__
 *
 * To run a query within a React component, call `useHomadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomadataQuery({
 *   variables: {
 *      lng: // value for 'lng'
 *      lat: // value for 'lat'
 *      isOnEstimationFlow: // value for 'isOnEstimationFlow'
 *      propertyType: // value for 'propertyType'
 *      livingArea: // value for 'livingArea'
 *      landArea: // value for 'landArea'
 *      roomNumber: // value for 'roomNumber'
 *      bedroomNumber: // value for 'bedroomNumber'
 *      floorPosition: // value for 'floorPosition'
 *      floorNumber: // value for 'floorNumber'
 *      constructionYear: // value for 'constructionYear'
 *   },
 * });
 */
export function useHomadataQuery(baseOptions: Apollo.QueryHookOptions<Types.HomadataQuery, Types.HomadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomadataQuery, Types.HomadataQueryVariables>(Operations.Homadata, options);
      }
export function useHomadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomadataQuery, Types.HomadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomadataQuery, Types.HomadataQueryVariables>(Operations.Homadata, options);
        }
export type HomadataQueryHookResult = ReturnType<typeof useHomadataQuery>;
export type HomadataLazyQueryHookResult = ReturnType<typeof useHomadataLazyQuery>;
export type HomadataQueryResult = Apollo.QueryResult<Types.HomadataQuery, Types.HomadataQueryVariables>;

/**
 * __useHomeLoanQuery__
 *
 * To run a query within a React component, call `useHomeLoanQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeLoanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeLoanQuery({
 *   variables: {
 *      housePrice: // value for 'housePrice'
 *      intakeAmount: // value for 'intakeAmount'
 *      interestRate: // value for 'interestRate'
 *      yearNumber: // value for 'yearNumber'
 *   },
 * });
 */
export function useHomeLoanQuery(baseOptions: Apollo.QueryHookOptions<Types.HomeLoanQuery, Types.HomeLoanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomeLoanQuery, Types.HomeLoanQueryVariables>(Operations.HomeLoan, options);
      }
export function useHomeLoanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomeLoanQuery, Types.HomeLoanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomeLoanQuery, Types.HomeLoanQueryVariables>(Operations.HomeLoan, options);
        }
export type HomeLoanQueryHookResult = ReturnType<typeof useHomeLoanQuery>;
export type HomeLoanLazyQueryHookResult = ReturnType<typeof useHomeLoanLazyQuery>;
export type HomeLoanQueryResult = Apollo.QueryResult<Types.HomeLoanQuery, Types.HomeLoanQueryVariables>;

/**
 * __useInvitationEmailParametersQuery__
 *
 * To run a query within a React component, call `useInvitationEmailParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationEmailParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationEmailParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitationEmailParametersQuery(baseOptions?: Apollo.QueryHookOptions<Types.InvitationEmailParametersQuery, Types.InvitationEmailParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvitationEmailParametersQuery, Types.InvitationEmailParametersQueryVariables>(Operations.InvitationEmailParameters, options);
      }
export function useInvitationEmailParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvitationEmailParametersQuery, Types.InvitationEmailParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvitationEmailParametersQuery, Types.InvitationEmailParametersQueryVariables>(Operations.InvitationEmailParameters, options);
        }
export type InvitationEmailParametersQueryHookResult = ReturnType<typeof useInvitationEmailParametersQuery>;
export type InvitationEmailParametersLazyQueryHookResult = ReturnType<typeof useInvitationEmailParametersLazyQuery>;
export type InvitationEmailParametersQueryResult = Apollo.QueryResult<Types.InvitationEmailParametersQuery, Types.InvitationEmailParametersQueryVariables>;

/**
 * __useJobOfferQuery__
 *
 * To run a query within a React component, call `useJobOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.JobOfferQuery, Types.JobOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobOfferQuery, Types.JobOfferQueryVariables>(Operations.JobOffer, options);
      }
export function useJobOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobOfferQuery, Types.JobOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobOfferQuery, Types.JobOfferQueryVariables>(Operations.JobOffer, options);
        }
export type JobOfferQueryHookResult = ReturnType<typeof useJobOfferQuery>;
export type JobOfferLazyQueryHookResult = ReturnType<typeof useJobOfferLazyQuery>;
export type JobOfferQueryResult = Apollo.QueryResult<Types.JobOfferQuery, Types.JobOfferQueryVariables>;

/**
 * __useJobOffersQuery__
 *
 * To run a query within a React component, call `useJobOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOffersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      includeHidden: // value for 'includeHidden'
 *   },
 * });
 */
export function useJobOffersQuery(baseOptions?: Apollo.QueryHookOptions<Types.JobOffersQuery, Types.JobOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobOffersQuery, Types.JobOffersQueryVariables>(Operations.JobOffers, options);
      }
export function useJobOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobOffersQuery, Types.JobOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobOffersQuery, Types.JobOffersQueryVariables>(Operations.JobOffers, options);
        }
export type JobOffersQueryHookResult = ReturnType<typeof useJobOffersQuery>;
export type JobOffersLazyQueryHookResult = ReturnType<typeof useJobOffersLazyQuery>;
export type JobOffersQueryResult = Apollo.QueryResult<Types.JobOffersQuery, Types.JobOffersQueryVariables>;

/**
 * __useJobTypesQuery__
 *
 * To run a query within a React component, call `useJobTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.JobTypesQuery, Types.JobTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobTypesQuery, Types.JobTypesQueryVariables>(Operations.JobTypes, options);
      }
export function useJobTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobTypesQuery, Types.JobTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobTypesQuery, Types.JobTypesQueryVariables>(Operations.JobTypes, options);
        }
export type JobTypesQueryHookResult = ReturnType<typeof useJobTypesQuery>;
export type JobTypesLazyQueryHookResult = ReturnType<typeof useJobTypesLazyQuery>;
export type JobTypesQueryResult = Apollo.QueryResult<Types.JobTypesQuery, Types.JobTypesQueryVariables>;

/**
 * __useLegalLinksQuery__
 *
 * To run a query within a React component, call `useLegalLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalLinksQuery(baseOptions?: Apollo.QueryHookOptions<Types.LegalLinksQuery, Types.LegalLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LegalLinksQuery, Types.LegalLinksQueryVariables>(Operations.LegalLinks, options);
      }
export function useLegalLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LegalLinksQuery, Types.LegalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LegalLinksQuery, Types.LegalLinksQueryVariables>(Operations.LegalLinks, options);
        }
export type LegalLinksQueryHookResult = ReturnType<typeof useLegalLinksQuery>;
export type LegalLinksLazyQueryHookResult = ReturnType<typeof useLegalLinksLazyQuery>;
export type LegalLinksQueryResult = Apollo.QueryResult<Types.LegalLinksQuery, Types.LegalLinksQueryVariables>;

/**
 * __useLocalPriceEvolutionQuery__
 *
 * To run a query within a React component, call `useLocalPriceEvolutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalPriceEvolutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalPriceEvolutionQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      cityName: // value for 'cityName'
 *      propertyType: // value for 'propertyType'
 *   },
 * });
 */
export function useLocalPriceEvolutionQuery(baseOptions: Apollo.QueryHookOptions<Types.LocalPriceEvolutionQuery, Types.LocalPriceEvolutionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LocalPriceEvolutionQuery, Types.LocalPriceEvolutionQueryVariables>(Operations.LocalPriceEvolution, options);
      }
export function useLocalPriceEvolutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LocalPriceEvolutionQuery, Types.LocalPriceEvolutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LocalPriceEvolutionQuery, Types.LocalPriceEvolutionQueryVariables>(Operations.LocalPriceEvolution, options);
        }
export type LocalPriceEvolutionQueryHookResult = ReturnType<typeof useLocalPriceEvolutionQuery>;
export type LocalPriceEvolutionLazyQueryHookResult = ReturnType<typeof useLocalPriceEvolutionLazyQuery>;
export type LocalPriceEvolutionQueryResult = Apollo.QueryResult<Types.LocalPriceEvolutionQuery, Types.LocalPriceEvolutionQueryVariables>;

/**
 * __useLocateQuery__
 *
 * To run a query within a React component, call `useLocateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocateQuery({
 *   variables: {
 *      cityName: // value for 'cityName'
 *      fallbackToParis: // value for 'fallbackToParis'
 *   },
 * });
 */
export function useLocateQuery(baseOptions?: Apollo.QueryHookOptions<Types.LocateQuery, Types.LocateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LocateQuery, Types.LocateQueryVariables>(Operations.Locate, options);
      }
export function useLocateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LocateQuery, Types.LocateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LocateQuery, Types.LocateQueryVariables>(Operations.Locate, options);
        }
export type LocateQueryHookResult = ReturnType<typeof useLocateQuery>;
export type LocateLazyQueryHookResult = ReturnType<typeof useLocateLazyQuery>;
export type LocateQueryResult = Apollo.QueryResult<Types.LocateQuery, Types.LocateQueryVariables>;

/**
 * __useMembersAdsQuery__
 *
 * To run a query within a React component, call `useMembersAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersAdsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useMembersAdsQuery(baseOptions: Apollo.QueryHookOptions<Types.MembersAdsQuery, Types.MembersAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MembersAdsQuery, Types.MembersAdsQueryVariables>(Operations.MembersAds, options);
      }
export function useMembersAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MembersAdsQuery, Types.MembersAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MembersAdsQuery, Types.MembersAdsQueryVariables>(Operations.MembersAds, options);
        }
export type MembersAdsQueryHookResult = ReturnType<typeof useMembersAdsQuery>;
export type MembersAdsLazyQueryHookResult = ReturnType<typeof useMembersAdsLazyQuery>;
export type MembersAdsQueryResult = Apollo.QueryResult<Types.MembersAdsQuery, Types.MembersAdsQueryVariables>;

/**
 * __useMobileStoreLinksQuery__
 *
 * To run a query within a React component, call `useMobileStoreLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileStoreLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileStoreLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useMobileStoreLinksQuery(baseOptions?: Apollo.QueryHookOptions<Types.MobileStoreLinksQuery, Types.MobileStoreLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MobileStoreLinksQuery, Types.MobileStoreLinksQueryVariables>(Operations.MobileStoreLinks, options);
      }
export function useMobileStoreLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MobileStoreLinksQuery, Types.MobileStoreLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MobileStoreLinksQuery, Types.MobileStoreLinksQueryVariables>(Operations.MobileStoreLinks, options);
        }
export type MobileStoreLinksQueryHookResult = ReturnType<typeof useMobileStoreLinksQuery>;
export type MobileStoreLinksLazyQueryHookResult = ReturnType<typeof useMobileStoreLinksLazyQuery>;
export type MobileStoreLinksQueryResult = Apollo.QueryResult<Types.MobileStoreLinksQuery, Types.MobileStoreLinksQueryVariables>;

/**
 * __useMutualContactsQuery__
 *
 * To run a query within a React component, call `useMutualContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutualContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutualContactsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *      searchFields: // value for 'searchFields'
 *      match: // value for 'match'
 *      excludeFromResultsUserId: // value for 'excludeFromResultsUserId'
 *   },
 * });
 */
export function useMutualContactsQuery(baseOptions: Apollo.QueryHookOptions<Types.MutualContactsQuery, Types.MutualContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MutualContactsQuery, Types.MutualContactsQueryVariables>(Operations.MutualContacts, options);
      }
export function useMutualContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MutualContactsQuery, Types.MutualContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MutualContactsQuery, Types.MutualContactsQueryVariables>(Operations.MutualContacts, options);
        }
export type MutualContactsQueryHookResult = ReturnType<typeof useMutualContactsQuery>;
export type MutualContactsLazyQueryHookResult = ReturnType<typeof useMutualContactsLazyQuery>;
export type MutualContactsQueryResult = Apollo.QueryResult<Types.MutualContactsQuery, Types.MutualContactsQueryVariables>;

/**
 * __useMyContactsQuery__
 *
 * To run a query within a React component, call `useMyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyContactsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      statuses: // value for 'statuses'
 *      match: // value for 'match'
 *      activityIds: // value for 'activityIds'
 *      query: // value for 'query'
 *      searchFields: // value for 'searchFields'
 *   },
 * });
 */
export function useMyContactsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyContactsQuery, Types.MyContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyContactsQuery, Types.MyContactsQueryVariables>(Operations.MyContacts, options);
      }
export function useMyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyContactsQuery, Types.MyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyContactsQuery, Types.MyContactsQueryVariables>(Operations.MyContacts, options);
        }
export type MyContactsQueryHookResult = ReturnType<typeof useMyContactsQuery>;
export type MyContactsLazyQueryHookResult = ReturnType<typeof useMyContactsLazyQuery>;
export type MyContactsQueryResult = Apollo.QueryResult<Types.MyContactsQuery, Types.MyContactsQueryVariables>;

/**
 * __useMyDraftAdQuery__
 *
 * To run a query within a React component, call `useMyDraftAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDraftAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDraftAdQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyDraftAdQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyDraftAdQuery, Types.MyDraftAdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyDraftAdQuery, Types.MyDraftAdQueryVariables>(Operations.MyDraftAd, options);
      }
export function useMyDraftAdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyDraftAdQuery, Types.MyDraftAdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyDraftAdQuery, Types.MyDraftAdQueryVariables>(Operations.MyDraftAd, options);
        }
export type MyDraftAdQueryHookResult = ReturnType<typeof useMyDraftAdQuery>;
export type MyDraftAdLazyQueryHookResult = ReturnType<typeof useMyDraftAdLazyQuery>;
export type MyDraftAdQueryResult = Apollo.QueryResult<Types.MyDraftAdQuery, Types.MyDraftAdQueryVariables>;

/**
 * __useMyPendingContactsQuery__
 *
 * To run a query within a React component, call `useMyPendingContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPendingContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPendingContactsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMyPendingContactsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyPendingContactsQuery, Types.MyPendingContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyPendingContactsQuery, Types.MyPendingContactsQueryVariables>(Operations.MyPendingContacts, options);
      }
export function useMyPendingContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyPendingContactsQuery, Types.MyPendingContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyPendingContactsQuery, Types.MyPendingContactsQueryVariables>(Operations.MyPendingContacts, options);
        }
export type MyPendingContactsQueryHookResult = ReturnType<typeof useMyPendingContactsQuery>;
export type MyPendingContactsLazyQueryHookResult = ReturnType<typeof useMyPendingContactsLazyQuery>;
export type MyPendingContactsQueryResult = Apollo.QueryResult<Types.MyPendingContactsQuery, Types.MyPendingContactsQueryVariables>;

/**
 * __useMyPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useMyPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPendingInvitationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      side: // value for 'side'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMyPendingInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyPendingInvitationsQuery, Types.MyPendingInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyPendingInvitationsQuery, Types.MyPendingInvitationsQueryVariables>(Operations.MyPendingInvitations, options);
      }
export function useMyPendingInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyPendingInvitationsQuery, Types.MyPendingInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyPendingInvitationsQuery, Types.MyPendingInvitationsQueryVariables>(Operations.MyPendingInvitations, options);
        }
export type MyPendingInvitationsQueryHookResult = ReturnType<typeof useMyPendingInvitationsQuery>;
export type MyPendingInvitationsLazyQueryHookResult = ReturnType<typeof useMyPendingInvitationsLazyQuery>;
export type MyPendingInvitationsQueryResult = Apollo.QueryResult<Types.MyPendingInvitationsQuery, Types.MyPendingInvitationsQueryVariables>;

/**
 * __useMyRecommandationsCardQuery__
 *
 * To run a query within a React component, call `useMyRecommandationsCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecommandationsCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecommandationsCardQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyRecommandationsCardQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyRecommandationsCardQuery, Types.MyRecommandationsCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyRecommandationsCardQuery, Types.MyRecommandationsCardQueryVariables>(Operations.MyRecommandationsCard, options);
      }
export function useMyRecommandationsCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyRecommandationsCardQuery, Types.MyRecommandationsCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyRecommandationsCardQuery, Types.MyRecommandationsCardQueryVariables>(Operations.MyRecommandationsCard, options);
        }
export type MyRecommandationsCardQueryHookResult = ReturnType<typeof useMyRecommandationsCardQuery>;
export type MyRecommandationsCardLazyQueryHookResult = ReturnType<typeof useMyRecommandationsCardLazyQuery>;
export type MyRecommandationsCardQueryResult = Apollo.QueryResult<Types.MyRecommandationsCardQuery, Types.MyRecommandationsCardQueryVariables>;

/**
 * __useNewsCategoriesQuery__
 *
 * To run a query within a React component, call `useNewsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewsCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.NewsCategoriesQuery, Types.NewsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewsCategoriesQuery, Types.NewsCategoriesQueryVariables>(Operations.NewsCategories, options);
      }
export function useNewsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewsCategoriesQuery, Types.NewsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewsCategoriesQuery, Types.NewsCategoriesQueryVariables>(Operations.NewsCategories, options);
        }
export type NewsCategoriesQueryHookResult = ReturnType<typeof useNewsCategoriesQuery>;
export type NewsCategoriesLazyQueryHookResult = ReturnType<typeof useNewsCategoriesLazyQuery>;
export type NewsCategoriesQueryResult = Apollo.QueryResult<Types.NewsCategoriesQuery, Types.NewsCategoriesQueryVariables>;

/**
 * __useNewsFeedQuery__
 *
 * To run a query within a React component, call `useNewsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsFeedQuery({
 *   variables: {
 *      skipPosts: // value for 'skipPosts'
 *      skipNewsPosts: // value for 'skipNewsPosts'
 *      skipAds: // value for 'skipAds'
 *   },
 * });
 */
export function useNewsFeedQuery(baseOptions?: Apollo.QueryHookOptions<Types.NewsFeedQuery, Types.NewsFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewsFeedQuery, Types.NewsFeedQueryVariables>(Operations.NewsFeed, options);
      }
export function useNewsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewsFeedQuery, Types.NewsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewsFeedQuery, Types.NewsFeedQueryVariables>(Operations.NewsFeed, options);
        }
export type NewsFeedQueryHookResult = ReturnType<typeof useNewsFeedQuery>;
export type NewsFeedLazyQueryHookResult = ReturnType<typeof useNewsFeedLazyQuery>;
export type NewsFeedQueryResult = Apollo.QueryResult<Types.NewsFeedQuery, Types.NewsFeedQueryVariables>;

/**
 * __useNewsPostQuery__
 *
 * To run a query within a React component, call `useNewsPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPostQuery({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useNewsPostQuery(baseOptions: Apollo.QueryHookOptions<Types.NewsPostQuery, Types.NewsPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewsPostQuery, Types.NewsPostQueryVariables>(Operations.NewsPost, options);
      }
export function useNewsPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewsPostQuery, Types.NewsPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewsPostQuery, Types.NewsPostQueryVariables>(Operations.NewsPost, options);
        }
export type NewsPostQueryHookResult = ReturnType<typeof useNewsPostQuery>;
export type NewsPostLazyQueryHookResult = ReturnType<typeof useNewsPostLazyQuery>;
export type NewsPostQueryResult = Apollo.QueryResult<Types.NewsPostQuery, Types.NewsPostQueryVariables>;

/**
 * __useNewsPostLikesQuery__
 *
 * To run a query within a React component, call `useNewsPostLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPostLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPostLikesQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useNewsPostLikesQuery(baseOptions: Apollo.QueryHookOptions<Types.NewsPostLikesQuery, Types.NewsPostLikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewsPostLikesQuery, Types.NewsPostLikesQueryVariables>(Operations.NewsPostLikes, options);
      }
export function useNewsPostLikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewsPostLikesQuery, Types.NewsPostLikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewsPostLikesQuery, Types.NewsPostLikesQueryVariables>(Operations.NewsPostLikes, options);
        }
export type NewsPostLikesQueryHookResult = ReturnType<typeof useNewsPostLikesQuery>;
export type NewsPostLikesLazyQueryHookResult = ReturnType<typeof useNewsPostLikesLazyQuery>;
export type NewsPostLikesQueryResult = Apollo.QueryResult<Types.NewsPostLikesQuery, Types.NewsPostLikesQueryVariables>;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(Operations.Notifications, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(Operations.Notifications, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<Types.NotificationsQuery, Types.NotificationsQueryVariables>;

/**
 * __usePagesJaunesProQuery__
 *
 * To run a query within a React component, call `usePagesJaunesProQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesJaunesProQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesJaunesProQuery({
 *   variables: {
 *      proId: // value for 'proId'
 *   },
 * });
 */
export function usePagesJaunesProQuery(baseOptions: Apollo.QueryHookOptions<Types.PagesJaunesProQuery, Types.PagesJaunesProQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PagesJaunesProQuery, Types.PagesJaunesProQueryVariables>(Operations.PagesJaunesPro, options);
      }
export function usePagesJaunesProLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PagesJaunesProQuery, Types.PagesJaunesProQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PagesJaunesProQuery, Types.PagesJaunesProQueryVariables>(Operations.PagesJaunesPro, options);
        }
export type PagesJaunesProQueryHookResult = ReturnType<typeof usePagesJaunesProQuery>;
export type PagesJaunesProLazyQueryHookResult = ReturnType<typeof usePagesJaunesProLazyQuery>;
export type PagesJaunesProQueryResult = Apollo.QueryResult<Types.PagesJaunesProQuery, Types.PagesJaunesProQueryVariables>;

/**
 * __useParsedLinkQuery__
 *
 * To run a query within a React component, call `useParsedLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useParsedLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParsedLinkQuery({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useParsedLinkQuery(baseOptions: Apollo.QueryHookOptions<Types.ParsedLinkQuery, Types.ParsedLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ParsedLinkQuery, Types.ParsedLinkQueryVariables>(Operations.ParsedLink, options);
      }
export function useParsedLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ParsedLinkQuery, Types.ParsedLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ParsedLinkQuery, Types.ParsedLinkQueryVariables>(Operations.ParsedLink, options);
        }
export type ParsedLinkQueryHookResult = ReturnType<typeof useParsedLinkQuery>;
export type ParsedLinkLazyQueryHookResult = ReturnType<typeof useParsedLinkLazyQuery>;
export type ParsedLinkQueryResult = Apollo.QueryResult<Types.ParsedLinkQuery, Types.ParsedLinkQueryVariables>;

/**
 * __usePhoneContactsSyncQuery__
 *
 * To run a query within a React component, call `usePhoneContactsSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneContactsSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneContactsSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhoneContactsSyncQuery(baseOptions?: Apollo.QueryHookOptions<Types.PhoneContactsSyncQuery, Types.PhoneContactsSyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneContactsSyncQuery, Types.PhoneContactsSyncQueryVariables>(Operations.PhoneContactsSync, options);
      }
export function usePhoneContactsSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneContactsSyncQuery, Types.PhoneContactsSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneContactsSyncQuery, Types.PhoneContactsSyncQueryVariables>(Operations.PhoneContactsSync, options);
        }
export type PhoneContactsSyncQueryHookResult = ReturnType<typeof usePhoneContactsSyncQuery>;
export type PhoneContactsSyncLazyQueryHookResult = ReturnType<typeof usePhoneContactsSyncLazyQuery>;
export type PhoneContactsSyncQueryResult = Apollo.QueryResult<Types.PhoneContactsSyncQuery, Types.PhoneContactsSyncQueryVariables>;

/**
 * __usePhoneContactsWithTotalQuery__
 *
 * To run a query within a React component, call `usePhoneContactsWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneContactsWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneContactsWithTotalQuery({
 *   variables: {
 *      isSynchronized: // value for 'isSynchronized'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      match: // value for 'match'
 *      query: // value for 'query'
 *      searchFields: // value for 'searchFields'
 *      onlyWoopenContacts: // value for 'onlyWoopenContacts'
 *      onlyNonWoopenContacts: // value for 'onlyNonWoopenContacts'
 *   },
 * });
 */
export function usePhoneContactsWithTotalQuery(baseOptions?: Apollo.QueryHookOptions<Types.PhoneContactsWithTotalQuery, Types.PhoneContactsWithTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneContactsWithTotalQuery, Types.PhoneContactsWithTotalQueryVariables>(Operations.PhoneContactsWithTotal, options);
      }
export function usePhoneContactsWithTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneContactsWithTotalQuery, Types.PhoneContactsWithTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneContactsWithTotalQuery, Types.PhoneContactsWithTotalQueryVariables>(Operations.PhoneContactsWithTotal, options);
        }
export type PhoneContactsWithTotalQueryHookResult = ReturnType<typeof usePhoneContactsWithTotalQuery>;
export type PhoneContactsWithTotalLazyQueryHookResult = ReturnType<typeof usePhoneContactsWithTotalLazyQuery>;
export type PhoneContactsWithTotalQueryResult = Apollo.QueryResult<Types.PhoneContactsWithTotalQuery, Types.PhoneContactsWithTotalQueryVariables>;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<Types.PostQuery, Types.PostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PostQuery, Types.PostQueryVariables>(Operations.Post, options);
      }
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PostQuery, Types.PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PostQuery, Types.PostQueryVariables>(Operations.Post, options);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = Apollo.QueryResult<Types.PostQuery, Types.PostQueryVariables>;

/**
 * __usePostCommentsQuery__
 *
 * To run a query within a React component, call `usePostCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCommentsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePostCommentsQuery(baseOptions: Apollo.QueryHookOptions<Types.PostCommentsQuery, Types.PostCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PostCommentsQuery, Types.PostCommentsQueryVariables>(Operations.PostComments, options);
      }
export function usePostCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PostCommentsQuery, Types.PostCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PostCommentsQuery, Types.PostCommentsQueryVariables>(Operations.PostComments, options);
        }
export type PostCommentsQueryHookResult = ReturnType<typeof usePostCommentsQuery>;
export type PostCommentsLazyQueryHookResult = ReturnType<typeof usePostCommentsLazyQuery>;
export type PostCommentsQueryResult = Apollo.QueryResult<Types.PostCommentsQuery, Types.PostCommentsQueryVariables>;

/**
 * __usePostLikesQuery__
 *
 * To run a query within a React component, call `usePostLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostLikesQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePostLikesQuery(baseOptions: Apollo.QueryHookOptions<Types.PostLikesQuery, Types.PostLikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PostLikesQuery, Types.PostLikesQueryVariables>(Operations.PostLikes, options);
      }
export function usePostLikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PostLikesQuery, Types.PostLikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PostLikesQuery, Types.PostLikesQueryVariables>(Operations.PostLikes, options);
        }
export type PostLikesQueryHookResult = ReturnType<typeof usePostLikesQuery>;
export type PostLikesLazyQueryHookResult = ReturnType<typeof usePostLikesLazyQuery>;
export type PostLikesQueryResult = Apollo.QueryResult<Types.PostLikesQuery, Types.PostLikesQueryVariables>;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PostsQuery, Types.PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PostsQuery, Types.PostsQueryVariables>(Operations.Posts, options);
      }
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PostsQuery, Types.PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PostsQuery, Types.PostsQueryVariables>(Operations.Posts, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<Types.PostsQuery, Types.PostsQueryVariables>;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(Operations.Project, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(Operations.Project, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<Types.ProjectQuery, Types.ProjectQueryVariables>;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(Operations.Projects, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(Operations.Projects, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<Types.ProjectsQuery, Types.ProjectsQueryVariables>;

/**
 * __useQuickSignupCompanyQuery__
 *
 * To run a query within a React component, call `useQuickSignupCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSignupCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSignupCompanyQuery({
 *   variables: {
 *      quickSignupCompanyId: // value for 'quickSignupCompanyId'
 *   },
 * });
 */
export function useQuickSignupCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.QuickSignupCompanyQuery, Types.QuickSignupCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuickSignupCompanyQuery, Types.QuickSignupCompanyQueryVariables>(Operations.QuickSignupCompany, options);
      }
export function useQuickSignupCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuickSignupCompanyQuery, Types.QuickSignupCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuickSignupCompanyQuery, Types.QuickSignupCompanyQueryVariables>(Operations.QuickSignupCompany, options);
        }
export type QuickSignupCompanyQueryHookResult = ReturnType<typeof useQuickSignupCompanyQuery>;
export type QuickSignupCompanyLazyQueryHookResult = ReturnType<typeof useQuickSignupCompanyLazyQuery>;
export type QuickSignupCompanyQueryResult = Apollo.QueryResult<Types.QuickSignupCompanyQuery, Types.QuickSignupCompanyQueryVariables>;

/**
 * __useQuickSignupUserQuery__
 *
 * To run a query within a React component, call `useQuickSignupUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSignupUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSignupUserQuery({
 *   variables: {
 *      quickSignupUserId: // value for 'quickSignupUserId'
 *      isPro: // value for 'isPro'
 *   },
 * });
 */
export function useQuickSignupUserQuery(baseOptions: Apollo.QueryHookOptions<Types.QuickSignupUserQuery, Types.QuickSignupUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuickSignupUserQuery, Types.QuickSignupUserQueryVariables>(Operations.QuickSignupUser, options);
      }
export function useQuickSignupUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuickSignupUserQuery, Types.QuickSignupUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuickSignupUserQuery, Types.QuickSignupUserQueryVariables>(Operations.QuickSignupUser, options);
        }
export type QuickSignupUserQueryHookResult = ReturnType<typeof useQuickSignupUserQuery>;
export type QuickSignupUserLazyQueryHookResult = ReturnType<typeof useQuickSignupUserLazyQuery>;
export type QuickSignupUserQueryResult = Apollo.QueryResult<Types.QuickSignupUserQuery, Types.QuickSignupUserQueryVariables>;

/**
 * __useRatingsQuery__
 *
 * To run a query within a React component, call `useRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      withHiddenRatings: // value for 'withHiddenRatings'
 *      includeCurrentUserRatings: // value for 'includeCurrentUserRatings'
 *   },
 * });
 */
export function useRatingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.RatingsQuery, Types.RatingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RatingsQuery, Types.RatingsQueryVariables>(Operations.Ratings, options);
      }
export function useRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RatingsQuery, Types.RatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RatingsQuery, Types.RatingsQueryVariables>(Operations.Ratings, options);
        }
export type RatingsQueryHookResult = ReturnType<typeof useRatingsQuery>;
export type RatingsLazyQueryHookResult = ReturnType<typeof useRatingsLazyQuery>;
export type RatingsQueryResult = Apollo.QueryResult<Types.RatingsQuery, Types.RatingsQueryVariables>;

/**
 * __useRecommendationsReceivedQuery__
 *
 * To run a query within a React component, call `useRecommendationsReceivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsReceivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsReceivedQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      userId: // value for 'userId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useRecommendationsReceivedQuery(baseOptions?: Apollo.QueryHookOptions<Types.RecommendationsReceivedQuery, Types.RecommendationsReceivedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecommendationsReceivedQuery, Types.RecommendationsReceivedQueryVariables>(Operations.RecommendationsReceived, options);
      }
export function useRecommendationsReceivedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecommendationsReceivedQuery, Types.RecommendationsReceivedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecommendationsReceivedQuery, Types.RecommendationsReceivedQueryVariables>(Operations.RecommendationsReceived, options);
        }
export type RecommendationsReceivedQueryHookResult = ReturnType<typeof useRecommendationsReceivedQuery>;
export type RecommendationsReceivedLazyQueryHookResult = ReturnType<typeof useRecommendationsReceivedLazyQuery>;
export type RecommendationsReceivedQueryResult = Apollo.QueryResult<Types.RecommendationsReceivedQuery, Types.RecommendationsReceivedQueryVariables>;

/**
 * __useRequestRecommendationsQuery__
 *
 * To run a query within a React component, call `useRequestRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestRecommendationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      recommendingUserId: // value for 'recommendingUserId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useRequestRecommendationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.RequestRecommendationsQuery, Types.RequestRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestRecommendationsQuery, Types.RequestRecommendationsQueryVariables>(Operations.RequestRecommendations, options);
      }
export function useRequestRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestRecommendationsQuery, Types.RequestRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestRecommendationsQuery, Types.RequestRecommendationsQueryVariables>(Operations.RequestRecommendations, options);
        }
export type RequestRecommendationsQueryHookResult = ReturnType<typeof useRequestRecommendationsQuery>;
export type RequestRecommendationsLazyQueryHookResult = ReturnType<typeof useRequestRecommendationsLazyQuery>;
export type RequestRecommendationsQueryResult = Apollo.QueryResult<Types.RequestRecommendationsQuery, Types.RequestRecommendationsQueryVariables>;

/**
 * __useRecommendingUsersQuery__
 *
 * To run a query within a React component, call `useRecommendingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendingUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      userId: // value for 'userId'
 *      orderBy: // value for 'orderBy'
 *      query: // value for 'query'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useRecommendingUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.RecommendingUsersQuery, Types.RecommendingUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecommendingUsersQuery, Types.RecommendingUsersQueryVariables>(Operations.RecommendingUsers, options);
      }
export function useRecommendingUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecommendingUsersQuery, Types.RecommendingUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecommendingUsersQuery, Types.RecommendingUsersQueryVariables>(Operations.RecommendingUsers, options);
        }
export type RecommendingUsersQueryHookResult = ReturnType<typeof useRecommendingUsersQuery>;
export type RecommendingUsersLazyQueryHookResult = ReturnType<typeof useRecommendingUsersLazyQuery>;
export type RecommendingUsersQueryResult = Apollo.QueryResult<Types.RecommendingUsersQuery, Types.RecommendingUsersQueryVariables>;

/**
 * __useSavedSearchesQuery__
 *
 * To run a query within a React component, call `useSavedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedSearchesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSavedSearchesQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavedSearchesQuery, Types.SavedSearchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavedSearchesQuery, Types.SavedSearchesQueryVariables>(Operations.SavedSearches, options);
      }
export function useSavedSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavedSearchesQuery, Types.SavedSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavedSearchesQuery, Types.SavedSearchesQueryVariables>(Operations.SavedSearches, options);
        }
export type SavedSearchesQueryHookResult = ReturnType<typeof useSavedSearchesQuery>;
export type SavedSearchesLazyQueryHookResult = ReturnType<typeof useSavedSearchesLazyQuery>;
export type SavedSearchesQueryResult = Apollo.QueryResult<Types.SavedSearchesQuery, Types.SavedSearchesQueryVariables>;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchQuery, Types.SearchQueryVariables>(Operations.Search, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchQuery, Types.SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchQuery, Types.SearchQueryVariables>(Operations.Search, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<Types.SearchQuery, Types.SearchQueryVariables>;

/**
 * __useSearchAdsCountSinceLastViewedQuery__
 *
 * To run a query within a React component, call `useSearchAdsCountSinceLastViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAdsCountSinceLastViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAdsCountSinceLastViewedQuery({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useSearchAdsCountSinceLastViewedQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchAdsCountSinceLastViewedQuery, Types.SearchAdsCountSinceLastViewedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchAdsCountSinceLastViewedQuery, Types.SearchAdsCountSinceLastViewedQueryVariables>(Operations.SearchAdsCountSinceLastViewed, options);
      }
export function useSearchAdsCountSinceLastViewedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchAdsCountSinceLastViewedQuery, Types.SearchAdsCountSinceLastViewedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchAdsCountSinceLastViewedQuery, Types.SearchAdsCountSinceLastViewedQueryVariables>(Operations.SearchAdsCountSinceLastViewed, options);
        }
export type SearchAdsCountSinceLastViewedQueryHookResult = ReturnType<typeof useSearchAdsCountSinceLastViewedQuery>;
export type SearchAdsCountSinceLastViewedLazyQueryHookResult = ReturnType<typeof useSearchAdsCountSinceLastViewedLazyQuery>;
export type SearchAdsCountSinceLastViewedQueryResult = Apollo.QueryResult<Types.SearchAdsCountSinceLastViewedQuery, Types.SearchAdsCountSinceLastViewedQueryVariables>;

/**
 * __useSearchAllUsersWithTotalQuery__
 *
 * To run a query within a React component, call `useSearchAllUsersWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllUsersWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllUsersWithTotalQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchAllUsersWithTotalQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchAllUsersWithTotalQuery, Types.SearchAllUsersWithTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchAllUsersWithTotalQuery, Types.SearchAllUsersWithTotalQueryVariables>(Operations.SearchAllUsersWithTotal, options);
      }
export function useSearchAllUsersWithTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchAllUsersWithTotalQuery, Types.SearchAllUsersWithTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchAllUsersWithTotalQuery, Types.SearchAllUsersWithTotalQueryVariables>(Operations.SearchAllUsersWithTotal, options);
        }
export type SearchAllUsersWithTotalQueryHookResult = ReturnType<typeof useSearchAllUsersWithTotalQuery>;
export type SearchAllUsersWithTotalLazyQueryHookResult = ReturnType<typeof useSearchAllUsersWithTotalLazyQuery>;
export type SearchAllUsersWithTotalQueryResult = Apollo.QueryResult<Types.SearchAllUsersWithTotalQuery, Types.SearchAllUsersWithTotalQueryVariables>;

/**
 * __useSearchCityQuery__
 *
 * To run a query within a React component, call `useSearchCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCityQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCityQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchCityQuery, Types.SearchCityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchCityQuery, Types.SearchCityQueryVariables>(Operations.SearchCity, options);
      }
export function useSearchCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchCityQuery, Types.SearchCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchCityQuery, Types.SearchCityQueryVariables>(Operations.SearchCity, options);
        }
export type SearchCityQueryHookResult = ReturnType<typeof useSearchCityQuery>;
export type SearchCityLazyQueryHookResult = ReturnType<typeof useSearchCityLazyQuery>;
export type SearchCityQueryResult = Apollo.QueryResult<Types.SearchCityQuery, Types.SearchCityQueryVariables>;

/**
 * __useSearchCommunityQuery__
 *
 * To run a query within a React component, call `useSearchCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCommunityQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      activityId: // value for 'activityId'
 *      categoryId: // value for 'categoryId'
 *      cityId: // value for 'cityId'
 *      cityRadiusKm: // value for 'cityRadiusKm'
 *      orderBy: // value for 'orderBy'
 *      communityEntityTypes: // value for 'communityEntityTypes'
 *   },
 * });
 */
export function useSearchCommunityQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchCommunityQuery, Types.SearchCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchCommunityQuery, Types.SearchCommunityQueryVariables>(Operations.SearchCommunity, options);
      }
export function useSearchCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchCommunityQuery, Types.SearchCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchCommunityQuery, Types.SearchCommunityQueryVariables>(Operations.SearchCommunity, options);
        }
export type SearchCommunityQueryHookResult = ReturnType<typeof useSearchCommunityQuery>;
export type SearchCommunityLazyQueryHookResult = ReturnType<typeof useSearchCommunityLazyQuery>;
export type SearchCommunityQueryResult = Apollo.QueryResult<Types.SearchCommunityQuery, Types.SearchCommunityQueryVariables>;

/**
 * __useSearchCompaniesForInvitationsQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesForInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesForInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesForInvitationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSearchCompaniesForInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchCompaniesForInvitationsQuery, Types.SearchCompaniesForInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchCompaniesForInvitationsQuery, Types.SearchCompaniesForInvitationsQueryVariables>(Operations.SearchCompaniesForInvitations, options);
      }
export function useSearchCompaniesForInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchCompaniesForInvitationsQuery, Types.SearchCompaniesForInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchCompaniesForInvitationsQuery, Types.SearchCompaniesForInvitationsQueryVariables>(Operations.SearchCompaniesForInvitations, options);
        }
export type SearchCompaniesForInvitationsQueryHookResult = ReturnType<typeof useSearchCompaniesForInvitationsQuery>;
export type SearchCompaniesForInvitationsLazyQueryHookResult = ReturnType<typeof useSearchCompaniesForInvitationsLazyQuery>;
export type SearchCompaniesForInvitationsQueryResult = Apollo.QueryResult<Types.SearchCompaniesForInvitationsQuery, Types.SearchCompaniesForInvitationsQueryVariables>;

/**
 * __useSearchCompaniesToJoinQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesToJoinQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesToJoinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesToJoinQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      shouldIncludePJPros: // value for 'shouldIncludePJPros'
 *   },
 * });
 */
export function useSearchCompaniesToJoinQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchCompaniesToJoinQuery, Types.SearchCompaniesToJoinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchCompaniesToJoinQuery, Types.SearchCompaniesToJoinQueryVariables>(Operations.SearchCompaniesToJoin, options);
      }
export function useSearchCompaniesToJoinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchCompaniesToJoinQuery, Types.SearchCompaniesToJoinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchCompaniesToJoinQuery, Types.SearchCompaniesToJoinQueryVariables>(Operations.SearchCompaniesToJoin, options);
        }
export type SearchCompaniesToJoinQueryHookResult = ReturnType<typeof useSearchCompaniesToJoinQuery>;
export type SearchCompaniesToJoinLazyQueryHookResult = ReturnType<typeof useSearchCompaniesToJoinLazyQuery>;
export type SearchCompaniesToJoinQueryResult = Apollo.QueryResult<Types.SearchCompaniesToJoinQuery, Types.SearchCompaniesToJoinQueryVariables>;

/**
 * __useSearchForSiretQuery__
 *
 * To run a query within a React component, call `useSearchForSiretQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForSiretQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForSiretQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchForSiretQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchForSiretQuery, Types.SearchForSiretQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchForSiretQuery, Types.SearchForSiretQueryVariables>(Operations.SearchForSiret, options);
      }
export function useSearchForSiretLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchForSiretQuery, Types.SearchForSiretQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchForSiretQuery, Types.SearchForSiretQueryVariables>(Operations.SearchForSiret, options);
        }
export type SearchForSiretQueryHookResult = ReturnType<typeof useSearchForSiretQuery>;
export type SearchForSiretLazyQueryHookResult = ReturnType<typeof useSearchForSiretLazyQuery>;
export type SearchForSiretQueryResult = Apollo.QueryResult<Types.SearchForSiretQuery, Types.SearchForSiretQueryVariables>;

/**
 * __useSearchProjectsQuery__
 *
 * To run a query within a React component, call `useSearchProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProjectsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSearchProjectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchProjectsQuery, Types.SearchProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchProjectsQuery, Types.SearchProjectsQueryVariables>(Operations.SearchProjects, options);
      }
export function useSearchProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchProjectsQuery, Types.SearchProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchProjectsQuery, Types.SearchProjectsQueryVariables>(Operations.SearchProjects, options);
        }
export type SearchProjectsQueryHookResult = ReturnType<typeof useSearchProjectsQuery>;
export type SearchProjectsLazyQueryHookResult = ReturnType<typeof useSearchProjectsLazyQuery>;
export type SearchProjectsQueryResult = Apollo.QueryResult<Types.SearchProjectsQuery, Types.SearchProjectsQueryVariables>;

/**
 * __useSearchRecommendedRealEstateQuery__
 *
 * To run a query within a React component, call `useSearchRecommendedRealEstateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRecommendedRealEstateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRecommendedRealEstateQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchRecommendedRealEstateQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchRecommendedRealEstateQuery, Types.SearchRecommendedRealEstateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchRecommendedRealEstateQuery, Types.SearchRecommendedRealEstateQueryVariables>(Operations.SearchRecommendedRealEstate, options);
      }
export function useSearchRecommendedRealEstateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchRecommendedRealEstateQuery, Types.SearchRecommendedRealEstateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchRecommendedRealEstateQuery, Types.SearchRecommendedRealEstateQueryVariables>(Operations.SearchRecommendedRealEstate, options);
        }
export type SearchRecommendedRealEstateQueryHookResult = ReturnType<typeof useSearchRecommendedRealEstateQuery>;
export type SearchRecommendedRealEstateLazyQueryHookResult = ReturnType<typeof useSearchRecommendedRealEstateLazyQuery>;
export type SearchRecommendedRealEstateQueryResult = Apollo.QueryResult<Types.SearchRecommendedRealEstateQuery, Types.SearchRecommendedRealEstateQueryVariables>;

/**
 * __useSearchUsersAndCompaniesWithTotalQuery__
 *
 * To run a query within a React component, call `useSearchUsersAndCompaniesWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersAndCompaniesWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersAndCompaniesWithTotalQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchUsersAndCompaniesWithTotalQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchUsersAndCompaniesWithTotalQuery, Types.SearchUsersAndCompaniesWithTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchUsersAndCompaniesWithTotalQuery, Types.SearchUsersAndCompaniesWithTotalQueryVariables>(Operations.SearchUsersAndCompaniesWithTotal, options);
      }
export function useSearchUsersAndCompaniesWithTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchUsersAndCompaniesWithTotalQuery, Types.SearchUsersAndCompaniesWithTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchUsersAndCompaniesWithTotalQuery, Types.SearchUsersAndCompaniesWithTotalQueryVariables>(Operations.SearchUsersAndCompaniesWithTotal, options);
        }
export type SearchUsersAndCompaniesWithTotalQueryHookResult = ReturnType<typeof useSearchUsersAndCompaniesWithTotalQuery>;
export type SearchUsersAndCompaniesWithTotalLazyQueryHookResult = ReturnType<typeof useSearchUsersAndCompaniesWithTotalLazyQuery>;
export type SearchUsersAndCompaniesWithTotalQueryResult = Apollo.QueryResult<Types.SearchUsersAndCompaniesWithTotalQuery, Types.SearchUsersAndCompaniesWithTotalQueryVariables>;

/**
 * __useSearchUsersForInvitationsQuery__
 *
 * To run a query within a React component, call `useSearchUsersForInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersForInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersForInvitationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchUsersForInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchUsersForInvitationsQuery, Types.SearchUsersForInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchUsersForInvitationsQuery, Types.SearchUsersForInvitationsQueryVariables>(Operations.SearchUsersForInvitations, options);
      }
export function useSearchUsersForInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchUsersForInvitationsQuery, Types.SearchUsersForInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchUsersForInvitationsQuery, Types.SearchUsersForInvitationsQueryVariables>(Operations.SearchUsersForInvitations, options);
        }
export type SearchUsersForInvitationsQueryHookResult = ReturnType<typeof useSearchUsersForInvitationsQuery>;
export type SearchUsersForInvitationsLazyQueryHookResult = ReturnType<typeof useSearchUsersForInvitationsLazyQuery>;
export type SearchUsersForInvitationsQueryResult = Apollo.QueryResult<Types.SearchUsersForInvitationsQuery, Types.SearchUsersForInvitationsQueryVariables>;

/**
 * __useSecondaryEmailQuery__
 *
 * To run a query within a React component, call `useSecondaryEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecondaryEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecondaryEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSecondaryEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.SecondaryEmailQuery, Types.SecondaryEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecondaryEmailQuery, Types.SecondaryEmailQueryVariables>(Operations.SecondaryEmail, options);
      }
export function useSecondaryEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecondaryEmailQuery, Types.SecondaryEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecondaryEmailQuery, Types.SecondaryEmailQueryVariables>(Operations.SecondaryEmail, options);
        }
export type SecondaryEmailQueryHookResult = ReturnType<typeof useSecondaryEmailQuery>;
export type SecondaryEmailLazyQueryHookResult = ReturnType<typeof useSecondaryEmailLazyQuery>;
export type SecondaryEmailQueryResult = Apollo.QueryResult<Types.SecondaryEmailQuery, Types.SecondaryEmailQueryVariables>;

/**
 * __useSecondaryEmailsQuery__
 *
 * To run a query within a React component, call `useSecondaryEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecondaryEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecondaryEmailsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSecondaryEmailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SecondaryEmailsQuery, Types.SecondaryEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecondaryEmailsQuery, Types.SecondaryEmailsQueryVariables>(Operations.SecondaryEmails, options);
      }
export function useSecondaryEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecondaryEmailsQuery, Types.SecondaryEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecondaryEmailsQuery, Types.SecondaryEmailsQueryVariables>(Operations.SecondaryEmails, options);
        }
export type SecondaryEmailsQueryHookResult = ReturnType<typeof useSecondaryEmailsQuery>;
export type SecondaryEmailsLazyQueryHookResult = ReturnType<typeof useSecondaryEmailsLazyQuery>;
export type SecondaryEmailsQueryResult = Apollo.QueryResult<Types.SecondaryEmailsQuery, Types.SecondaryEmailsQueryVariables>;

/**
 * __useSharedFilesQuery__
 *
 * To run a query within a React component, call `useSharedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFilesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      channelUrl: // value for 'channelUrl'
 *   },
 * });
 */
export function useSharedFilesQuery(baseOptions: Apollo.QueryHookOptions<Types.SharedFilesQuery, Types.SharedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharedFilesQuery, Types.SharedFilesQueryVariables>(Operations.SharedFiles, options);
      }
export function useSharedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharedFilesQuery, Types.SharedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharedFilesQuery, Types.SharedFilesQueryVariables>(Operations.SharedFiles, options);
        }
export type SharedFilesQueryHookResult = ReturnType<typeof useSharedFilesQuery>;
export type SharedFilesLazyQueryHookResult = ReturnType<typeof useSharedFilesLazyQuery>;
export type SharedFilesQueryResult = Apollo.QueryResult<Types.SharedFilesQuery, Types.SharedFilesQueryVariables>;

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.StoriesQuery, Types.StoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoriesQuery, Types.StoriesQueryVariables>(Operations.Stories, options);
      }
export function useStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoriesQuery, Types.StoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoriesQuery, Types.StoriesQueryVariables>(Operations.Stories, options);
        }
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>;
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>;
export type StoriesQueryResult = Apollo.QueryResult<Types.StoriesQuery, Types.StoriesQueryVariables>;

/**
 * __useStoriesGroupedByOwnersQuery__
 *
 * To run a query within a React component, call `useStoriesGroupedByOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesGroupedByOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesGroupedByOwnersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useStoriesGroupedByOwnersQuery(baseOptions?: Apollo.QueryHookOptions<Types.StoriesGroupedByOwnersQuery, Types.StoriesGroupedByOwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoriesGroupedByOwnersQuery, Types.StoriesGroupedByOwnersQueryVariables>(Operations.StoriesGroupedByOwners, options);
      }
export function useStoriesGroupedByOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoriesGroupedByOwnersQuery, Types.StoriesGroupedByOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoriesGroupedByOwnersQuery, Types.StoriesGroupedByOwnersQueryVariables>(Operations.StoriesGroupedByOwners, options);
        }
export type StoriesGroupedByOwnersQueryHookResult = ReturnType<typeof useStoriesGroupedByOwnersQuery>;
export type StoriesGroupedByOwnersLazyQueryHookResult = ReturnType<typeof useStoriesGroupedByOwnersLazyQuery>;
export type StoriesGroupedByOwnersQueryResult = Apollo.QueryResult<Types.StoriesGroupedByOwnersQuery, Types.StoriesGroupedByOwnersQueryVariables>;

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useStoryQuery(baseOptions: Apollo.QueryHookOptions<Types.StoryQuery, Types.StoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoryQuery, Types.StoryQueryVariables>(Operations.Story, options);
      }
export function useStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoryQuery, Types.StoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoryQuery, Types.StoryQueryVariables>(Operations.Story, options);
        }
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>;
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>;
export type StoryQueryResult = Apollo.QueryResult<Types.StoryQuery, Types.StoryQueryVariables>;

/**
 * __useStoryReactionsQuery__
 *
 * To run a query within a React component, call `useStoryReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryReactionsQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStoryReactionsQuery(baseOptions: Apollo.QueryHookOptions<Types.StoryReactionsQuery, Types.StoryReactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoryReactionsQuery, Types.StoryReactionsQueryVariables>(Operations.StoryReactions, options);
      }
export function useStoryReactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoryReactionsQuery, Types.StoryReactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoryReactionsQuery, Types.StoryReactionsQueryVariables>(Operations.StoryReactions, options);
        }
export type StoryReactionsQueryHookResult = ReturnType<typeof useStoryReactionsQuery>;
export type StoryReactionsLazyQueryHookResult = ReturnType<typeof useStoryReactionsLazyQuery>;
export type StoryReactionsQueryResult = Apollo.QueryResult<Types.StoryReactionsQuery, Types.StoryReactionsQueryVariables>;

/**
 * __useSuggestedUsersAndCompaniesQuery__
 *
 * To run a query within a React component, call `useSuggestedUsersAndCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedUsersAndCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedUsersAndCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      includeCompanies: // value for 'includeCompanies'
 *      includeUsers: // value for 'includeUsers'
 *   },
 * });
 */
export function useSuggestedUsersAndCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<Types.SuggestedUsersAndCompaniesQuery, Types.SuggestedUsersAndCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SuggestedUsersAndCompaniesQuery, Types.SuggestedUsersAndCompaniesQueryVariables>(Operations.SuggestedUsersAndCompanies, options);
      }
export function useSuggestedUsersAndCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SuggestedUsersAndCompaniesQuery, Types.SuggestedUsersAndCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SuggestedUsersAndCompaniesQuery, Types.SuggestedUsersAndCompaniesQueryVariables>(Operations.SuggestedUsersAndCompanies, options);
        }
export type SuggestedUsersAndCompaniesQueryHookResult = ReturnType<typeof useSuggestedUsersAndCompaniesQuery>;
export type SuggestedUsersAndCompaniesLazyQueryHookResult = ReturnType<typeof useSuggestedUsersAndCompaniesLazyQuery>;
export type SuggestedUsersAndCompaniesQueryResult = Apollo.QueryResult<Types.SuggestedUsersAndCompaniesQuery, Types.SuggestedUsersAndCompaniesQueryVariables>;

/**
 * __useUserAdsQuery__
 *
 * To run a query within a React component, call `useUserAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      type: // value for 'type'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useUserAdsQuery(baseOptions: Apollo.QueryHookOptions<Types.UserAdsQuery, Types.UserAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserAdsQuery, Types.UserAdsQueryVariables>(Operations.UserAds, options);
      }
export function useUserAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserAdsQuery, Types.UserAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserAdsQuery, Types.UserAdsQueryVariables>(Operations.UserAds, options);
        }
export type UserAdsQueryHookResult = ReturnType<typeof useUserAdsQuery>;
export type UserAdsLazyQueryHookResult = ReturnType<typeof useUserAdsLazyQuery>;
export type UserAdsQueryResult = Apollo.QueryResult<Types.UserAdsQuery, Types.UserAdsQueryVariables>;

/**
 * __useUserContactsQuery__
 *
 * To run a query within a React component, call `useUserContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContactsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      searchFields: // value for 'searchFields'
 *      match: // value for 'match'
 *      excludeFromResultsUserId: // value for 'excludeFromResultsUserId'
 *      onlyMutual: // value for 'onlyMutual'
 *   },
 * });
 */
export function useUserContactsQuery(baseOptions: Apollo.QueryHookOptions<Types.UserContactsQuery, Types.UserContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserContactsQuery, Types.UserContactsQueryVariables>(Operations.UserContacts, options);
      }
export function useUserContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserContactsQuery, Types.UserContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserContactsQuery, Types.UserContactsQueryVariables>(Operations.UserContacts, options);
        }
export type UserContactsQueryHookResult = ReturnType<typeof useUserContactsQuery>;
export type UserContactsLazyQueryHookResult = ReturnType<typeof useUserContactsLazyQuery>;
export type UserContactsQueryResult = Apollo.QueryResult<Types.UserContactsQuery, Types.UserContactsQueryVariables>;

/**
 * __useUserFavoriteNewsPostsQuery__
 *
 * To run a query within a React component, call `useUserFavoriteNewsPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFavoriteNewsPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFavoriteNewsPostsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUserFavoriteNewsPostsQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserFavoriteNewsPostsQuery, Types.UserFavoriteNewsPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserFavoriteNewsPostsQuery, Types.UserFavoriteNewsPostsQueryVariables>(Operations.UserFavoriteNewsPosts, options);
      }
export function useUserFavoriteNewsPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserFavoriteNewsPostsQuery, Types.UserFavoriteNewsPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserFavoriteNewsPostsQuery, Types.UserFavoriteNewsPostsQueryVariables>(Operations.UserFavoriteNewsPosts, options);
        }
export type UserFavoriteNewsPostsQueryHookResult = ReturnType<typeof useUserFavoriteNewsPostsQuery>;
export type UserFavoriteNewsPostsLazyQueryHookResult = ReturnType<typeof useUserFavoriteNewsPostsLazyQuery>;
export type UserFavoriteNewsPostsQueryResult = Apollo.QueryResult<Types.UserFavoriteNewsPostsQuery, Types.UserFavoriteNewsPostsQueryVariables>;

/**
 * __useUserIdBySendbirdUserIdQuery__
 *
 * To run a query within a React component, call `useUserIdBySendbirdUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdBySendbirdUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdBySendbirdUserIdQuery({
 *   variables: {
 *      sendbirdUserId: // value for 'sendbirdUserId'
 *   },
 * });
 */
export function useUserIdBySendbirdUserIdQuery(baseOptions: Apollo.QueryHookOptions<Types.UserIdBySendbirdUserIdQuery, Types.UserIdBySendbirdUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserIdBySendbirdUserIdQuery, Types.UserIdBySendbirdUserIdQueryVariables>(Operations.UserIdBySendbirdUserId, options);
      }
export function useUserIdBySendbirdUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserIdBySendbirdUserIdQuery, Types.UserIdBySendbirdUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserIdBySendbirdUserIdQuery, Types.UserIdBySendbirdUserIdQueryVariables>(Operations.UserIdBySendbirdUserId, options);
        }
export type UserIdBySendbirdUserIdQueryHookResult = ReturnType<typeof useUserIdBySendbirdUserIdQuery>;
export type UserIdBySendbirdUserIdLazyQueryHookResult = ReturnType<typeof useUserIdBySendbirdUserIdLazyQuery>;
export type UserIdBySendbirdUserIdQueryResult = Apollo.QueryResult<Types.UserIdBySendbirdUserIdQuery, Types.UserIdBySendbirdUserIdQueryVariables>;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      showcaseLimit: // value for 'showcaseLimit'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.UserProfileQuery, Types.UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserProfileQuery, Types.UserProfileQueryVariables>(Operations.UserProfile, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserProfileQuery, Types.UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserProfileQuery, Types.UserProfileQueryVariables>(Operations.UserProfile, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<Types.UserProfileQuery, Types.UserProfileQueryVariables>;

/**
 * __useUserProfileForChatQuery__
 *
 * To run a query within a React component, call `useUserProfileForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileForChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileForChatQuery(baseOptions: Apollo.QueryHookOptions<Types.UserProfileForChatQuery, Types.UserProfileForChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserProfileForChatQuery, Types.UserProfileForChatQueryVariables>(Operations.UserProfileForChat, options);
      }
export function useUserProfileForChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserProfileForChatQuery, Types.UserProfileForChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserProfileForChatQuery, Types.UserProfileForChatQueryVariables>(Operations.UserProfileForChat, options);
        }
export type UserProfileForChatQueryHookResult = ReturnType<typeof useUserProfileForChatQuery>;
export type UserProfileForChatLazyQueryHookResult = ReturnType<typeof useUserProfileForChatLazyQuery>;
export type UserProfileForChatQueryResult = Apollo.QueryResult<Types.UserProfileForChatQuery, Types.UserProfileForChatQueryVariables>;

/**
 * __useVersionsSupportedQuery__
 *
 * To run a query within a React component, call `useVersionsSupportedQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionsSupportedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionsSupportedQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionsSupportedQuery(baseOptions?: Apollo.QueryHookOptions<Types.VersionsSupportedQuery, Types.VersionsSupportedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VersionsSupportedQuery, Types.VersionsSupportedQueryVariables>(Operations.VersionsSupported, options);
      }
export function useVersionsSupportedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VersionsSupportedQuery, Types.VersionsSupportedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VersionsSupportedQuery, Types.VersionsSupportedQueryVariables>(Operations.VersionsSupported, options);
        }
export type VersionsSupportedQueryHookResult = ReturnType<typeof useVersionsSupportedQuery>;
export type VersionsSupportedLazyQueryHookResult = ReturnType<typeof useVersionsSupportedLazyQuery>;
export type VersionsSupportedQueryResult = Apollo.QueryResult<Types.VersionsSupportedQuery, Types.VersionsSupportedQueryVariables>;

/**
 * __useWoopenSupportInfoQuery__
 *
 * To run a query within a React component, call `useWoopenSupportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWoopenSupportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWoopenSupportInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useWoopenSupportInfoQuery(baseOptions?: Apollo.QueryHookOptions<Types.WoopenSupportInfoQuery, Types.WoopenSupportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WoopenSupportInfoQuery, Types.WoopenSupportInfoQueryVariables>(Operations.WoopenSupportInfo, options);
      }
export function useWoopenSupportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WoopenSupportInfoQuery, Types.WoopenSupportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WoopenSupportInfoQuery, Types.WoopenSupportInfoQueryVariables>(Operations.WoopenSupportInfo, options);
        }
export type WoopenSupportInfoQueryHookResult = ReturnType<typeof useWoopenSupportInfoQuery>;
export type WoopenSupportInfoLazyQueryHookResult = ReturnType<typeof useWoopenSupportInfoLazyQuery>;
export type WoopenSupportInfoQueryResult = Apollo.QueryResult<Types.WoopenSupportInfoQuery, Types.WoopenSupportInfoQueryVariables>;