/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react';

import useUser from 'src/hooks/useUser';
import useAppRouter from 'src/hooks/useAppRouter';
import {
    routePathSignUpProStep2,
    routePathSignUpStep2,
    routePathWelcomeAfterSignUp,
    routePathWelcomeAfterSignUpPro,
} from 'src/constants/router';
import { minimumNumberOfNewsCategories } from 'src/constants/misc';

const useSignupRedirections = () => {
    const currentUser = useUser();
    const router = useAppRouter();
    const loggedInUser = Boolean(currentUser);
    const isProUser = currentUser?.isProfessionnal;
    const isOnUserWelcomePage = Boolean(router.pathname === routePathWelcomeAfterSignUp);
    const isOnProWelcomePage = Boolean(router.pathname === routePathWelcomeAfterSignUpPro);

    // mandatory fields for step 2 user = currentUser?.firstName , currentUser?.lastName, currentUser?.city?.id
    // mandatory fields for step 2 proUser = currentUser?.firstName , currentUser?.lastName, currentUser?.city?.id, currentUser?.phoneNumber

    const userHasSignupStep2Info = Boolean(currentUser?.firstName && currentUser.lastName && currentUser.city?.id);
    const proUserHasSignupStep2Info = Boolean(
        currentUser?.firstName && currentUser.lastName && currentUser.city?.id && currentUser.phoneNumber?.number,
    );

    const userHasEnoughCategories = (currentUser?.newsCategories.length ?? 0) >= minimumNumberOfNewsCategories;

    useEffect(() => {
        const redirectStep2 = async () => {
            // do not redirect if the current page is already the SignupStep2 page or on the welcome page
            if (router.pathname !== routePathSignUpStep2 && !isOnUserWelcomePage)
                await router.push(routePathSignUpStep2);
        };
        const redirectProStep2 = async () => {
            // do not redirect if the current page is already the SignupProStep2 page  or on the welcome page
            if (router.pathname !== routePathSignUpProStep2 && !isOnProWelcomePage)
                await router.push(routePathSignUpProStep2);
        };
        const openChoseInterestsModal = async () => {
            if (router.asPath.startsWith('/signup')) {
                return;
            }
            if (!router.asPath.includes('?interestsModal=true')) {
                await router.push(`${router.asPath}?interestsModal=true`);
            }
        };
        if (loggedInUser && !currentUser?.deletedAt) {
            if (!isProUser && !userHasSignupStep2Info) {
                redirectStep2();
            } else if (isProUser && !proUserHasSignupStep2Info) {
                redirectProStep2();
            } else if (!userHasEnoughCategories) {
                openChoseInterestsModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUser,
        currentUser?.isProfessionnal,
        proUserHasSignupStep2Info,
        userHasSignupStep2Info,
        userHasEnoughCategories,
        loggedInUser,
    ]);
};

export default useSignupRedirections;
