import { OAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { useApolloClient } from '@apollo/client';

import { useTranslation } from 'next-i18next';

import {
    logEvent,
    setUserId,
    auth as firebaseAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
} from 'src/utils/firebase';
import { logCustomEvent as piwikLogCustomEvent } from 'src/utils/piwik';
import useAppRouter from 'src/hooks/useAppRouter';
import { useSignUpMutation, useSignUpProMutation, useAddEventMutation } from 'src/hooks/__generated__/queries';
import type { CurrentUserQuery } from 'src/types/__generated__/graphql';
import { CurrentUser, PlatformSource } from 'src/types/__generated__/graphql';
import useAlerts from 'src/hooks/useAlerts';
import useLoginModal from 'src/hooks/useLoginModal';
import { AnalyticsEvent } from 'src/utils/analytics';
import {
    routePathHome,
    routePathLogin,
    routePathSignUpProStep2,
    routePathSignUpStep2,
    routePathWelcomeAfterSignUp,
    routePathWelcomeAfterSignUpPro,
} from 'src/constants/router';
import useFormatFirebaseError from 'src/hooks/useFormatFirebaseError';
import useAuth from 'src/hooks/useAuth';
import { tokensDuration } from 'src/constants/signup';
import { getCurrentUserIpAddressInfo } from 'src/utils/ipUtils';

export type AuthProvider = 'google' | 'facebook' | 'apple';

/**
 * Get auth actions
 */
const useAuthActions = () => {
    const router = useAppRouter();
    const [signUpMutation] = useSignUpMutation();
    const [signUpProMutation] = useSignUpProMutation();
    const [addEventMutation] = useAddEventMutation();
    const { t } = useTranslation();
    const client = useApolloClient();
    const { onEndFlow } = useLoginModal();
    const { setAuth, removeAuth } = useAuth();
    const { addAlert } = useAlerts();

    const { formatFirebaseError } = useFormatFirebaseError();

    const handleAddConnectionEvent = async () => {
        const { data } = await client.query<CurrentUserQuery>({
            query: CurrentUser,
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        addEventMutation({
            variables: {
                userId: data.me.id,
                eventType: 'connection',
                platform: 'web',
            },
        });
    };

    const createUser = async (hasOptedInToMarketingComms?: boolean, quickSignupUserId?: string) => {
        let ipAddressInfo;
        if (hasOptedInToMarketingComms) {
            ipAddressInfo = await getCurrentUserIpAddressInfo();
        }
        // authorization header is automatically set before each request (see createApolloClient file)
        const optInVariables = hasOptedInToMarketingComms
            ? {
                  hasOptedInToMarketingCommsAt: new Date(),
                  ipAddress: (await getCurrentUserIpAddressInfo())?.ipAddress,
                  platform: PlatformSource.Web,
              }
            : { hasOptedInToMarketingCommsAt: null };
        const { data } = await signUpMutation({
            variables: {
                quickSignupUserId,
                ...optInVariables,
            },
        });
        if (data?.signUp.id) {
            setUserId(data.signUp.id);
        }
    };

    interface CreateProUser {
        activityId: string;
        customActivity?: string;
        hasOptedInToMarketingComms?: boolean;
        quickSignupUserId?: string;
        isTheRealEstateOther?: boolean;
    }

    const createProUser = async ({
        activityId,
        customActivity,
        hasOptedInToMarketingComms,
        quickSignupUserId,
        isTheRealEstateOther,
    }: CreateProUser) => {
        const optInVariables = hasOptedInToMarketingComms
            ? {
                  hasOptedInToMarketingCommsAt: new Date(),
                  ipAddress: (await getCurrentUserIpAddressInfo())?.ipAddress,
                  platform: PlatformSource.Web,
              }
            : { hasOptedInToMarketingCommsAt: null };
        // authorization header is automatically set before each request (see createApolloClient file)
        await signUpProMutation({
            variables: {
                activityId,
                customActivity: customActivity ?? null,
                quickSignupUserId,
                isTheRealEstateOther,
                ...optInVariables,
            },
        });
    };

    const signUp = async (values: SignUpFormValues) => {
        try {
            const { user } = await createUserWithEmailAndPassword(values.email, values.password);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, tokensDuration.short);
            await createUser(values.hasOptedInToMarketingComms);
            await sendEmailVerification(user, {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}${routePathLogin}?isEmailVerify=true`,
            });
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                ACCOUNT_TYPE: 'regular',
            });
            piwikLogCustomEvent({ category: 'signup', action: 'success' });
            await router.push(routePathWelcomeAfterSignUp);
            await handleAddConnectionEvent();
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    const quickSignUp = async (values: SignUpFormValues, routeParams: string, quickSignupUserId: string) => {
        try {
            const { user } = await createUserWithEmailAndPassword(values.email, values.password);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, tokensDuration.short);
            await createUser(values.hasOptedInToMarketingComms, quickSignupUserId);
            await sendEmailVerification(user, {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}${routePathLogin}?isEmailVerify=true`,
            });
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                ACCOUNT_TYPE: 'regular',
            });
            piwikLogCustomEvent({ category: 'signup', action: 'success' });
            await router.push(`${routePathSignUpStep2}${routeParams}`);
            await handleAddConnectionEvent();
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    const signUpPro = async (values: SignUpProFormValues) => {
        try {
            const { user } = await createUserWithEmailAndPassword(values.email, values.password);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, tokensDuration.short);
            await createProUser({
                activityId: values.activityId,
                customActivity: values.customActivity,
                hasOptedInToMarketingComms: values.hasOptedInToMarketingComms,
                isTheRealEstateOther: values.isTheRealEstateOther,
            });
            await sendEmailVerification(user, {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}${routePathLogin}?isEmailVerify=true`,
            });
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                ACCOUNT_TYPE: 'professionnal',
            });
            piwikLogCustomEvent({ category: 'signup', action: 'success' });
            await router.push(routePathWelcomeAfterSignUpPro);
            await handleAddConnectionEvent();
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    const quickSignUpPro = async (values: SignUpProFormValues, routeParams: string, quickSignupUserId: string) => {
        try {
            const { user } = await createUserWithEmailAndPassword(values.email, values.password);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, tokensDuration.short);
            await createProUser({
                activityId: values.activityId,
                customActivity: values.customActivity,
                hasOptedInToMarketingComms: values.hasOptedInToMarketingComms,
                quickSignupUserId,
            });
            await sendEmailVerification(user, {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}${routePathLogin}?isEmailVerify=true`,
            });
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                ACCOUNT_TYPE: 'professionnal',
            });
            piwikLogCustomEvent({ category: 'signup', action: 'success' });
            await router.push(`${routePathSignUpProStep2}${routeParams}`);
            await handleAddConnectionEvent();
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    type SignUpProSocialParams = {
        provider: AuthProvider;
        values: SignUpProFormValues;
        returnUrl?: string;
    };
    const signUpProSocial = async ({ provider, values, returnUrl = routePathHome }: SignUpProSocialParams) => {
        let authProvider;
        if (provider === 'facebook') {
            authProvider = new FacebookAuthProvider();
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                FACEBOOK_SIGNUP: true,
                ACCOUNT_TYPE: 'professionnal',
            });
            authProvider.addScope('email');
            authProvider.addScope('public_profile');
        } else if (provider === 'apple') {
            authProvider = new OAuthProvider('apple.com');
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                APPLE_SIGNUP: true,
                ACCOUNT_TYPE: 'professionnal',
            });
        } else {
            authProvider = new GoogleAuthProvider();
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                GOOGLE_SIGNUP: true,
                ACCOUNT_TYPE: 'professionnal',
            });
        }
        try {
            const { user } = await signInWithPopup(authProvider);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, tokensDuration.short);
            let userAlreadyExist = false;
            try {
                await createProUser({
                    activityId: values.activityId,
                    customActivity: values.customActivity,
                    hasOptedInToMarketingComms: values.hasOptedInToMarketingComms,
                });
            } catch (signupError: unknown) {
                if ((signupError as Error).message.includes('AccountAlreadyExist')) {
                    userAlreadyExist = true;
                } else {
                    throw signupError;
                }
            }
            const { data } = await client.query<CurrentUserQuery>({
                query: CurrentUser,
            });
            if (!userAlreadyExist) {
                await sendEmailVerification(user, {
                    url: `${process.env.NEXT_PUBLIC_SITE_URL}${routePathLogin}?isEmailVerify=true`,
                });
                onEndFlow(undefined, data.me.id);
            }
            if (userAlreadyExist) {
                onEndFlow(returnUrl, data.me.id);
            } else {
                piwikLogCustomEvent({ category: 'signup', action: 'success' });
                await router.push({
                    pathname: routePathWelcomeAfterSignUpPro,
                    query: {
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                    },
                });
                await handleAddConnectionEvent();
            }
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    type SignInParams = {
        email: string;
        password: string;
        rememberMe?: boolean;
        returnUrl?: string;
    };
    const signIn = async ({ email, password, rememberMe, returnUrl = routePathHome }: SignInParams) => {
        try {
            const { user } = await signInWithEmailAndPassword(email, password);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, rememberMe ? tokensDuration.long : tokensDuration.short);
            const { data } = await client.query<CurrentUserQuery>({
                query: CurrentUser,
            });
            if (data.me.deletedAt) {
                await router.push(routePathLogin);
            } else {
                if (data.me.id) {
                    setUserId(data.me.id);
                }
                onEndFlow(returnUrl, data.me.id);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                addEventMutation({
                    variables: {
                        userId: data.me.id,
                        eventType: 'connection',
                        platform: 'web',
                    },
                });
            }
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    const signOut = async () => {
        try {
            await firebaseAuth.signOut(); // the onIdTokenChanged listener will take care to handle cookie, apollo reset ..
            if (router.pathname !== routePathHome) {
                await router.push(routePathHome);
            }
        } catch (error: unknown) {
            showAuthError(error);
        }
    };

    type SignUpSocialParams = {
        provider: AuthProvider;
        values?: SignUpFormValues;
        returnUrl?: string;
    };
    const signUpSocial = async ({ provider, values, returnUrl = routePathHome }: SignUpSocialParams) => {
        let authProvider;
        if (provider === 'facebook') {
            authProvider = new FacebookAuthProvider();
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                FACEBOOK_SIGNUP: true,
                ACCOUNT_TYPE: 'regular',
            });
            authProvider.addScope('email');
            authProvider.addScope('public_profile');
        } else if (provider === 'apple') {
            authProvider = new OAuthProvider('apple.com');
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                APPLE_SIGNUP: true,
                ACCOUNT_TYPE: 'regular',
            });
        } else {
            authProvider = new GoogleAuthProvider();
            logEvent(AnalyticsEvent.SignUpConfirmed, {
                GOOGLE_SIGNUP: true,
                ACCOUNT_TYPE: 'regular',
            });
        }
        try {
            const { user } = await signInWithPopup(authProvider);
            const idToken = await user.getIdToken();
            setAuth({ token: idToken }, tokensDuration.short);
            try {
                await createUser(values?.hasOptedInToMarketingComms);
                await sendEmailVerification(user, {
                    url: `${process.env.NEXT_PUBLIC_SITE_URL}${routePathLogin}?isEmailVerify=true`,
                });

                await router.push({
                    pathname: routePathWelcomeAfterSignUp,
                    query: {
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                    },
                });
            } catch (signupError: unknown) {
                if ((signupError as Error).message.includes('AccountAlreadyExist')) {
                    setAuth({ token: idToken }, tokensDuration.short);
                    piwikLogCustomEvent({ category: 'signup', action: 'success' });
                    addAlert({
                        type: 'success',
                        message: t('socialLogin.success'),
                    });
                    const { data } = await client.query<CurrentUserQuery>({
                        query: CurrentUser,
                    });
                    onEndFlow(returnUrl, data.me.id);
                    await handleAddConnectionEvent();
                } else {
                    throw signupError;
                }
            }
        } catch (error: unknown) {
            showAuthError(error);
            await removeAuth();
        }
    };

    const showAuthError = (error: unknown) => {
        const { name, message } = error as Error;
        let formatedMessage: string | undefined = message;

        // if the error is a FirebaseError, we format the error
        if (name === 'FirebaseError') {
            formatedMessage = formatFirebaseError(message);
        }

        if (formatedMessage) {
            addAlert({
                type: 'error',
                message: String(formatedMessage),
            });
        }
    };

    return {
        quickSignUp,
        signUp,
        signUpPro,
        quickSignUpPro,
        signIn,
        signOut,
        signUpSocial,
        signUpProSocial,
    };
};

export default useAuthActions;
